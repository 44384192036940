export function latRad(lat) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}

export function zoom(mapPx, worldPx, fraction) {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
}

export const fixedNumber = (n, digit) => {
  return Number(parseFloat(n).toFixed(digit));
}

export const beautifyPrice = (n) => {
  const price = parseInt(n).toString();
  const stringPrice = n.toString();
  if(price.length > 3) {
    return `${price.slice(0, price.length-3)},${stringPrice.slice(price.length-3)}`
  }
  return n;
}
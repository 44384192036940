export default class ControlModel {
  constructor({ type, action, url, text, hideTooltip, tooltipText, icon, value, items, isLayout, isShow, openCourierShiftPopup, openDateRange, customStyles, customClassName, width, callback }) {
    this.type = type;
    this.action = action;
    this.url = url;
    this.text = text;
    this.tooltipText = tooltipText;
    this.icon = icon;
    this.value = value;
    this.items = items;
    this.width = width;
    this.isLayout = isLayout;
    this.isShow = isShow;
    this.openCourierShiftPopup = openCourierShiftPopup;
    this.callback = callback;
    this.customStyles = customStyles;
    this.customClassName = customClassName;
    this.openCourierShiftPopup = openCourierShiftPopup;
    this.openDateRange = openDateRange;
    this.hideTooltip = hideTooltip;
  }
}

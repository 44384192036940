import {saveAs} from 'file-saver';
import * as Sentry from '@sentry/react';
import i18n from '../../i18n';
import Store from '../store';

import {all, call, put, select, takeEvery} from 'redux-saga/effects';

import {
    addExternalCycle,
    addUser,
    approveOrders,
    cancelCycle,
    cancelExternalCycle,
    confirmExternalCycle,
    couriersCsv,
    courierToGroup,
    createExternalCycle,
    deleteShift,
    disbandCycle,
    editUser,
    endShift,
    fetchCourierLocations,
    fetchShiftActiveUsers,
    fetchUser,
    fetchUsers,
    finishCycle,
    getAvailableControlCenters,
    getCourierByUserName,
    getUser,
    getUsersByIds,
    goAway,
    groupCourier,
    groupCourierForGroup,
    lockCourierGroup,
    removeExternalCycle,
    removeUser,
    resetPassword,
    resetUserPassword,
    setCurrentUser,
    setUserAvatarBase64,
    shiftPdf,
    shiftProviderPdf,
    startShift1,
    startShifts,
    unlockCourierGroup,
    updateCurrentShiftDepotCouriers,
    updateCurrentShiftUser,
    updateShift,
    updateUsersPagination,
} from '../../api/user-requests';
import {disbandGroup, fetchCycle, lockGroup, unlockGroup} from '../../api/order-requests';
import {authorize} from '../../api/auth-requests';

import {
    actionCheckUserGroup,
    actionCourierUpdate,
    actionCycleStateUpdate,
    actionCycleUpdate,
    actionDeleteUser,
    actionGetIntervalCourierLocations,
    actionLoadCourierLocationsFail,
    actionLoadCourierLocationsSuccess,
    actionLoadUserMarkers,
    actionLoadUsersFail,
    actionLoadUsersSuccess,
    actionNewUser,
    actionSaveTravelData,
    actionSetUserAvatar,
    actionUpdateCourierLocation,
    actionUpdateUser,
    actionUpdateUserById,
    actionUserCycleUpdate,
    actionUsersSet,
    actionUsersUpdate,
    AVAILABLE_CONTROL_CENTERS,
    CANCEL_SHIFT,
    CHECK_USER_GROUP,
    COURIER_APPROVE_ORDERS,
    COURIER_PAGINATION,
    COURIER_TO_GROUP,
    DISBAND_GROUP,
    END_SHIFT,
    FINISH_SHIFT,
    GET_DEFAULT_USERS_STATE,
    GET_USERS_BY_AVAILABLE_COURIERS_IDS,
    GOAWAY_SHIFT,
    INTERVAL_COURIER_LOCATIONS,
    LOAD_COURIER_LOCATIONS,
    LOAD_USER_MARKERS,
    LOAD_USERS,
    LOAD_USERS_BY_ID,
    LOAD_USERS_BY_IDS,
    LOCK_GROUP,
    PERSONAL_SETTING_EDIT,
    PERSONAL_SETTING_PASSWORD_EDIT,
    PUSHER_COURIER_LOCK_UPDATE,
    PUSHER_COURIER_SHIFT_CREATED,
    PUSHER_USER_UPDATED,
    REFRESH_GROUP,
    SET_USER_AVATAR,
    START_SHIFT_BACKUP,
    START_SHIFTS,
    UPDATE_CURRENT_SHIFTS_DEPOT_COURIERS,
    UPDATE_USER_BY_ID,
    USER_CURRENT_SHIFTS_EDIT,
    USER_SETTING_ADD,
    USER_SETTING_DELETE,
    USER_SETTING_EDIT,
    USER_SETTING_PASSWORD_EDIT,
    USER_SHIFT_COURIERS_CSV,
    USER_SHIFT_DOWNLOAD_PDF,
    USER_SHIFTS_DELETE,
    USER_SHIFTS_EDIT,
    USERS_CHECK_COLORS,
    USERS_PAGINATION,
} from '../reducers/userModel/userModel-actions';

import {
    actionExternalCyclesMarkersUpdate,
    actionRemoveMarker,
    actionUpdateAllCouriersMarkers,
    actionUpdateCourierLocationMarker,
    actionUpdateCourierMarker,
    actionUpdateShowCourierMarker,
    UPDATE_SHOW_COURIER_MARKER,
} from '../reducers/marker/marker-actions';
import {PUSHER_CYCLE_DISPATCHED, PUSHER_CYCLE_STATE_UPDATE} from '../reducers/common-actions';
import {
    actionChangeItemsGroup,
    actionCycleDispatched,
    actionGroupUpdateState,
    actionOrdersModelUpdate,
    actionPendingOrdersUpdate,
    actionPendingOrderUpdate,
    actionRemoveGroupOrders,
    actionRemoveOrder,
    actionUpdateGroup,
    actionUpdateGroupOrdersMarkers,
    actionUpdateOrders,
} from '../reducers/order/order-actions';
import {
    actionRestaurantUpdate,
    actionUpdateRestaurantAvailableControlCenters, actionUpdateShifts,
} from '../reducers/restaurant/restaurant-actions';
import {AUTHENTICATION} from '../reducers/authentication/authentication-actions';

import {fetchCourierMarkerSaga} from './marker-saga';

import User from '../../services/user';
import Markers from '../../services/markers';
import Interval from '../../services/interval';
import {
    currentUserIsDeliveryManager,
    currentUserIsDepotRole,
    getUserByGroupId,
    initUserModel,
    isValidPasswordData,
    separateName,
    updateUserColor,
} from '../../services/userModel';
import {
    extractExternalCycleCourierMarkers,
    extractExternalCycleOrders,
    getGroup,
    isDefaultGroup,
    isGroupHide,
    isNotDefaultOrderGroup,
    removeGroupFromPendingOrder,
} from '../../services/order';
import {getInitModel} from '../../services/initModels';
import {removeStoredData} from '../../services/dataStorage';
import ColorService from "../../services/color";

import {
    COURIER_STATE,
    CYCLE_STATE,
    DEFAULT_ORDERS_GROUPS,
    EXTERNAL_CYCLE_STATE,
    getUserError,
    ICONS_MARKER_TYPE,
    MODEL_TYPE,
    ORDER_TYPE,
    TYPE_MARKER,
} from '../../utils/enums';
import {
    dataURItoBlob,
    fileFromRequestResult,
    isSameObjects,
    isValidStringNoSpaces,
    objectKeysToUpperLowerCase,
} from '../../utils/objects-util';
import {actionCreateSuccess, createSaga, ErrorData} from '../../utils/sagaHelper';
import {getCurrentUserName, getRememberMe, getUserConnectionData} from '../../utils/auth-util';
import {getErrorMessage, requestResultIsOk} from '../../utils/request-util';
import {getISOTime} from '../../utils/convertTime';

import {
    getActiveStateCycles,
    getControlCenter,
    getExternalCycles,
    getGroups,
    getMarkers,
    getOrders,
    getPendingOrders,
    getPickups,
    getRestaurant,
    getSectionModel,
    getUserModel,
} from '../selectors';
import {isBase64Image} from '../../utils/image-util';
import {
    actionExternalCycleRemove,
    actionExternalCyclesUpdateList,
    actionExternalCycleUpdate,
    actionExternalCycleUpdateFromCourierLocations,
    actionExtractExternalCycleData,
    ADD_ORDER_TO_EXTERNAL_CYCLE,
    ASSOCIATE_ORDER_TO_EXTERNAL_CYCLE,
    DISBAND_ORDER_FROM_EXTERNAL_CYCLE,
    EXTERNAL_CYCLE_CONFIRM,
    EXTERNAL_CYCLE_DISBAND,
    EXTERNAL_CYCLES_UPDATE,
    EXTERNAL_CYCLES_UPDATE_FROM_COURIER_LOCATIONS,
    EXTRACT_EXTERNAL_CYCLE_DATA,
    PUSHER_EXTERNAL_CYCLE_CREATED,
    PUSHER_EXTERNAL_CYCLE_FINISHED,
    PUSHER_EXTERNAL_CYCLE_UPDATED,
} from '../reducers/externalCycles/externalCycles-actions';
import {toast} from 'react-toastify';
import moment from 'moment';
import {actionSetSectionItemsLoading} from '../reducers/sectionsWrapperData/sectionsWrapperData-actions.js';
import {controlCenterUpdateConfig, depotCycleRefreshConfig} from '../../utils/constants.js';
import {groupUpdateDataSaga} from './init-saga.js';
import {actionActiveStateCycleUpdate} from '../reducers/cycles/cycles-actions.js';

export function* usersSaga(action) {
    try {
        if (action.users) {
            yield put(
                actionLoadUsersSuccess(
                    action.users.map(user => {
                        const isCurrentUser = user.userId === getUserConnectionData().unique_name;
                        let controlCenterId = null;

                        if (isCurrentUser) {
                            controlCenterId =
                                user.controlCenterId ||
                                (action.restaurant &&
                                    action.restaurant.activeControlCenter &&
                                    action.restaurant.activeControlCenter.controlCenterId);
                            User.instance.setCurrentUserInfo({ ...user, controlCenterId });
                        }

                        return isCurrentUser ? { ...user, controlCenterId } : user;
                    })
                )
            );
        }

        if (action.cycles || action.courierLocations) {
            yield all([
                put(actionSaveTravelData({ cycles: action.cycles, courierLocations: action.courierLocations })),
                put(actionExternalCycleUpdateFromCourierLocations(action.courierLocations)),
            ]);
            // TODO Check fleet working
            // yield call(courierLocationsSaga);
        }

        if (action.users) {
            yield put(actionLoadUserMarkers());
        }

        return true;
    } catch (e) {
        yield put(actionLoadUsersFail('Error users loading'));
    }
}

export function* courierLocationsSaga(action) {
    try {
        const response = yield call(fetchCourierLocations);
        if (response && response.status === 200) {
            const data = response.data;
            yield put(actionLoadCourierLocationsSuccess(data));

            //update courier markers
            let users = yield select(getUserModel);
            let markerUsers = data.map(location => users.find(f => f.userId === location.CourierId));
            yield all([...markerUsers.map(user => call(fetchCourierMarkerSaga, { data: user }))]);
        } else {
            yield put(actionLoadCourierLocationsFail('Error load courier locations'));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* updateCourierLocationSaga() {
    try {
        const response = yield call(fetchCourierLocations);
        if (response && response.status === 200 && response.data) {
            let users = yield select(getUserModel);
            let orders = yield select(getOrders);
            const data = objectKeysToUpperLowerCase(response.data);
            const travelDataUpdate = data.reduce((accumulator, currentValue) => {
                const user = users.find(f => f.userId === currentValue.courierId);
                if (user && JSON.stringify(user.travelData) !== JSON.stringify(currentValue))
                    accumulator.push(currentValue);
                return accumulator;
            }, []);

            if (travelDataUpdate.length) {
                yield put(actionUpdateCourierLocation(travelDataUpdate));

                users = yield select(getUserModel);
                const ids = data.map(m => m.courierId);
                const locationUsers = users.filter(u => ids.includes(u.userId));
                yield put(actionUpdateCourierLocationMarker({ data, users: locationUsers, orders }));
            }
            yield put(actionExternalCycleUpdateFromCourierLocations(data));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* approveOrdersSaga(action) {
    yield* createSaga(function*() {
        const { courierId } = action.data;
        const externalCycles = yield select(getExternalCycles);
        const isExternalGroup = externalCycles.find(f => f.id === courierId);
        const requestResult = yield call(isExternalGroup ? confirmExternalCycle : approveOrders, courierId);

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (isExternalGroup) {
                yield put(actionExternalCycleUpdate(data));
                yield put(actionExtractExternalCycleData({ id: data.id }));
            } else {
                yield put(actionCheckUserGroup({ userId: courierId }));

                const users = yield select(getUserModel);
                const user = getUserByGroupId(users, courierId);
                const newCycle = {
                    ...(user.cycle || {}),
                    state: CYCLE_STATE.approved,
                };
                if(data.orders.length) {
                    yield put(actionPendingOrdersUpdate({ activeOrders: data.orders }));
                }

                if (user && newCycle) {
                    yield put(actionCourierUpdate({ ...user, cycle: newCycle }));
                    yield put(actionUpdateGroup({ id: user.userId, state: newCycle.state }));
                    yield put(actionGroupUpdateState([{ id: user.userId, hide: isGroupHide(newCycle.state) }]));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export function* fetchCycleUsersSaga(data) {
    try {
        const cycles = (data.cycles || []).map(cycle => cycle.courierId);
        const users = (data.users || []).map(user => user.userId);
        const courierIds = cycles.filter(courierId => !users.includes(courierId));
        let cycleUsers = [];
        if (courierIds.length) {
            cycleUsers = yield all([...courierIds.map(courierId => call(fetchCycleUserSaga, { data: { courierId } }))]);
        }

        return cycleUsers.filter(user => !!user);
    } catch (error) {
        console.log(error);
    }
}

export function* fetchUserAvatarsSaga(action) {
    const data = (action && action.data) || {};
    const users = data.users || (yield select(getUserModel));
    const orders = yield select(getOrders);
    const showUserMarkers = users.filter(user =>
        Markers.instance.canShowMarker(
            user,
            (orders || []).filter(f => f.groupId === user.userId)
        )
    );
    yield put(actionUpdateAllCouriersMarkers(showUserMarkers));
}

function* cycleStateUpdateSaga(action) {
    try {
        const data = objectKeysToUpperLowerCase(action.data);

        if (data) {
            yield put(actionCheckUserGroup({ userId: data.courierId }));
            yield put(actionCycleStateUpdate(data));

            let users = yield select(getUserModel);

            if(!users.some(user => user.userId === data.courierId)) {
                yield getUserByIdSaga({ data: { id: data.courierId }})
                users = yield select(getUserModel);
            }

            let user = users.find(u => u.userId === data.courierId);

            if(data.cycleState === CYCLE_STATE.closed || !user.cycle) {
                yield put(actionRemoveMarker([user.userId]));
            }

            if (user.cycle) {
                yield put(actionCourierUpdate({ ...user, cycle: { ...user.cycle, retrieved: data?.retrieved } }));
                yield put(actionActiveStateCycleUpdate({ ...user.cycle, retrieved: data?.retrieved }))
                yield put(actionGroupUpdateState([{ id: data.courierId, hide: isGroupHide(user.cycle.state) }]));
            }

            if (data.cleanOrders) {
                const markers = yield select(getMarkers);
                const groupMarkers = markers.filter(
                    marker => marker.groupId === data.courierId && marker.markerType === TYPE_MARKER.order
                );
                yield put(actionRemoveGroupOrders({ groupId: data.courierId }));
                if (groupMarkers && groupMarkers.length) {
                    yield put(actionRemoveMarker(groupMarkers.map(marker => marker.id)));
                }
            }

            yield put(actionUpdateShowCourierMarker({ userId: user.userId }));
        }
    } catch (error) {
        console.log(error);
    }
}

function* cycleDispatchedSaga(action) {
    try {
        const data = objectKeysToUpperLowerCase(action.data);
        yield put(actionUserCycleUpdate(data));
        yield put(actionCycleDispatched(data));

        let users = yield select(getUserModel);

        if(!users.some(user => user.userId === data.courierId)) {
            yield getUserByIdSaga({ data: { id: data.courierId }})
            users = yield select(getUserModel);
        }

        let user = users.find(u => u.userId === data.courierId);

        yield put(actionCourierUpdate({ ...user, cycle: { ...user.cycle, retrieved: data?.retrieved } }));
        yield put(actionActiveStateCycleUpdate({ ...user.cycle, retrieved: data?.retrieved }))
        yield put(actionGroupUpdateState([{ id: user.userId, hide: isGroupHide(user.cycle && user.cycle.state) }]));

        yield put(actionUpdateGroupOrdersMarkers({ groupId: user.userId }));
        yield put(actionUpdateShowCourierMarker({ userId: user.userId }));
    } catch (e) {
        console.log(e);
    }
}

function* courierShiftCreatedSaga(action) {
    const data = objectKeysToUpperLowerCase(action.data);
    if (User.instance.skipObjectWithControlCenter(data) || User.instance.skipObjectWithControlCenter(data.cycle))
        return;

    try {
        if (data.cycle && data.cycle.courierId) {
            let users = yield select(getUserModel);

            if(!users.some(user => user.userId === data.cycle.courierId)) {
                yield getUserByIdSaga({ data: { id: data.cycle.courierId }})
                users = yield select(getUserModel);
            }

            let user = users.find(f => f.userId === data.cycle.courierId);

            if (user) {
                user.cycle = data.cycle;
                user.roles.active = true;
                user = updateUserColor(user);
                yield put(actionCourierUpdate(user));
                yield put(
                    actionGroupUpdateState([{ id: user.userId, hide: isGroupHide(user.cycle && user.cycle.state) }])
                );
                yield put(actionUpdateGroupOrdersMarkers({ groupId: user.userId }));
            }
        }
    } catch (error) {
        console.log(error);
    }
}

function* startBackupSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
          startShift1,
          action.data
        );

        if (requestResult && requestResult.status === 200) {
            const courierId = action.data.courierId
            let users = yield select(getUserModel);

            if(!users.some(user => user.userId === courierId)) {
                yield getUserByIdSaga({ data: { id: courierId }})
                users = yield select(getUserModel);
            }

            const user = users.find(f => f.userId === courierId);
            const data = objectKeysToUpperLowerCase(requestResult.data);

            if (data) {
                yield put(
                    actionCourierUpdate({
                        ...user,
                        cycle: { ...user.cycle, ...data },
                        roles: {
                            ...user.roles,
                            active: data.courierId && data.courierId === user.userId,
                        },
                    })
                );

                yield groupUpdateDataSaga(currentUserIsDeliveryManager()
                    ? depotCycleRefreshConfig
                    : controlCenterUpdateConfig,
                    { users: false }
                );
                yield put(actionUpdateShowCourierMarker({ userId: user.userId }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* startShiftsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
            startShifts,
            action.data
        );

        if (requestResult && requestResult.status === 200) {
            const courierIds = action.data.courierIds
            let users = yield select(getUserModel);

            if(!users.every(user => courierIds.includes(user.userId))) {
                yield getUsersByIdsSaga({ data: { ids: courierIds }})
                users = yield select(getUserModel);
            }

            const data = objectKeysToUpperLowerCase(requestResult.data);

            if (data) {
                const updateUser = cycle => {
                    const user = users.find(f => f.userId === cycle.courierId);
                    return {
                        ...user,
                        cycle: { ...user?.cycle, ...cycle },
                        roles: {
                            ...user.roles,
                            active: cycle.courierId === user.userId,
                        },
                    }
                }
                yield all(data.map(cycle => put(actionCourierUpdate(updateUser(cycle)))));

                yield groupUpdateDataSaga(currentUserIsDeliveryManager()
                    ? depotCycleRefreshConfig
                    : controlCenterUpdateConfig,
                    { users: false }
                );
                yield all(data.map(cycle => put(actionUpdateShowCourierMarker({ userId: cycle.courierId }))))
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* availableControlCenters(action) {
    yield* createSaga(function*() {
        const controlCenters = yield select(getControlCenter);
        yield put(actionUpdateRestaurantAvailableControlCenters([]));

        const requestResult = yield call(getAvailableControlCenters, action.data);
        if (requestResult && requestResult.status === 200) {
            const availableControlCenters = controlCenters.filter(controlCenter => requestResult.data?.includes(controlCenter.controlCenterId))
            yield put(actionUpdateRestaurantAvailableControlCenters(availableControlCenters));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* endShiftSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(endShift, action.data.courierId);

        if (requestResult && requestResult.status === 200) {
            let users = yield select(getUserModel);
            let user = users.find(f => f.userId === action.data.courierId);

            yield put(
                actionCourierUpdate({
                    ...user,
                    courierState: COURIER_STATE.default,
                    roles: { ...user?.roles, active: false },
                })
            );
            yield put(actionUpdateShowCourierMarker({ userId: action.data.courierId }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* cancelShiftSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(cancelCycle, action.data.courierId);

        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateShowCourierMarker({ userId: action.data.courierId }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* finishShiftSaga(action) {
    yield* createSaga(function*() {
        const { courierId } = action.data;
        const requestResult = yield call(finishCycle, courierId);

        if (requestResult && requestResult.status === 200) {
            const users = yield select(getUserModel);
            const user = users.find(f => f.userId === courierId);

            yield put(actionRemoveGroupOrders({ groupId: courierId }));
            yield put(
                actionCourierUpdate({
                    ...user,
                    cycle: {
                        ...user.cycle,
                        state: CYCLE_STATE.started,
                        routeEstimation: {},
                        optimalData: { totalDistance: 0, totalTime: 0 },
                    },
                })
            );
            yield put(actionUpdateShowCourierMarker({ userId: courierId }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* goAwayShiftSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(goAway, action.data.courierId);

        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateShowCourierMarker({ userId: action.data.courierId }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* refreshGroupSaga(action) {
    yield* createSaga(function*() {
        let requestResult;

        if (action.data.isGroup) {
            requestResult = yield call(groupCourierForGroup, action.data.courierId);
        } else {
            requestResult = yield call(groupCourier, action.data.courierId);
        }

        if (requestResult && requestResult.status === 200) {
            yield put(actionUpdateShowCourierMarker({ userId: action.data.courierId }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* disbandGroupSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(
            action.data.group.isCourierGroup ? disbandCycle : disbandGroup,
            action.data.group.id
        );

        if (requestResult && requestResult.status === 200) {
            const groups = yield select(getGroups);
            const orders = yield select(getOrders);
            const pickups = yield select(getPickups);
            const destinationGroup = groups.find(f => f.id === DEFAULT_ORDERS_GROUPS.Unsorted);
            const itemsIds = orders.filter(order => order.groupId === action.data.group.id).map(m => m.id);

            yield all([
                put(actionRemoveOrder(pickups.filter(f => f.groupId === action.data.group.id).map(m => m.id))),
                put(actionChangeItemsGroup({ destinationGroup, itemsIds })),
            ]);
            yield put(actionRemoveMarker(itemsIds.concat([action.data.group.id])));
            yield put(actionUpdateShowCourierMarker({ userId: action.data.group.id }));
            yield all([
                put(actionUpdateGroupOrdersMarkers({ groupId: destinationGroup.id })),
                put(actionUpdateGroupOrdersMarkers({ groupId: action.data.group.id })),
            ]);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateCourierMarkerSaga(action) {
    try {
        const users = yield select(getUserModel);
        const user = users.find(f => f.userId === action.data.userId);
        const orders = yield select(getOrders);
        const groupOrders = orders.filter(f => f.groupId === action.data.userId);
        if (Markers.instance.canShowMarker(user, groupOrders)) {
            yield put(actionUpdateCourierMarker(user));
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchCycleUserSaga(action) {
    try {
        const response = yield call(fetchUser, action.data.courierId);
        return requestResultIsOk(response, true) ? objectKeysToUpperLowerCase(response.data) : null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

function* courierLockUpdateSaga(action) {
    try {
        const data = objectKeysToUpperLowerCase(action.data);
        const users = yield select(getUserModel);
        const user = users.find(u => u.userId === data.courierId);

        yield put(
            actionCourierUpdate({
                ...user,
                isLocked: data.isLocked,
                cycle: user.cycle && { ...user.cycle, ...data },
            })
        );
        yield put(actionUpdateGroup(data));
    } catch (error) {
        console.log(error);
    }
}

function* courierToGroupSaga(action) {
    yield* createSaga(function*() {
        const { orderId, groupId, newOrderIndex } = action.data;
        const requestResult = yield call(courierToGroup, orderId, groupId, newOrderIndex);

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            const pickups = yield select(getPickups);
            const pickup = pickups.find(f => f.items.includes(orderId));
            if (pickup && pickup.items.length === 1) {
                yield put(actionRemoveOrder([pickup.id]));
            }

            if (groupId === DEFAULT_ORDERS_GROUPS.Unsorted) {
                const groups = yield select(getGroups);
                const destinationGroup = groups.find(f => f.id === groupId);
                yield put(actionChangeItemsGroup({ destinationGroup, itemsIds: [orderId] }));

                //Fix for depot role update pending orders
                if (currentUserIsDepotRole()) {
                    const pendingOrders = yield select(getPendingOrders);
                    const pendingOrder = pendingOrders.find(f => f.id === orderId);
                    if (pendingOrder) {
                        yield put(actionPendingOrderUpdate(removeGroupFromPendingOrder(pendingOrder)));
                    }
                }
            }

            if (data) {
                yield put(actionCycleUpdate({ courierId: data.courierId, data }));
                yield put(actionUpdateGroup({ id: data.courierId, ...data, cycle: data }));
                yield put(actionUpdateGroupOrdersMarkers({ groupId: data.courierId }));
            }

            yield put(actionUpdateGroupOrdersMarkers({ groupId: groupId }));
        } else {
            toast.error(getErrorMessage(requestResult));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}



function* usersPaginationSaga(action) {
    yield* createSaga(function*() {
        yield put(actionSetSectionItemsLoading(true));
        const users = yield select(getUserModel);
        const requestResult = yield call(updateUsersPagination, action.data);

        if(action.data.num === 0) {
            const curUserId = getUserConnectionData()?.unique_name || ''
            yield put(actionUsersSet(users.filter(user => user.userId === curUserId)))
        }

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data) || [];

            const newUsers = data?.page
              .map(m => ({ ...initUserModel(m)}))

            yield put(actionUsersUpdate(newUsers))

            return {
                users: newUsers,
                totalCount: data.totalCount || 0
            }

        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* couriersPaginationSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateUsersPagination, action.data);

        const setCycles = (users, cycles) => {
            return users.map(user => {
                const newCycle = (cycles || []).find(cycle => cycle.courierId === user.userId);

                if (newCycle && !newCycle.routeEstimation) {
                    newCycle.routeEstimation = getInitModel(MODEL_TYPE.routeEstimation);
                }

                return {
                    ...user,
                    roles: {
                        ...user.roles,
                        active: !!(newCycle && newCycle.courierId === user.userId)
                    },
                    cycle: newCycle,
                }
            })
        }

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);

            const newUsers = data?.page.map(m => ({ ...initUserModel(m)}))

            const activeStateCycles = yield select(getActiveStateCycles);
            const withCycles = setCycles(newUsers, activeStateCycles)
            return {
                users: withCycles,
                totalCount: data.totalCount || 0
            }

        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* editUserSaga(action) {
    yield* createSaga(function*() {
        let item = action.data;
        const name = separateName(item.fullName);
        item.lastName = name.lastName;
        item.firstName = name.firstName;

        const requestResult = yield call(
            editUser,
            item.userId,
            item.firstName,
            item.lastName,
            item.phoneNumber,
            item.email,
            item.workerId,
            item.hourlyRate,
            item.allowOpenShift,
            item.roles,
            item.capacity,
            item.vehicleType,
            item.defaultControlCenterId,
            item.salaryCalculationMode,
            item.creationDate,
            item.allowedControlCenters
        );

        const userId = action.data.userId
        const users = yield select(getUserModel);
        const oldUser = users.find(user => user.userId === userId)

        const emptyUser = {
            ...oldUser,
            fullName: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            workerId: '',
            roles : [],
        }

        yield put(actionUpdateUser(emptyUser));


        if (requestResult) {
            if (requestResult.status === 200) {
                yield put(actionUpdateUserById(userId))
            } else {
                yield put(actionUpdateUserById(userId))
                switch (requestResult.status) {
                    case 409:
                        return new ErrorData(i18n.t('settings.users.PHONE_NUMBER_ERROR_MSG'));
                    default:
                        return new ErrorData(getUserError(getErrorMessage(requestResult)));
                }
            }
        } else {
            yield put(actionUpdateUserById(userId))
            return new ErrorData('Error');
        }
    }, action.type);
}

function* addUserSaga(action) {
    yield* createSaga(function*() {
        let item = action.data;

        if (!item.fullName) {
            return new ErrorData(i18n.t('settings.users.USER_NAME_REQUIRED_ERROR_MSG'));
        } else if (!item.phoneNumber && item.roles.courier) {
            return new ErrorData(i18n.t('settings.users.PHONE_NUMBER_REQUIRED_ERROR_MSG'));
        } else if (!Object.keys(item.roles).some(key => item.roles[key])) {
            return new ErrorData(i18n.t('settings.users.ROLES_REQUIRED_ERROR_MSG'));
        }

        const name = separateName(item.fullName);
        item.lastName = name.lastName;
        item.firstName = name.firstName;

        const requestResult = yield call(
            addUser,
            item.firstName,
            item.lastName,
            item.phoneNumber,
            item.email,
            item.workerId,
            item.hourlyRate,
            item.allowOpenShift,
            item.roles,
            item.capacity,
            item.vehicleType,
            item.defaultControlCenterId,
            item.salaryCalculationMode,
            item.creationDate
        );

        if (requestResult) {
            if (requestResult.status === 200) {
                // after add new user we clear users state (pagination)
                yield put(actionUsersSet([]))
            } else {
                switch (requestResult.status) {
                    case 409:
                        return new ErrorData(i18n.t('settings.users.PHONE_NUMBER_ERROR_MSG'));
                    default:
                        return new ErrorData(getUserError(getErrorMessage(requestResult)));
                }
            }
        } else {
            return new ErrorData('Error');
        }
    }, action.type);
}

function* deleteUserSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(removeUser, id);
        if (requestResult && requestResult.status === 200) {
            yield put(actionDeleteUser({ userId: id }));
            yield put(actionRemoveMarker([id]));
        } else {
            return new ErrorData(i18n.t('settings.FORM_SEND_ERROR_MSG'));
        }
    }, action.type);
}

function* editUserPasswordSaga(action) {
    yield* createSaga(function*() {
        const item = action.data;

        if (!isValidStringNoSpaces(item.userName)) {
            return new ErrorData(i18n.t('settings.map.DEPOT_NAME_REQUIRED_ERROR_MSG'));
        } else if (
            !isValidStringNoSpaces(item.newPassword) ||
            !isValidStringNoSpaces(item.retypePassword) ||
            !isValidPasswordData(item)
        ) {
            return new ErrorData(i18n.t('settings.map.PASSWORD_MISMATCH_MSG'));
        } else {
            const request = yield call(
                resetUserPassword,
                item.userId,
                getInitModel(MODEL_TYPE.credentialsServerModel, {
                    userName: item.userName,
                    newPassword: item.newPassword,
                })
            );

            if (request && request.status === 200) {
                yield put(
                    actionCourierUpdate({
                        userId: item.userId,
                        userName: item.userName,
                    })
                );
            } else {
                return new ErrorData(i18n.t('settings.map.PASSWORD_CHANGING_ERROR'));
            }
        }
    }, action.type);
}

function* updateUserByIdSaga(action) {
    yield* createSaga(function*() {
        const userId = action.data;
        const reqRes1 = yield call(getCourierByUserName, userId);
        const reqRes2 = yield call(getUser, userId);
        const users = yield select(getUserModel);
        const user = users.find(f => f.userId === userId);
        let newUser = user ? { ...user } : { userId };
        if (reqRes1 && reqRes1.status === 200 && reqRes1.data) {
            const data = objectKeysToUpperLowerCase(reqRes1.data);
            newUser = {
                ...newUser,
                ...data,
            };

            if (!newUser.statistics) {
                newUser.statistics = {
                    cashCollected: 0,
                    currency: 0,
                    distanceTraveled: 0,
                    ordersDelivered: 0,
                    totalAmount: 0,
                };
            }
        }

        if (reqRes2 && reqRes2.status === 200 && reqRes2.data) {
            newUser = {
                ...newUser,
                ...objectKeysToUpperLowerCase(reqRes2.data),
            };
        }

        const reqResCycles = yield call(fetchCycle);
        if (reqResCycles && reqResCycles.status === 200 && reqResCycles.data) {
            const cycles = objectKeysToUpperLowerCase(reqResCycles.data);
            const newUserCycle = cycles.find(cycle => cycle.courierId === newUser.userId);

            if (newUserCycle && !user) {
                newUser.cycle = newUserCycle;
            }
        }

        if (user) {
            yield put(actionUpdateUser(newUser));
        } else {
            yield put(actionNewUser(newUser));
        }

        yield put(actionUpdateGroupOrdersMarkers({ groupId: newUser.userId }));
    }, action.type);
}

function* personalSettingsSaga(action) {
    yield* createSaga(function*() {
        const {
            userId,
            userFirstAndLastname,
            phoneNumber,
            email,
            roles,
            currentPassword,
            language,
            avatarUrl,
            isHideTakeawayGroup,
            isHidePreordersGroup,
            visualizeReadinessTime,
        } = action.data;
        const visualSettings = getInitModel(MODEL_TYPE.visualSettingsServerModel, action.data);
        const name = separateName(userFirstAndLastname);
        let users = yield select(getUserModel);
        let user = users.find(f => f.userId === userId);

        if (currentPassword) {
            const requestResult = yield call(
                setCurrentUser,
                name.firstName,
                name.lastName,
                phoneNumber,
                email,
                roles.courier,
                true,
                currentPassword,
                language,
                visualSettings
            );

            if (requestResult) {
                if (requestResult.status === 200) {
                    const configuration = {
                        ...user.configuration,
                        language: language,
                        visualSettings: {
                            ...user.configuration.visualSettings,
                            ...objectKeysToUpperLowerCase(visualSettings),
                            visualizeReadinessTime: visualizeReadinessTime,
                        },
                    };

                    User.instance.setCurrentUserConfiguration(configuration);
                    yield put(
                        actionCourierUpdate({
                            ...user,
                            firstName: name.firstName,
                            lastName: name.lastName,
                            email: email,
                            phoneNumber: phoneNumber,
                            configuration: configuration,
                        })
                    );
                    yield put(
                        actionUpdateGroup({
                            id: DEFAULT_ORDERS_GROUPS.takeawayOrders,
                            alwaysShow: !isHideTakeawayGroup,
                        })
                    );
                    yield put(
                        actionUpdateGroup({
                            id: DEFAULT_ORDERS_GROUPS.preOrders,
                            alwaysShow: !isHidePreordersGroup,
                        })
                    );
                } else if (requestResult.status === 409) {
                    return new ErrorData(
                        i18n.t('settings.personalSettings.personalSettings.PHONE_NUMBER_CONFLICT_MSG')
                    );
                } else if (requestResult.status === 401) {
                    return new ErrorData(i18n.t('settings.personalSettings.personalSettings.INVALID_PASSWORD_MSG'));
                } else if (
                    requestResult.response &&
                    requestResult.response.data &&
                    requestResult.response.data.Message
                ) {
                    return new ErrorData(requestResult.response.data.Message);
                } else {
                    return new ErrorData('Error save settings');
                }
            } else {
                return new ErrorData('Error save settings');
            }
        }

        if (user.avatarUrl !== avatarUrl && isBase64Image(avatarUrl)) {
            yield put(actionSetUserAvatar({ userId, avatarUrl, roles }));
        }
    }, action.type);
}

function* setUserAvatarSaga(action) {
    yield* createSaga(function*() {
        const { userId, avatarUrl, roles } = action.data;
        let users = yield select(getUserModel);
        let user = users.find(f => f.userId === userId);
        if (!isSameObjects(avatarUrl, user.avatarUrl)) {
            const requestResult = yield call(setUserAvatarBase64, userId, dataURItoBlob(avatarUrl));

            if (requestResult && requestResult.status === 200) {
                const requestResultUsers = yield call(fetchUsers);
                if (requestResultUsers && requestResultUsers.status === 200) {
                    const newUserData = requestResultUsers.data.find(f => f.UserId === user.userId);
                    yield put(actionCourierUpdate({ ...user, avatarUrl: newUserData.AvatarUrl }));
                }

                //if user is courier update marker
                if (roles.courier) {
                    users = yield select(getUserModel);
                    user = users.find(f => f.userId === userId);
                    yield put(actionUpdateCourierMarker(user));
                }
            } else {
                return new ErrorData('Error save');
            }
        }
    }, action.type);
}

function* personalSettingsPasswordSaga(action) {
    yield* createSaga(function*() {
        const { newPassword, retypePassword, currentPassword } = action.data;

        if (newPassword && currentPassword) {
            if (newPassword === retypePassword) {
                const requestResult = yield call(resetPassword, newPassword, currentPassword);
                if (requestResult && requestResult.status === 200) {
                    const authRequestResult = yield call(authorize, getCurrentUserName(), newPassword);
                    if (authRequestResult && authRequestResult.status === 200) {
                        yield put(
                            actionCreateSuccess(AUTHENTICATION, {
                                data: authRequestResult.data,
                                rememberMe: getRememberMe(),
                                username: getCurrentUserName(),
                            })
                        );
                        Interval.instance.start(Store.store);
                    } else {
                        Interval.instance.stop();
                        removeStoredData();
                    }
                } else {
                    return new ErrorData(i18n.t('settings.personalSettings.personalSettings.INVALID_PASSWORD_MSG'));
                }
            } else {
                return new ErrorData(i18n.t('settings.personalSettings.personalSettings.PASSWORD_MISMATCH_MSG'));
            }
        }
    }, action.type);
}

function* userShiftsEditSaga(action) {
    yield* createSaga(function*() {
        if(!moment(action.data.endDate).isAfter(moment(action.data.startDate))) {
            return new ErrorData(i18n.t('basic.error.INCORRECT_DATE'));
        }

        const requestResult = yield call(updateShift, getInitModel(MODEL_TYPE.shiftServerModel, action.data));
        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(
                actionRestaurantUpdate({
                    shifts: (restaurant.shifts || []).map(shift =>
                        shift.shiftId === action.data.shiftId ? { ...shift, ...action.data } : shift
                    ),
                })
            );
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}
function* userCurrentShiftsEditSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateCurrentShiftUser, getInitModel(MODEL_TYPE.currentShiftServerModel, action.data));
        const response = objectKeysToUpperLowerCase(requestResult.data);

        if (requestResultIsOk(requestResult)) {
            const users = yield select(getUserModel);
            const userId = action.data.courierId;

            if (userId) {
                const newUsers = users.map(user => {
                    if(user.userId === userId){
                        return {
                            ...user,
                            cycle: {
                                ...user.cycle,
                                controlCenterId: response.controlCenterId,
                                shiftType: action.data.shiftType,
                                startTime: action?.data?.startTime?.toISOString(),
                                endTime: action?.data?.endTime?.toISOString(),
                                allowedDepots: action.data.allowedDepots,
                            },
                        }
                    }

                    return user;
                })
                yield put(actionUsersUpdate(newUsers));
                if(action.data.shiftId) {
                    yield put(actionUpdateShifts({
                        shiftId: action.data.shiftId,
                        controlCenterId: response.controlCenterId,
                        shiftType: action.data.shiftType,
                        startDate: action?.data?.startTime?.toISOString(),
                        endDate: action?.data?.endTime?.toISOString(),
                        allowedDepots: action.data.allowedDepots
                    }));
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateCurrentShiftDepotCouriersSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateCurrentShiftDepotCouriers, getInitModel(MODEL_TYPE.currentShiftDepotServerModel, action.data));

        if (requestResultIsOk(requestResult)) {
            const users = yield select(getUserModel);
            const userIds = action.data.courierIds
            const depotId = action.data.depotId

            if (userIds && depotId) {
                const clearUsersByDepotId = users.map(user => {
                    if(user?.cycle?.allowedDepots?.includes(depotId)){
                        return {
                            ...user,
                            cycle: {
                                ...user.cycle,
                                allowedDepots: user.cycle.allowedDepots.filter(depot => depot !== depotId)
                            },
                        }
                    }

                    return user
                })

                const newUsers = clearUsersByDepotId.map(user => {
                    if(userIds.includes(user.userId)){
                        return {
                            ...user,
                            cycle: {
                                ...user.cycle,
                                allowedDepots: user.cycle?.allowedDepots ?
                                  [depotId, ...user.cycle.allowedDepots] : [depotId],
                            },
                        }
                    }

                    return user
                })

                yield put(actionUsersUpdate(newUsers));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* userShiftRemoveSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(deleteShift, id);

        if (requestResultIsOk(requestResult)) {
            const restaurant = yield select(getRestaurant);
            yield put(actionRestaurantUpdate({ shifts: restaurant.shifts.filter(f => f.shiftId !== id) }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* userShiftDownloadPdfSaga(action) {
    yield* createSaga(function*() {
        const { shiftId, isProvider, id } = action.data;

        if (!isProvider) {
            const requestResult = yield call(shiftPdf, shiftId);

            if (requestResult && requestResult.status === 200 && requestResult.data) {
                return fileFromRequestResult(requestResult);
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }

        const sectionModel = yield select(getSectionModel);
        const { currentModelType, sectionModels } = sectionModel;

        const prevModel = sectionModels[currentModelType];
        const dateStart = prevModel.model.getFilterOptionPropValue('dateStart', 'selected');
        const dateEnd = prevModel.model.getFilterOptionPropValue('endDate', 'selected');
        const controlCenterId = prevModel.model.getFilterOptionPropValue('controlCenter', 'selected');

        const requestResult = yield call(
            shiftProviderPdf,
            dateStart ? getISOTime(new Date(dateStart)) : getISOTime(new Date()),
            dateEnd
                ? getISOTime(new Date(dateEnd))
                : getISOTime(dateStart ? new Date(dateStart) : new Date()),
            id,
            controlCenterId,
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            return fileFromRequestResult(requestResult);
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* userShiftDownloadCsvSaga(action) {
    yield* createSaga(function*() {
        const { dateStart, endDate, user, controlCenter ,shiftType} = action.data.filterOptions.options;
        const startShiftDate = dateStart.selected ? new Date(dateStart.selected) : null;
        const endShiftDate = endDate.selected ? new Date(endDate.selected) : new Date();
        const controlCenterId = controlCenter.selected;
        const userId = user.selected;

        const requestResult = yield call(
            couriersCsv,
            startShiftDate,
            endShiftDate,
            null,
            controlCenterId,
            userId,
            shiftType.selected
        );

        if (requestResult && requestResult.status === 200 && requestResult.data) {
            const dataBlob = fileFromRequestResult(requestResult);

            if (dataBlob && dataBlob.file && dataBlob.fileName) {
                const blob = new Blob([dataBlob.file], {
                    type: dataBlob.contentType,
                });
                saveAs(blob, dataBlob.fileName);
            } else {
                return new ErrorData("Can't convert file");
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* externalCyclesUpdateSaga(action) {
    yield* createSaga(function*() {
        const { externalCycles, courierLocations } = action.data;
        //Save cycles
        yield put(actionExternalCyclesUpdateList(externalCycles || []));

        //Save orders and markers
        const currentExternalCycles = yield select(getExternalCycles);
        const externalCyclesOrders = extractExternalCycleOrders(currentExternalCycles);
        yield all([
            put(actionOrdersModelUpdate(externalCyclesOrders.orders)),
            put(actionExternalCyclesMarkersUpdate(externalCyclesOrders.markers)),
        ]);

        if (courierLocations) {
            yield put(actionExternalCycleUpdateFromCourierLocations(courierLocations));
        }
    }, action.type);
}

function* externalCycleCreatedSaga(action) {
    yield* createSaga(function*() {
        const data = objectKeysToUpperLowerCase(action.data);
        if (data && data.id && !User.instance.skipObjectWithControlCenter(data)) {
            const externalCycles = yield select(getExternalCycles);
            const externalCycle = externalCycles.find(f => f.id === data.id) || {};
            const newData = { ...externalCycle, ...data };

            yield put(actionExternalCycleUpdate(newData));
            if (newData && newData.orders && newData.orders.length) {
                yield put(actionExtractExternalCycleData({ id: data.id }));
            }
        }
    }, action.type);
}

function* externalCycleUpdatedSaga(action) {
    yield* createSaga(function*() {
        const data = objectKeysToUpperLowerCase(action.data);

        if (data && data.id && !User.instance.skipObjectWithControlCenter(data)) {
            const externalCycles = yield select(getExternalCycles);
            const externalCycle = externalCycles.find(f => f.id === data.id) || {};
            const newData = { ...externalCycle, ...data };

            yield put(actionExternalCycleUpdate(newData));
            if (newData.orders && newData.orders.length) {
                yield put(actionExtractExternalCycleData({ id: data.id }));
            }

            if (newData.state === EXTERNAL_CYCLE_STATE.approved) {
                yield put(actionGetIntervalCourierLocations());
            }
        } else {
            Sentry.captureException(`Error. externalCycleUpdatedSaga. data is undefined: ${JSON.stringify(data)}`);
        }
    }, action.type);
}

function* externalCycleFinishedSaga(action) {
    yield* createSaga(function*() {
        const data = objectKeysToUpperLowerCase(action.data);

        if (data && data.id) {
            if (data.state === EXTERNAL_CYCLE_STATE.closed) {
                yield put(actionExternalCycleRemove([data.id])); //Remove external group cycle
                yield put(actionRemoveGroupOrders({ groupId: data.id })); //Remove external group orders
                yield put(actionRemoveMarker((data.orders || []).map(order => order.id))); //Remove external group orders markers
            } else {
                const externalCycles = yield select(getExternalCycles);
                const externalCycle = externalCycles.find(f => f.id === data.id) || {};
                const newData = { ...externalCycle, ...data };

                yield put(actionExternalCycleUpdate(newData));
                if (newData && newData.orders && newData.orders.length) {
                    yield put(actionExtractExternalCycleData({ id: data.id }));
                }
            }

            const excludedTypes = [EXTERNAL_CYCLE_STATE.closed, EXTERNAL_CYCLE_STATE.canceled];
            if (excludedTypes.includes(data.state)) {
                yield put(actionRemoveMarker([data.id])); //Remove external group marker
            }
        }
    }, action.type);
}

function* externalCycleAssociateSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(createExternalCycle, objectKeysToUpperLowerCase(action.data, true));

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            if (data) {
                yield put(actionExternalCycleUpdate(data));
                yield put(actionExtractExternalCycleData({ id: data.id }));
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* disbandOrderFromExternalCycleSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(removeExternalCycle, objectKeysToUpperLowerCase(action.data, true));

        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);

            yield put(
                actionUpdateOrders(action.data.orderIds.map(id => ({ id, groupId: DEFAULT_ORDERS_GROUPS.Unsorted })))
            );
            yield put(actionUpdateGroupOrdersMarkers({ groupId: DEFAULT_ORDERS_GROUPS.Unsorted }));

            const externalCyclesOrders = extractExternalCycleOrders([data]);
            yield put(actionExternalCycleUpdate(data));
            yield put(actionOrdersModelUpdate(externalCyclesOrders.orders));
            yield put(actionExternalCyclesMarkersUpdate(externalCyclesOrders.markers));

            if (currentUserIsDepotRole()) {
                if (action.data.id && Array.isArray(action.data.orderIds)) {
                    const pendingOrders = yield select(getPendingOrders);
                    yield put(
                        actionPendingOrdersUpdate({
                            activeOrders: pendingOrders
                                .filter(f => action.data.orderIds.includes(f.id))
                                .map(m => removeGroupFromPendingOrder(m)),
                        })
                    );
                }
            }
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* disbandExternalCycleDataSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;
        const requestResult = yield call(cancelExternalCycle, id);

        if (requestResultIsOk(requestResult)) {
            const orders = yield select(getOrders);
            const pickups = yield select(getPickups);

            yield put(actionRemoveOrder(pickups.filter(pickup => pickup.groupId === id).map(pickup => pickup.id)));
            yield put(
                actionUpdateOrders(
                    orders
                        .filter(order => order.groupId === id)
                        .map(order => ({
                            ...order,
                            groupId: DEFAULT_ORDERS_GROUPS.Unsorted,
                            isExternalGroup: false,
                            isCourierGroup: false,
                            markerType: ICONS_MARKER_TYPE.empty,
                        }))
                )
            );
            yield put(actionUpdateGroupOrdersMarkers({ groupId: DEFAULT_ORDERS_GROUPS.Unsorted }));
            yield put(actionExternalCycleUpdate(objectKeysToUpperLowerCase(requestResult.data)));

            const activeOrders = yield select(getPendingOrders);
            yield put(
                actionPendingOrdersUpdate({
                    activeOrders: activeOrders.map(pendingOrder =>
                        (pendingOrder.groupId || pendingOrder.courier_id) === id
                            ? removeGroupFromPendingOrder(pendingOrder)
                            : pendingOrder
                    ),
                })
            );
        } else {
            toast.error(getErrorMessage(requestResult));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* extractExternalCycleDataSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data;

        if (id) {
            const externalCycles = yield select(getExternalCycles);
            const externalCycle = externalCycles.find(f => f.id === id);
            const externalCyclesOrders = extractExternalCycleOrders([externalCycle]);
            yield put(actionOrdersModelUpdate(externalCyclesOrders.orders));
            yield put(actionExternalCyclesMarkersUpdate(externalCyclesOrders.markers));

            //Fix for depot user role update pending orders data
            if (currentUserIsDepotRole() && Array.isArray(externalCyclesOrders.orders)) {
                const newPendingOrders = externalCyclesOrders.orders
                    .filter(order => order.type !== ORDER_TYPE.pickUp)
                    .map(order => ({ ...order, groupId: id, courierId: id, courier_id: id, isExternalGroup: true }));
                yield put(actionPendingOrdersUpdate({ activeOrders: newPendingOrders }));
            }
        }
    }, action.type);
}

function* addOrderToExternalCycleSaga(action) {
    yield* createSaga(function*() {
        const orders = yield select(state => state.order.data.orders);
        const externalGroup = yield select(state => state.externalCycles.data);
        const newOrdersInGroup = orders.filter(o => action.data.orderIds.includes(o.id));
        const group = externalGroup.find(g => g.id === action.data.id);
        if(!newOrdersInGroup.every(o => o.controlCenterId === group.controlCenterId)) {
            return new ErrorData('Error');
        }
        const requestResult = yield call(addExternalCycle, objectKeysToUpperLowerCase(action.data, true));
        if (requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data);
            yield put(actionExternalCycleUpdate(data));
            yield put(actionExtractExternalCycleData({ id: data.id }));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateExternalCyclesFromCourierLocationsSaga(action) {
    yield* createSaga(function*() {
        const externalCycles = yield select(getExternalCycles);
        const externalCycleIds = externalCycles.map(m => m.id);
        const data = extractExternalCycleCourierMarkers(
            (action.data || []).filter(f => externalCycleIds.includes(f.courierId)),
            externalCycles
        );

        yield all([
            put(actionExternalCyclesUpdateList(data.externalCycles)),
            put(actionExternalCyclesMarkersUpdate(data.markers)),
        ]);
    }, action.type);
}

function* confirmExternalCycleSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data || {};

        if (id) {
            const requestResult = yield call(confirmExternalCycle, id);
            if (requestResultIsOk(requestResult)) {
                const data = objectKeysToUpperLowerCase(requestResult.data);
                yield put(actionExternalCycleUpdate(data));
                yield put(actionExtractExternalCycleData({ id: data.id }));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        } else {
            return new ErrorData('Error');
        }
    }, action.type);
}

function* checkUserGroupSaga(action) {
    yield* createSaga(function*() {
        const { userId, data } = action.data || {};

        if (userId && !isDefaultGroup(userId)) {
            let users = yield select(getUserModel);
            let user = users.find(u => u.userId === userId);

            if (!user) {
                const resUser = yield call(fetchUser, userId);
                if (requestResultIsOk(resUser, true)) {
                    yield put(actionNewUser({ ...objectKeysToUpperLowerCase(resUser.data), ...(data || {}) }));
                }
            }

            const groups = yield select(getGroups);
            if (!groups.find(u => u.id === userId)) {
                users = yield select(getUserModel);
                user = users.find(u => u.userId === userId);
                if (user) {
                    const group = getGroup(DEFAULT_ORDERS_GROUPS.courierGroup, user);
                    yield put(actionUpdateGroup(group));
                }
            }
        }
    }, action.type);
}

function* lockGroupSaga(action) {
    yield* createSaga(function*() {
        const group = (action.data || {}).group;

        if (group) {
            const id = group.id || group.userId;

            if (isNotDefaultOrderGroup(id)) {
                const isLocked = group.isLocked || (group.cycle || {}).isLocked;
                const requestResult = yield call(isLocked ? unlockCourierGroup : lockCourierGroup, id);
                if (requestResultIsOk(requestResult)) {
                    yield put(actionCheckUserGroup({ userId: id }));

                    const users = yield select(getUserModel);
                    const user = users.find(u => u.userId === id);
                    yield put(
                        actionCourierUpdate({
                            ...user,
                            isLocked: !isLocked,
                            cycle: user.cycle && {
                                ...user.cycle,
                                isLocked: !isLocked,
                            },
                        })
                    );
                    yield put(actionUpdateGroup({ id, isLocked: !isLocked }));
                } else {
                    return new ErrorData(getErrorMessage(requestResult));
                }
            } else {
                const requestResult = yield call(group.isLocked ? unlockGroup : lockGroup, id);
                if (requestResultIsOk(requestResult)) {
                    yield put(actionUpdateGroup({ id, isLocked: !group.isLocked }));
                } else {
                    return new ErrorData(getErrorMessage(requestResult));
                }
            }
        }
    }, action.type);
}

function* pusherUserUpdatedSaga(action) {
    yield* createSaga(function*() {
        const data = objectKeysToUpperLowerCase((action.data || {}).User);
        if (data) {
            if (data.userId === getUserConnectionData().unique_name) {
                const restaurant = yield select(getRestaurant);
                const controlCenterId = data?.controlCenterId || restaurant?.activeControlCenter?.controlCenterId;
                User.instance.setCurrentUserInfo({ ...data, controlCenterId });
            }

            yield put(actionUpdateUser(data));
        }
    }, action.type);
}

export function* getUsersByIdsSaga(action) {
  yield* createSaga(function*() {
    const { ids } = action.data || {};

    const userModel = yield select(getUserModel)

    if(ids.every(userId => userModel.some(user => user.userId === userId))) return

    if((ids || []).length) {
        const uniqueUserIds = [...new Set(ids)]
        const requestResult = yield call(getUsersByIds, { ids: uniqueUserIds });
        if(requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data) || [];
            const newUsers = data
              .map(m=> ({ ...initUserModel(m)}))
            yield put(actionUsersUpdate(newUsers));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }
  }, action.type);
}

export function* getUserByIdSaga(action) {
    yield* createSaga(function*() {
        const { id } = action.data || {};

        const userModel = yield select(getUserModel)

        if(userModel.some(user => user.userId === id )) return

        if(id) {
            const requestResult = yield call(fetchUser, id);
            if(requestResultIsOk(requestResult)) {
                const user = objectKeysToUpperLowerCase(requestResult?.data)
                yield put(actionUsersUpdate([ initUserModel(user) ]));
            } else {
                return new ErrorData(getErrorMessage(requestResult));
            }
        }
    }, action.type);
}

export function* getUsersByAvailableCouriersIdsSaga(action) {
    yield* createSaga(function*() {
        const { controlCenter } = action.data || {};
        const users = yield select(getUserModel);

        yield put(actionSetSectionItemsLoading(true));

        const requestResult = yield call(fetchShiftActiveUsers, controlCenter || '');
        if(requestResultIsOk(requestResult)) {
            const data = objectKeysToUpperLowerCase(requestResult.data || []);
            const shiftActiveUserIds = data.map(user => user.userId);

            if(!shiftActiveUserIds.every(activeUser => users.some(user => user.userId === activeUser) )) {
                yield getUsersByIdsSaga({ data: { ids: shiftActiveUserIds } })
            }

            yield put(actionSetSectionItemsLoading(false));

            return {
                shiftActiveUserIds,
            }
        } else {
            yield put(actionSetSectionItemsLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* checkColorsForUsersSaga(action){
  yield* createSaga(function*() {
    const usersIds = action.data;
    if(Array.isArray(usersIds) && usersIds.length) {
      const users = yield select(getUserModel);
      const usersNeededUpdate = (users || [])
        .reduce((acc, user)=> {
          if(usersIds.includes(user.userId) && !user.color) {
            acc.push({ ...user, color: ColorService.instance.getColor() })
          }

          return acc;
        }, []);

        if(usersNeededUpdate.length) {
          yield put(actionUsersUpdate(usersNeededUpdate));
        }
    }

  }, action.type);
}

export function* getDefaultUsersState(action){
    yield* createSaga(function*() {
        yield put(actionSetSectionItemsLoading(true));
        const users = yield select(getUserModel);

        const curUserId = getUserConnectionData()?.unique_name || ''
        if(!users.some(user => user.userId === curUserId)) {
            yield getUsersByIdsSaga({ data: { ids: [ curUserId ]}})
        }

        const oneUserAndIsCourier = users.length === 1 && users[0].userId === curUserId;

        if(action.cycles && action.courierLocations) {
            const cyclesIds = action.cycles.map(cycle => cycle.courierId)
            if(oneUserAndIsCourier || !users.every(user => cyclesIds.some(cycle => cycle === user.userId))) {
                yield getUsersByIdsSaga({ data: { ids: cyclesIds } })
            }
            if (action.courierLocations || action.cycles) {
                yield usersSaga({ courierLocations: action.courierLocations, cycles: action.cycles });
            }
            yield put(actionSetSectionItemsLoading(false));
            return
        }

        const requestResult = yield call(fetchCourierLocations);
        if(requestResultIsOk(requestResult)) {

            const courierLocations = objectKeysToUpperLowerCase(requestResult.data)
            const activeStateCycles = yield select(getActiveStateCycles);
            const cyclesIds = activeStateCycles.map(cycle => cycle.courierId)

            if(oneUserAndIsCourier || !users.every(user => cyclesIds.some(cycle => cycle === user.userId))) {
                yield getUsersByIdsSaga({ data: { ids: cyclesIds } })
            }

            if (courierLocations || activeStateCycles) {
                yield usersSaga({ courierLocations, cycles: activeStateCycles });
            }
        }

        yield put(actionSetSectionItemsLoading(false));
    }, action.type);
}


export default function*() {
    yield takeEvery(LOAD_USERS, usersSaga);
    yield takeEvery(LOAD_USER_MARKERS, fetchUserAvatarsSaga);
    yield takeEvery(PUSHER_CYCLE_STATE_UPDATE, cycleStateUpdateSaga);
    yield takeEvery(LOAD_COURIER_LOCATIONS, courierLocationsSaga);
    yield takeEvery(INTERVAL_COURIER_LOCATIONS, updateCourierLocationSaga);
    yield takeEvery(COURIER_APPROVE_ORDERS, approveOrdersSaga);
    yield takeEvery(PUSHER_CYCLE_DISPATCHED, cycleDispatchedSaga);
    yield takeEvery(PUSHER_COURIER_SHIFT_CREATED, courierShiftCreatedSaga);
    yield takeEvery(START_SHIFT_BACKUP, startBackupSaga);
    yield takeEvery(START_SHIFTS, startShiftsSaga);
    yield takeEvery(AVAILABLE_CONTROL_CENTERS, availableControlCenters);
    yield takeEvery(END_SHIFT, endShiftSaga);
    yield takeEvery(CANCEL_SHIFT, cancelShiftSaga);
    yield takeEvery(FINISH_SHIFT, finishShiftSaga);
    yield takeEvery(GOAWAY_SHIFT, goAwayShiftSaga);
    yield takeEvery(REFRESH_GROUP, refreshGroupSaga);
    yield takeEvery(DISBAND_GROUP, disbandGroupSaga);
    yield takeEvery(UPDATE_SHOW_COURIER_MARKER, updateCourierMarkerSaga);
    yield takeEvery(PUSHER_COURIER_LOCK_UPDATE, courierLockUpdateSaga);
    yield takeEvery(COURIER_TO_GROUP, courierToGroupSaga);
    yield takeEvery(USERS_PAGINATION, usersPaginationSaga);
    yield takeEvery(COURIER_PAGINATION, couriersPaginationSaga);
    yield takeEvery(USER_SETTING_EDIT, editUserSaga);
    yield takeEvery(USER_SETTING_ADD, addUserSaga);
    yield takeEvery(USER_SETTING_DELETE, deleteUserSaga);
    yield takeEvery(USER_SETTING_PASSWORD_EDIT, editUserPasswordSaga);
    yield takeEvery(UPDATE_USER_BY_ID, updateUserByIdSaga);
    yield takeEvery(PERSONAL_SETTING_EDIT, personalSettingsSaga);
    yield takeEvery(SET_USER_AVATAR, setUserAvatarSaga);
    yield takeEvery(PERSONAL_SETTING_PASSWORD_EDIT, personalSettingsPasswordSaga);
    yield takeEvery(USER_SHIFTS_EDIT, userShiftsEditSaga);
    yield takeEvery(USER_SHIFTS_DELETE, userShiftRemoveSaga);
    yield takeEvery(USER_CURRENT_SHIFTS_EDIT, userCurrentShiftsEditSaga);
    yield takeEvery(UPDATE_CURRENT_SHIFTS_DEPOT_COURIERS, updateCurrentShiftDepotCouriersSaga);
    yield takeEvery(USER_SHIFT_DOWNLOAD_PDF, userShiftDownloadPdfSaga);
    yield takeEvery(USER_SHIFT_COURIERS_CSV, userShiftDownloadCsvSaga);
    yield takeEvery(EXTERNAL_CYCLES_UPDATE, externalCyclesUpdateSaga);
    yield takeEvery(PUSHER_EXTERNAL_CYCLE_CREATED, externalCycleCreatedSaga);
    yield takeEvery(PUSHER_EXTERNAL_CYCLE_UPDATED, externalCycleUpdatedSaga);
    yield takeEvery(PUSHER_EXTERNAL_CYCLE_FINISHED, externalCycleFinishedSaga);
    yield takeEvery(ASSOCIATE_ORDER_TO_EXTERNAL_CYCLE, externalCycleAssociateSaga);
    yield takeEvery(DISBAND_ORDER_FROM_EXTERNAL_CYCLE, disbandOrderFromExternalCycleSaga);
    yield takeEvery(EXTRACT_EXTERNAL_CYCLE_DATA, extractExternalCycleDataSaga);
    yield takeEvery(EXTERNAL_CYCLE_DISBAND, disbandExternalCycleDataSaga);
    yield takeEvery(ADD_ORDER_TO_EXTERNAL_CYCLE, addOrderToExternalCycleSaga);
    yield takeEvery(EXTERNAL_CYCLES_UPDATE_FROM_COURIER_LOCATIONS, updateExternalCyclesFromCourierLocationsSaga);
    yield takeEvery(EXTERNAL_CYCLE_CONFIRM, confirmExternalCycleSaga);
    yield takeEvery(CHECK_USER_GROUP, checkUserGroupSaga);
    yield takeEvery(LOCK_GROUP, lockGroupSaga);
    yield takeEvery(PUSHER_USER_UPDATED, pusherUserUpdatedSaga);
  yield takeEvery(LOAD_USERS_BY_IDS, getUsersByIdsSaga);
  yield takeEvery(LOAD_USERS_BY_ID, getUserByIdSaga);
  yield takeEvery(GET_USERS_BY_AVAILABLE_COURIERS_IDS, getUsersByAvailableCouriersIdsSaga);
  yield takeEvery(USERS_CHECK_COLORS, checkColorsForUsersSaga);
  yield takeEvery(GET_DEFAULT_USERS_STATE, getDefaultUsersState);
}

import environment from 'environment';
import {getUserRole} from '../utils/auth-util';
import {
    configurationForDeliveryManager,
    configurationForDeliveryManagerWithDepot,
    defaultRolesManagerConfig,
    getDepotLinksConfiguration,
    getDepotReadonlySectionPages,
    getDepotSectionItemEditConfiguration,
    initRolesManagerConfig,
} from '../utils/constants';
import {
    BASIC_PATH,
    CONTROL_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    ORDERING_MENU_TYPE_SECTION_MODEL,
    ROLES_MANAGER_TYPE,
    SHIFTS_MODEL,
    TYPE_SECTION_MODEL,
} from '../utils/enums';
import {currentUserIsDepotRole} from './userModel.js';

let singleton = Symbol();
let singletonEnforcer = Symbol();
export default class RoleManager {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer)
            throw new Error("Instantiation failed: use RoleManager.getInstance() instead of new.");
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new RoleManager(singletonEnforcer);
            this[singleton].setRolesManagerConfiguration();
            return this[singleton];
        }
        return this[singleton];
    }

    static set instance(v) {
        throw new Error("Can't change constant property!");
    }

    isAuthorized(type, value) {
        if(!value) return
        const roles = getUserRole();
        if(!roles)
            return true;
        const rez = (this.rolesManagerConfiguration || [])
            .some(role=> roles.includes(role.id) && role[type][value.split("?")[0]]);
        if(value === '/marketplace-settings/orderingmenu/orderingMenuCategories/new'){
        }
        return rez
    }

    isAccessorAuthorized(typeModel, accessor) {
        const roles = getUserRole();
        if(!roles) {
            return true;
        }

        return (this.rolesManagerConfiguration || [])
            .some((role) => {
                return roles.includes(role.id) &&
                  (role[ROLES_MANAGER_TYPE.sectionItemEdit][typeModel] || {})[accessor] !== false;
            });
    }

    isReadonlyPage(typeModel) {
        const roles = getUserRole();
        if(!roles || !this.rolesManagerConfiguration) {
            return true;
        }

        return roles.every((role) => {
            const settings = this.rolesManagerConfiguration.find(s => s.id === role);
            return (settings && settings[ROLES_MANAGER_TYPE.readonlyPage] && settings[ROLES_MANAGER_TYPE.readonlyPage][typeModel]) === true || false;
        });
    }

    getFirstAuthorizedLink() {
        return Object.keys(defaultRolesManagerConfig[ROLES_MANAGER_TYPE.menu])
            .find(key=> this.isAuthorized(ROLES_MANAGER_TYPE.menu, key));
    }

    setRolesManagerConfiguration() {
        this.rolesManagerConfiguration = [
            currentUserIsDepotRole()
                ? configurationForDeliveryManagerWithDepot
                : configurationForDeliveryManager,
            {
                id: environment.USER_ROLES.controlCenterManager,
                ...defaultRolesManagerConfig,
                ...initRolesManagerConfig,
                [ROLES_MANAGER_TYPE.menu]: {
                    ...initRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
                    [TYPE_SECTION_MODEL.controlCenters]: true,
                    [TYPE_SECTION_MODEL.controlCentersEdit]: true,
                    [SHIFTS_MODEL.historyEdit]: false,
                    [SHIFTS_MODEL.currentShiftDepotEdit]: false,
                    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
                },
                [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
                    ...initRolesManagerConfig[ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]],
                    [`${[SHIFTS_MODEL.history]}DeleteButton`]: false,
                    [`${[SHIFTS_MODEL.providers]}DeleteButton`]: false,
                    [`${[SHIFTS_MODEL.currentShiftUsers]}DeleteButton`]: false,
                },
            },
            {
                id: environment.USER_ROLES.supervisor,
                ...defaultRolesManagerConfig,
                ...initRolesManagerConfig,
                [ROLES_MANAGER_TYPE.menu]: {
                    ...initRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
                    [TYPE_SECTION_MODEL.controlCenters]: true,
                    [TYPE_SECTION_MODEL.controlCentersEdit]: true,
                },
            },
            {
                id: environment.USER_ROLES.supportOperator,
                ...defaultRolesManagerConfig,
                [ROLES_MANAGER_TYPE.menu]: {
                    // ...initRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
                    "/": false,
                    [BASIC_PATH.adminOrders]: true,
                    "/settings": true,
                    "/marketplace-settings": true,
                    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
                    [TYPE_SECTION_MODEL.personalSettings]: true,
                    [SHIFTS_MODEL.historyEdit]: false,
                    [SHIFTS_MODEL.currentShiftDepotEdit]: false,
                    [MARKETPLACE_SETTINGS_MODEL.paymentsConfiguration]: true,
                    [MARKETPLACE_SETTINGS_MODEL.deepLink]: true,
                    [MARKETPLACE_SETTINGS_MODEL.loyalty]: true,
                    [MARKETPLACE_SETTINGS_MODEL.campaigns]: true,
                    [MARKETPLACE_SETTINGS_MODEL.campaignsNew]: true,
                    [MARKETPLACE_SETTINGS_MODEL.campaignsEdit]: true,
                    [MARKETPLACE_SETTINGS_MODEL.campaignsExecutions]: true,
                    [MARKETPLACE_SETTINGS_MODEL.appBuilder]: true,
                    [MARKETPLACE_SETTINGS_MODEL.pages]: true,
                    [MARKETPLACE_SETTINGS_MODEL.pagesNew]: true,
                    [MARKETPLACE_SETTINGS_MODEL.pagesEdit]: true,
                },
                [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
                    ...defaultRolesManagerConfig[ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]],
                    [`${[SHIFTS_MODEL.history]}DeleteButton`]: false,
                    [`${[SHIFTS_MODEL.providers]}DeleteButton`]: false,
                    [`${[SHIFTS_MODEL.currentShiftUsers]}DeleteButton`]: false,
                    [`${[MARKETPLACE_SETTINGS_MODEL.pages]}DeleteButton`]: true,
                    [`${[MARKETPLACE_SETTINGS_MODEL.pages]}AddButton`]: true,
                    [`${[MARKETPLACE_SETTINGS_MODEL.campaigns]}DeleteButton`]: true,
                    [`${[MARKETPLACE_SETTINGS_MODEL.campaigns]}AddButton`]: true,
                },
            },
            {
                id: environment.USER_ROLES.courier,
                ...defaultRolesManagerConfig,
                [ROLES_MANAGER_TYPE.menu]: {
                    ...defaultRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
                    '/': false,
                    [BASIC_PATH.editCustomerOrder]: false,
                    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
                },
                [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: {
                    ...[initRolesManagerConfig[ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]],
                    [`${[SHIFTS_MODEL.history]}DeleteButton`]: false,
                    [`${[SHIFTS_MODEL.providers]}DeleteButton`]: false,
                    [`${[SHIFTS_MODEL.currentShiftUsers]}DeleteButton`]: false,
                }
            },
            {
                id: environment.USER_ROLES.depot,
                ...defaultRolesManagerConfig,
                [ROLES_MANAGER_TYPE.menu]: {
                    ...defaultRolesManagerConfig[ROLES_MANAGER_TYPE.menu],
                    [TYPE_SECTION_MODEL.personalSettingsConfirmPassword]: true,
                    "/": true,
                    "/orders": true,
                    "/new-order": true,
                    "/edit-order": true,
                    "/edit-customer-order": true,
                    "/assistance": true,
                    "/menu": true,
                    "/settings": true,
                    "/marketplace-settings": false,
                    "/info": true,
                    "/pendingorders": false,
                    [TYPE_SECTION_MODEL.personalSettings]: true,
                    [TYPE_SECTION_MODEL.users]: false,
                    [TYPE_SECTION_MODEL.usersNew]: true,
                    [TYPE_SECTION_MODEL.cloudPrinters]: true,
                    [TYPE_SECTION_MODEL.cloudPrintersEdit]: true,
                    [TYPE_SECTION_MODEL.cloudPrintersNew]: true,

                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.translations]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.sku]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenuEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenu]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscountsEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption]: true,
                    // // [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboptionEdit]: true,
                    // // [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboptionNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsDeepLinkEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.gallery]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryNew]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.galleryEdit]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuCategories]: true,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink]: true,
                },
                [ROLES_MANAGER_TYPE.readonlyPage]: {
                    ...getDepotReadonlySectionPages(),
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo]: false,
                    [ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu]: false,
                },
                [ROLES_MANAGER_TYPE.sectionItemEdit]: getDepotSectionItemEditConfiguration(),
                [ROLES_MANAGER_TYPE[CONTROL_TYPE.linkIconComponent]]: getDepotLinksConfiguration()
            }
        ]
    }
}

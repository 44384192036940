import React, {useEffect, useState} from 'react';
import './style.scss';
import {fetchShiftProvidersOrders} from "../../../api/user-requests.js";
import {objectKeysToUpperLowerCase} from "../../../utils/objects-util.js";
import {useTranslation} from "react-i18next";
import OrderTitleInfo from "../OrderTitleInfo/index.js";
import {getAmountForType, getDeliveredDate, getFormattedDeliveryDuration} from "../../../services/order.js";
import {getCurrencyIcons} from "../../../utils/enums.js";
import {getStaticTime} from "../../../utils/convertTime.js";
import StatusOrder from "../StatusOrder/index.js";

const ServiceProviderHistoryOrders = ({orderIds}) => {
    const {t} = useTranslation();
    const [orders, setOrders] = useState(null);

    useEffect(() => {
        if(!orderIds) {
            return;
        }

        fetchShiftProvidersOrders(orderIds).then(data => {
            setOrders(objectKeysToUpperLowerCase(data.data));
        }).catch(() => {
            setOrders([]);
        })
    }, [orderIds]);

    return (
        <table className={'serviceProviderHistoryOrders'}>
            <thead>
                <tr>
                    <td>{t('couriers.NAME_TABLE_TITLE')}</td>
                    <td>{t('shifts.ORDER_ID')}</td>
                    <td>{t('couriers.PAYMENT_CASH')}</td>
                    <td>{t('couriers.PAYMENT_CREDIT_CARD')}</td>
                    <td>{t('couriers.DELIVERY_TIME_TABLE_TITLE')}</td>
                    <td>{t('couriers.DELIVERY_AT_TABLE_TITLE')}</td>
                </tr>
            </thead>
            <tbody>
                {orders?.map(order => (<tr>
                    <td><OrderTitleInfo order={order}/></td>
                    <td>{order.orderId}</td>
                    <td>{`${getAmountForType(order, true)}${getCurrencyIcons(order.currency)}`}</td>
                    <td>{`${getAmountForType(order)}${getCurrencyIcons(order.currency)}`}</td>
                    <td>{getFormattedDeliveryDuration(order)}</td>
                    <td>{getStaticTime(getDeliveredDate(order))}</td>
                    <td><StatusOrder order={order} /></td>
                </tr>))}
            </tbody>
        </table>
    )
}

export default ServiceProviderHistoryOrders
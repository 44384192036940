import {convertToBoolean} from "../utils/objects-util.js";

export function  productOptionModel(data) {
    let model = data || {};

    return {
        id: model.id || "",
        name: model.name || "",
        enabled: model.enabled || false,
        order: model.order || 0,
        conditioned: model.conditioned || false,
        respect_to: model.respect_to || null,
        min: model.min || 0,
        max: model.max || 0,
        free_budget : model.free_budget  || 0,
        with_half_option: model.with_half_option || false,
		    sku : model.sku || null,
        allow_suboption_quantity: model.allow_suboption_quantity || false,
        limit_suboptions_by_max: model.limit_suboptions_by_max || false,
        reduce_price_for_half_option: model.reduce_price_for_half_option || false,
        suboptions: model.suboptions || [],
        description: model.description || "",
        pizza_option: model.pizza_option || false,
    }
}

export function optionModelOverride(data) {
    let model = data || {};

    return {
        id: model.id || "",
        name: model.name || "",
        description: model.description || "",
        min: model.min || 0,
        max: model.max || 0,
        sku : model.sku || null,
        free_budget : model.free_budget || 0,
        with_half_option: convertToBoolean(model.with_half_option) || false,
        allow_suboption_quantity: convertToBoolean(model.allow_suboption_quantity) || false,
        limit_suboptions_by_max: convertToBoolean(model.limit_suboptions_by_max) || false,
        pizza_option: convertToBoolean(model.pizza_option) || false,
    }
}


export function optionModelWithOverrides(data) {
    const model = data || {};
    const overridesProperty = [];

    if(model && model.overrides) {
        model.overrides.forEach(ov => {
            if(ov.id === model.id) {
                model[ov.name] = ov.value;
                overridesProperty.push(ov.name);
            }
        })
    }

    return {
        ...optionModelOverride(model),
        overridesProperty: overridesProperty,
    }
}

import MenuModel from './menuModel';

import { ORDERING_MENU_TYPE_SECTION_MODEL } from '../utils/enums';
import { currentUserIsDeliveryManager, currentUserIsDepotRole } from '../services/userModel';
import { getCurrentDepotByBusiness, isShowParentBusinessMenu } from '../services/restaurant';

export function createOrderingMenu() {
    return [
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
            title: 'settings.orderingmenu.MENU_BUSINESS_PARENT_MENU',
            icon: 'parentMenu',
            isShow: () => isShowParentBusinessMenu(),
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
            title: 'settings.orderingmenu.MENU_BUSINESS_MENU',
            icon: 'childMenu',
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location =>
                getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu) ||
                getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo,
            title: 'settings.orderingmenu.MENU_BUSINESS_INFO',
            icon: 'businessInfo',
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuBusinesInfo),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.gallery,
            title: 'settings.business-gallery.LABEL',
            icon: 'businessGallery',
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.gallery),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule,
            title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE',
            icon: 'orderingBusinessSchedule',
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuSchedule),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones,
            title: 'settings.orderingmenu.MENU_DELIVERY_ZONES',
            icon: 'deliveryZone',
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDeliveryZones),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts,
            title: 'settings.orderingmenu.MENU_DISCOUNTS',
            titleInfo: 'basic.BETA_LABEL',
            icon: 'discounts',
            isTitleInfoShow: function() {
                return currentUserIsDepotRole();
            },
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuDiscounts),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuCategories,
            title: 'settings.businessCategories.LABEL',
            titleInfo: 'settings.businessCategories.LABEL',
            icon: 'orderingBusinessCategories',
            isTitleInfoShow: function() {
                return currentUserIsDepotRole() && !currentUserIsDeliveryManager();
            },
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuCategories),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink,
            title: 'settings.deepLink.LABEL',
            titleInfo: 'settings.deepLink.LABEL',
            icon: 'link',
            isTitleInfoShow: function() {
                return currentUserIsDepotRole() && !currentUserIsDeliveryManager();
            },
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuLink),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.translations,
            title: 'settings.translations.LABEL',
            titleInfo: 'settings.translations.LABEL',
            icon: 'translations',
            isTitleInfoShow: function() {
                return currentUserIsDepotRole() && !currentUserIsDeliveryManager();
            },
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.translations),
        }),
        new MenuModel({
            pathname: ORDERING_MENU_TYPE_SECTION_MODEL.sku,
            title: 'settings.sku_override.LABEL',
            titleInfo: 'settings.sku_override.LABEL',
            icon: 'sku',
            isTitleInfoShow: function() {
                return currentUserIsDepotRole() && !currentUserIsDeliveryManager();
            },
            searchParams: state => {
                return `?id=${getCurrentDepotByBusiness(state)}`;
            },
            active: location => getIsActive(location, ORDERING_MENU_TYPE_SECTION_MODEL.sku),
        }),
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}

import ControlModel from '../../models/controlModel';
import { MODEL_CONTROL_TYPE } from '../../utils/enums';

export function createIconControlModel({ ...args }) {
  return new ControlModel({
    action: args.action || "",
    text: args.text || "",
    tooltipText: args.tooltipText || "",
    icon: args.icon || "",
    isShow: args.isShow || function () {return true},
    hideTooltip: args.hideTooltip,
    type: MODEL_CONTROL_TYPE.iconControl
  });
}

import {callServer} from '../utils/request-util';
import environment from 'environment';

export function fetchUsers() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.supportOperator
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchUser(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/" + id,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getUsersByIds(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "userByIds",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchCourierLocations() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/courierLocations",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchCourierAvatarBase64(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/avatar/" + courierId,
      method: "GET",
      responseType: "blob"
    }).then(
      response => {
        resolve(response);
      },
      error => {
        resolve(null);
      }
    );
  });
}

export function approveOrders(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}cycle/approveCycle/${courierId}`,
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function courierQRCode(courierId, applicationType) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/deviceQRCode/" + courierId,
      method: "POST",
      data: {
        ApplicationType: applicationType
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function depotQRCode(depotId,userId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: `${environment.backendAPI}depot/${depotId}/user/${userId}/deviceQRCode`,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function sendCourierInvitation(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/deviceinvitation/" + courierId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getAvailableControlCenters(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "controlCenters/availableControlCenters/" + courierId,
      method: "POST",
      roles: [
        environment.USER_ROLES.all,
        environment.USER_ROLES.supervisor,
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.courier,
        environment.USER_ROLES.supportOperator,
        environment.USER_ROLES.controlCenterManager,
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function goAway(groupId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/goaway/" + groupId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function cancelCycle(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/cancelCycle/" + courierId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function finishCycle(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/finishCycle/" + courierId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function startShift1(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/startShift",
      method: "POST",
      data: {
        ...data
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function startShifts(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/startMultipleShifts",
      method: "POST",
      data: {
        ...data
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function endShift(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/endShift",
      method: "POST",
      data: {
        CourierId: courierId
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function groupCourier(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/buildoptimalroute/" + id,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function cycleGroupCourier(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/group/" + id,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function groupCourierForGroup(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "groups/buildoptimalroute/" + id,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}


export function groupFutureRoute() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "groups/group",
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function disbandCycle(courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/disbandCycle/" + courierId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function unlockCourierGroup(groupId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/unlock/" + groupId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function lockCourierGroup(groupId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/lock/" + groupId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchShiftActiveUsers(controlCenterId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/availableCouriers" + ((controlCenterId && `/${controlCenterId}`) || ""),
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function courierToGroup(orderId, groupId, newOrderIndex) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/unassociateOrder",
      method: "POST",
      data: {
        OrderId: orderId,
        GroupId: groupId,
        Index: newOrderIndex
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function editUser(
  userId,
  firstName,
  lastName,
  phoneNumber,
  email,
  workerId,
  hourlyRate,
  allowOpenShift,
  roles,
  capacity,
  vehicleType,
  defaultControlCenterId,
  salaryCalculationMode,
  creationDate,
  allowedControlCenters
) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/" + userId,
      method: "PUT",
      data: {
        AllowedControlCenters: allowedControlCenters || [],
        FirstName: firstName || "",
        LastName: lastName || "",
        PhoneNumber: phoneNumber || "",
        Email: email || "",
        WorkerId: workerId || "",
        Capacity: capacity || 0,
        HourlyRate: hourlyRate || 0,
		    AllowOpenShift: allowOpenShift || false,
        Roles: Object.keys(roles)
          .filter(key=> roles[key])
          .map(key=> ({ Name: key.toFirstCharUpperCase() })),
        VehicleType: vehicleType,
        DefaultControlCenterId: defaultControlCenterId || "",
		    SalaryCalculationMode: salaryCalculationMode,
        CreationDate: creationDate
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addUser(
  firstName,
  lastName,
  phoneNumber,
  email,
  workerId,
  hourlyRate,
  allowOpenShift,
  roles,
  capacity,
  vehicleType,
  defaultControlCenterId,
  salaryCalculationMode,
  creationDate
) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user",
      method: "POST",
      data: {
        FirstName: firstName || "",
        LastName: lastName || "",
        PhoneNumber: phoneNumber || "",
        Email: email || "",
        WorkerId: workerId || "",
        Capacity: capacity || 0,
        HourlyRate: hourlyRate || 0,
		    AllowOpenShift: allowOpenShift || false,
        Roles: Object.keys(roles)
          .filter(key=> roles[key])
          .map(key=> ({ Name: key.toFirstCharUpperCase() })),
        VehicleType: vehicleType,
        DefaultControlCenterId: defaultControlCenterId,
		    SalaryCalculationMode:salaryCalculationMode,
        CreationDate: creationDate
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function removeUser(userId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/" + userId,
      method: "DELETE",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function resetUserPassword(userId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.authAPI + "profile/resetPassword/" + userId,
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supervisor]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getCourierByUserName(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getUser(userId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/" + userId,
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setCurrentUser(
  firstName,
  lastName,
  phoneNumber,
  email,
  isCourier,
  isManager,
  password,
  language,
  visualSettings
) {
  return new Promise((resolve, reject) => {
    let roles = [];
    if (isCourier) {
      roles.push({ Name: environment.USER_ROLES.courier });
    }
    if (isManager) {
      roles.push({ Name: environment.USER_ROLES.deliveryManager });
    }

    callServer({
      url: environment.authAPI + "profile",
      method: "PUT",
      skipRetry: true,
      data: {
        FirstName: firstName || "",
        LastName: lastName || "",
        PhoneNumber: phoneNumber || "",
        Email: email || "",
        Roles: roles,
        CurrentPassword: password,
        Configuration: {
          Language: language,
          VisualSettings: visualSettings
        }
      },
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function setUserAvatarBase64(userId, avatar) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/avatar/" + userId,
      method: "POST",
      headers: {
        "Content-Type": "image/png"
      },
      data: avatar,
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function resetPassword(pass, oldPass) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.authAPI + "profile/resetPassword",
      method: "POST",
      data: {
        Password: pass,
        CurrentPassword: oldPass
      }
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getReportDeliveryTime(skipHours) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/deliverytime/" + skipHours,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getReportDeliveryTimeByDate(startDate, endDate, controlCenterId, userId, controlCenterIds, territoryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/deliverytime",
      method: "POST",
      data: {
        StartDate: startDate,
        EndDate: endDate,
        ControlCenterId: controlCenterId,
        ControlCenterIds: controlCenterIds,
        UserId: userId,
        TerritoryId: territoryId,
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function updateUsersPagination({ num, count, query, roles, controlCenterId }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/page",
      method: "POST",
      data: {
        Page: num,
        Count: count,
        Query: query,
        Roles: roles,
        ControlCenterId: controlCenterId
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function updateCurrentShiftUser({ courierId, startTime, endTime, allowedDepots, shiftType, controlCenterId, vehicleId }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/shift/" + courierId,
      method: "PUT",
      data: {
        ShiftType: shiftType || 0,
        StartTime: startTime || '',
        vehicleId: vehicleId || null,
        EndTime: endTime || '',
        ControlCenterId: controlCenterId || null,
        AllowedDepots: allowedDepots || []
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function updateCurrentShiftDepotCouriers({ depotId, courierIds }) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/shift/assignment/" + depotId,
      method: "PUT",
      data: {
        CourierIds: courierIds || []
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getShiftsProvider(startDate, endDate, controlCenterId, provider, page, count, filters) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "query/providersPage",
      method: "POST",
      data: {
        StartDate: startDate,
        EndDate: endDate,
        ControlCenterId: controlCenterId,
        UserId: provider,
        Page: page,
        PageSize: count,
        ...filters
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function getShifts(startDate, endDate, controlCenterId, userId, controlCenterIds, territoryId,shiftType) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "history/shifts",
      method: "POST",
      data: {
        StartDate: startDate,
        EndDate: endDate,
        ControlCenterId: controlCenterId,
        ControlCenterIds: controlCenterIds,
        UserId: userId,
        TerritoryId: territoryId,
        ShiftType: shiftType
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function updateShift(shifts) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "history/updateShift",
      method: "POST",
      data: shifts,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function deleteShift(shiftId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "query/shift/" + shiftId,
      method: "DELETE",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getShift(shiftId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "query/shift/" + shiftId,
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function shiftPdf(shiftId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/shift/" + shiftId,
      method: "GET",
      responseType: "blob",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function shiftProviderPdf(dateStart, dateEnd, providerId, controlCenterId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/providerShift",
      method: "POST",
      responseType: "blob",
      data: {
        StartDate: dateStart,
        EndDate: dateEnd,
        ControlCenterId: controlCenterId,
        UserId: providerId,
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error.response);
        }
    );
  });
}

export function couriersCsv(start, end, depotId, controlCenterId, userId,shiftType) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/couriers",
      method: "POST",
      responseType: "blob",
      data: {
        StartDate: start,
        EndDate: end,
        DepotId: depotId,
        ControlCenterId: controlCenterId,
        UserId: userId,
        ShiftType: shiftType
      },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchExternalCycle() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchExternalOrderReorder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/reorder",
      method: "POST",
      data,
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchExternalProviders() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/providers",
      method: "GET",
      skipRetry: true,
      roles: [environment.USER_ROLES.supervisor, environment.USER_ROLES.deliveryManager, environment.USER_ROLES.controlCenterManager, environment.USER_ROLES.depot],
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchShiftProviders(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "history/providersSummary",
      method: "POST",
      data,
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchShiftProvidersOrders(orderIds) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "history/orders",
      method: "POST",
      data: {
        Ids: orderIds
      },
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchDeliveryProviders(depotId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "deliveryProvider/" + depotId,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function editDeliveryProvider(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "deliveryProvider/" + depotId,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createVirtualDeliveryProvider(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "deliveryProvider/virtual/" + depotId,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateVirtualDeliveryProvider(depotId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "deliveryProvider/virtual/" + depotId,
      method: "PUT",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteVirtualDeliveryProvider(depotId, providerId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "deliveryProvider/virtual/" + depotId + "/" + providerId,
      method: "DELETE"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function reorderDeliveryProviders(depotId, providers) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "deliveryProvider/" + depotId + "/reorder",
      method: "POST",
      data: providers
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function fetchExternalProvidersByIds(ids) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/providers",
      method: "POST",
      data: {
        OrderIds: ids
      }
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchExternalAssignmentsByIds(ids) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/assignments",
      method: "POST",
      data: {
        OrderIds: ids
      }
    }).then(response => resolve(response), error => reject(error.response));
  });
}


export function createExternalCycle(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/create",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addExternalCycle(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/add",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteMultiOrders(ids, token) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders/multiDelete",
      // headers: {
      //   Authorization: token,
      // },
      method: "POST",
      data: {
        ids,
      }
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function removeExternalCycle(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/remove",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function confirmExternalCycle(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/confirm/" + id,
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function cancelExternalCycle(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/cancel/" + id,
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getExternalCycleDriverPhone(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "externalCycle/phone/" + id,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function freshDeskRestoreId(userId, restoreId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/freshdeskrestoreid/" + userId,
      method: "POST",
      data: { RestoreId: restoreId }
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function freshDeskRestoreIdRemove(userId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "user/freshdeskrestoreid/" + userId,
      method: "DELETE"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

import SectionModel from '../../models/sectionModel';

import {
    DEPOT_BUSINESS_SKU, DEPOT_BUSINESS_SKU_EXPORT,
    DEPOT_BUSINESS_SKU_GET, DEPOT_BUSINESS_SKU_IMPORT,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    MODEL_CONTROL_TYPE,
    ORDERING_MENU_TYPE_SECTION_MODEL,
    TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import { createBreadcrumbModel } from '../createBreadcrumbModel';
import {createControlModel, filtrateControlByDepotRole} from '../controlModelFactory';
import { getHistoryLocationParams } from '../../utils/routesHelper';
import { getDepotNameById } from '../restaurant';
import FilterOptionsModel from '../../models/filterOptionsModel.js';
import i18n from '../../i18n.js';

export function createDepotBusinessSKUSectionModel({ ...args }) {
    return new SectionModel({
        filterOptions: createDepotBusinessSKUSectionFilter(),
        columns: [
            {
                columns: [
                    {
                        title: 'settings.sku_override.PROPERTY_NAME_LABEL',
                        accessor: 'name',
                        width: '50%',
                        fixedWidth: '50%',
                    },
                    {
                        title: 'settings.sku_override.NEW_SKU',
                        accessor: 'sKU',
                        width: '40%',
                        fixedWidth: '40%',
                        type: CONTROL_TYPE.inputText
                    }
                ],
                accessor: 'overrides',
                isEditable: true,
                type: CONTROL_TYPE.translationTable,
            },
        ],
        initNewItemModel: item => {
            return item;
        },
        actionName: DEPOT_BUSINESS_SKU,
        actionLoad: DEPOT_BUSINESS_SKU_GET,
        typeAction: args.typeAction,
        typeModel: args.typeModel || ORDERING_MENU_TYPE_SECTION_MODEL.sku,
        parentUrl: args.parentUrl || TYPE_SECTION_DEPOT_MODEL.depotsEdit,
        stayAfterSave: true,
        withSearch: true,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'settings.map.DEPOT_SETTINGS_LABEL',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                },
            }),
        ],
        label: 'settings.sku_override.LABEL',
        controls: filtrateControlByDepotRole([
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.iconControl,
                icon: 'export',
                hideTooltip: true,
                text: i18n.t('settings.sku_override.EXPORT_LABEL'),
                action: DEPOT_BUSINESS_SKU_EXPORT,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.importFile,
                icon: 'import',
                text: i18n.t('settings.sku_override.IMPORT_LABEL'),
                action: DEPOT_BUSINESS_SKU_IMPORT,
            }),
        ], [MODEL_CONTROL_TYPE.back, MODEL_CONTROL_TYPE.importFile]),
        mappingLambda: state => {
            return {
                values: state.restaurant.data?.skuOverrides
            }
        },
    });
}

function createDepotBusinessSKUSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    // filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(translations) {
    //     return translationsFilter(this.selected, translations);
    // });

    return filterOptionsModel;

}

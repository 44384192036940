import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { COURIER_APPROVE_ORDERS } from "../../../store/reducers/userModel/userModel-actions";
import { fetchUser } from "../../../api/user-requests";

import {
    getDepotNameByBusinessId,
    getExternalCycleDriverInfo,
    getExternalCycleLogo,
    getExternalCyclePriceInfo
} from "../../../services/restaurant";

import {
    ACCOUNT_ORDER_STATUS,
    CYCLE_STATE,
    DEFAULT_ORDERS_GROUPS,
    DELIVERY_STRATEGY,
    WAITER_TYPES,
    getCurrencyIcons,
    PENDING_ORDER_TYPE, getAccountOrderStatusTitle
} from "../../../utils/enums";
import {
    getStaticTime,
    isAfterDateByCurrent,
} from "../../../utils/convertTime";
import {defaultPromiseResolve, objectKeysToUpperLowerCase} from "../../../utils/objects-util";
import { createAction } from "../../../utils/sagaHelper";

import { Avatar } from "../Avatar/Avatar";
import IconButton from "../IconButton";
import TimeBox from "../TimeBox/TimeBox";
import TimeInfoETA from "../TimeInfoETA/TimeInfoETA";
import Waiter from "../Waiter/Waiter";
import { currentUserIsDeliveryManager, getUserByGroupId } from "../../../services/userModel";
import CourierInfoControl from "../CourierInfoControl/CourierInfoControl";
import { PENDING_ORDER_CANCEL_CYCLE } from "../../../store/reducers/order/order-actions";
import ExternalCycleDriverInfo from "../ExternalCycleDriverInfo";
import {getOrderSource} from "../../../utils/order.js";
import {deliveryIconNames} from "../../../utils/constants.js";

const AdminOrderItem = ({
    t,
    value,
    selected,
    filterStatus,
    withFirstNameClient,
    showOnlyImageStatus,
    showOnlyIdOrder,
    hidePrice,
    isMobile,
    isAdminOrder,
    onClick,
    withHeaderCourier,
    groupCourierMiddle,
    groupCourierEnd,
    hideTimeInCourier,
    users, externalCycles,
    approveOrders, cancelCycle
}) => {
    const [courier, setCourier] = useState(null);
    const [isOpenCourierInfo, setIsOpenCourierInfo] = useState(false);

    const handleOnApprove = () => {
        return approveOrders({ courierId: (value || {}).courier_id });
    }

    const handleOnCardControlClick = () => setIsOpenCourierInfo(!isOpenCourierInfo);

    const handleOnCancelCourier = () => {
        if(currentUserIsDeliveryManager()) {
            cancelCycle({ id: value.courier_id }).then(()=> {
                setIsOpenCourierInfo(false);
            }, error=> {
                toast.error(error || "Error");
            });
        }        
    }
    
    const timeValue = (value.groupId === DEFAULT_ORDERS_GROUPS.preOrders || isAdminOrder)
        ? value.delivery_strategy === DELIVERY_STRATEGY.asap ? value.creation_datetime : value.delivery_datatime
        : (value.creation_datetime || value.date);

    const getAddress = () => {
        if(!value || !value.address){
            return '';
        }

        if(value.address.city){
            return `${value.address.city}${value.address.street ? ', ' + value.address.street : ''}`; 
        } else {
            return `${value.address || ''}${value.city ? ', ' + value.city : ''}`;
        }
    }

    const isShowApproveCycle = () => {
        return currentUserIsDeliveryManager() &&
            ((courier && courier.isExternalGroup)
                ? courier.state === CYCLE_STATE.started
                : (courier && (!courier.cycle || (courier.cycle && courier.cycle.state === CYCLE_STATE.started))));
    }

    useEffect(() => {
        if(value && value.courier_id && withHeaderCourier) {
            const findCourier = externalCycles.find(f => f.id === value.courier_id) ||
                getUserByGroupId(users, value.courier_id);
            if(findCourier) {
                setCourier(findCourier);
            } else {
                if(!courier || courier.userId !== value.courier_id) {
                    fetchUser(value.courier_id).then(d => {
                        setCourier(objectKeysToUpperLowerCase(d.data));
                    });
                }
            }
        }
    }, [value, users, externalCycles, withHeaderCourier, courier])

    const getSourceTypeLabel = () => {
        return getOrderSource(value.order_source, value.order_source_id);
    }

    const deliveryLabel = {
        [PENDING_ORDER_TYPE.delivery]: t("dashboard.orders.DELIVERY_ORDERS_MODE_LABEL"),
        [PENDING_ORDER_TYPE.takeAway]: t("dashboard.orders.TAKEAWAY_ORDERS_MODE_LABEL"),
        [PENDING_ORDER_TYPE.dinein]: t("dashboard.orders.DINEIN_ORDERS_MODE_LABEL"),
    }

    return (
        <div className={classNames("adminOrderItem", { selected: selected, isMobile: isMobile, hasCourierHeader: withHeaderCourier, groupCourierMiddle, groupCourierEnd })}
            onClick={()=> onClick(value.id)}
        >
            {!!getSourceTypeLabel() && (
                <div className={"source_type"}>{getSourceTypeLabel()}</div>
            )}
            {withHeaderCourier && courier && (
                <div className={classNames("inline info courier")}>
                    {!isShowApproveCycle() && (
                        <TimeInfoETA abt date={!hideTimeInCourier && value.pickup_eta} 
                            title={t("admin-orders.TIME_ETA_DELIVERING")} nonRelative 
                            icon="dotsThreeCircle"
                            onClick={handleOnCardControlClick}
                        />
                    )}
                    {isShowApproveCycle() && (
                        <div className={classNames("courierControls")}>
                            <Waiter type={WAITER_TYPES.buttonSvg}
                                useClass="approveButton"
                                useSvg="#icon-arrow-right"
                                tooltipText={t("dashboard.orders.APPROVE_BUTTON")}
                                stopPropagation={true}
                                handleOnClick={handleOnApprove}
                            />
                            {courier.approveValidity && (
                                <div className={classNames("info")}><TimeBox date={courier.approveValidity} /></div>
                            )}
                        </div>
                    )}
                    <div className={classNames("courier-body")}>
                        <span>&nbsp;{getExternalCyclePriceInfo(courier)}</span>
                        <span className={classNames("courierName")}>
                            {courier.isExternalGroup ? getExternalCycleDriverInfo(courier, "name") : courier.firstName}
                        </span>
                        {courier.isExternalGroup
                            ? <Avatar avatar={getExternalCycleLogo(courier)} hideIconColor />
                            : <Avatar avatar={courier.avatarUrl} hideIconColor />}
                    </div>
                    {isOpenCourierInfo && (
                        <CourierInfoControl value={{ phone: courier.phoneNumber }}                            
                            component={courier && 
                                courier.isExternalGroup && 
                                (<ExternalCycleDriverInfo id={courier.id || courier.userId} isHideTimeValidCheck={true} />)
                            }
                            isShowCancel={currentUserIsDeliveryManager()}
                            onClose={()=> setIsOpenCourierInfo(false)} 
                            onCancel={handleOnCancelCourier}
                        />
                    )}
                </div>
            )}
            <div className={classNames("info")}>
                <span className={classNames("orderNumber", {"withoutPrice": hidePrice})}>
                    {!showOnlyIdOrder && (<span>{isMobile ? `for ${value.customer_name}` : (getDepotNameByBusinessId(value.business_id)) || t("admin-orders.ADMIN_ORDERS_ORDER_LABEL")}</span>)}
                    {withFirstNameClient && ( <span className={classNames("whitespace")}>{value.customer_name ? value.customer_name : ""}</span> )}
                    <span>&nbsp;#&nbsp;{value.sequence_id || value.orderId}</span>
                </span>
                {!hidePrice && (
                    <span className={classNames("price")}>
                        {value.price}&nbsp;{getCurrencyIcons(value.currency)}
                    </span>
                )}
                
            </div>
            <div className={'info inline'}>
                {[ACCOUNT_ORDER_STATUS.canceled, ACCOUNT_ORDER_STATUS.finished].includes(value.status) && (
                    <span className={classNames('orderStatus',{canceled: value.status === ACCOUNT_ORDER_STATUS.canceled})}>{getAccountOrderStatusTitle(value.status)}</span>
                )}
                {getAddress() && (<span className="address">{getAddress()}</span>)}
            </div>
            <div className={classNames("info", "inline")}>
                {timeValue && <div className={classNames("inlineFlex", "timeBlock")}>
                    <IconButton isIcon iconName={"time"}/>
                    <TimeBox shortLabel date={timeValue}/>

                    &nbsp;&nbsp;&nbsp;
                    {value.status === ACCOUNT_ORDER_STATUS.accepted && !isAfterDateByCurrent(value.readiness_time) && (
                        <>
                            <IconButton tooltipText={t("covertTime.THROUGH")} isIcon iconName={"readinessTime"}/>
                            <TimeBox shortLabel date={value.readiness_time} isRelativeFromCurrent={false} />
                        </>
                        )}
                    {(value.status === ACCOUNT_ORDER_STATUS.ready || value.status === ACCOUNT_ORDER_STATUS.delivering) && (
                        <>
                            <IconButton isIcon iconName={"tillReady"}/>
                            {isAfterDateByCurrent(value.dropoff_eta) && (<span className={classNames("info inline")}>{t("admin-orders.DROPOFF_ETA_ETA")}</span>)}
                            {!isAfterDateByCurrent(value.dropoff_eta) && (<TimeBox shortLabel date={value.dropoff_eta} isRelativeFromCurrent={false} />)}
                        </>
                    )}
                </div>}

                {filterStatus === ACCOUNT_ORDER_STATUS.pending && (
                    <span className="timeFromDileverFromAdminPage">
                        <TimeBox shortLabel date={value.creation_datetime} title={t("admin-orders.CREATION_DATE_TIME_AGO_LABEL")} />
                    </span>
                )}
                {isAdminOrder && value.delivery_strategy === DELIVERY_STRATEGY.concreteTime && (
                    <span className={'scheduledOn'}>{t("admin-orders.SCHEDULED_ON")}: {getStaticTime(value.delivery_datetime)}</span>
                )}
                <span className={classNames("status", {fixedWidth: isAdminOrder && value.delivery_strategy === DELIVERY_STRATEGY.concreteTime})}>
                    {isAdminOrder && value.delivery_strategy === DELIVERY_STRATEGY.concreteTime && (
                        <IconButton isIcon={true} iconName={"preorders"} onClick={defaultPromiseResolve} />
                    )}              
                    <IconButton isIcon={true} 
                        iconName={deliveryIconNames[(value.delivery_type || (value.type + 1))]}
                        onClick={defaultPromiseResolve}
                    />
                    {!showOnlyImageStatus && (
                        <span>
                            {deliveryLabel[(value.delivery_type || (value.type + 1))]}
                        </span>  
                    )}
                           
                </span>
            </div>
            <div className={classNames("pendingOrderItemBorderBottom")}></div>        
        </div>
    );
}

const mapStateToProps = state => {
    return {
        users: state.userModel.data || [],
        externalCycles: state.externalCycles.data || []
    };
};

const mapDispatchToProps = dispatch => {
    return {
      approveOrders: data => dispatch(createAction(COURIER_APPROVE_ORDERS, data)),
      cancelCycle: data => dispatch(createAction(PENDING_ORDER_CANCEL_CYCLE, data))      
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminOrderItem));

import React from 'react';
import i18n from '../../i18n';

import {getCampaignFilter} from '../filter';
import {createControlModel} from '../controlModelFactory';

import SectionModel from '../../models/sectionModel';
import FilterOptionsModel from '../../models/filterOptionsModel';

import {
  CONTROL_TYPE,
  DATECONTROL_TYPE,
  getCampaignRecurringTypes,
  getCampaignTypes,
  MARKETPLACE_SETTINGS_MODEL,
  MODAL_COMPONENT_TYPE,
  MODEL_CONTROL_TYPE,
  MODEL_TYPE,
  TYPE_ACTION,
} from '../../utils/enums';

import {
  getCampaignNameById,
  getCampaignsExecutionStatusLabel,
  getCampaignType,
  getCurrencyValue, getRestaurantLoyaltyEnabled,
  isMobileWidth,
} from '../restaurant';
import {createBreadcrumbModel} from '../createBreadcrumbModel';
import {getInitModel} from '../initModels.js';
import {
  EXECUTE_COMPAIGN,
  LOYALTY_CAMPAIGNS,
  LOYALTY_CAMPAIGNS_EXECUTIONS_ADD,
  LOYALTY_CAMPAIGNS_EXECUTIONS_GET,
  LOYALTY_CAMPAIGNS_EXECUTIONS_UPDATE_BY_ID,
  LOYALTY_CAMPAIGNS_GET,
  LOYALTY_CAMPAIGNS_TOGGLE_ENABLED,
} from '../../store/reducers/restaurant/restaurant-actions.js';
import {DATE_FORMAT_DMY2_HM, getFormattedDate} from '../../utils/convertTime.js';
import {getHistoryLocationParams} from '../../utils/routesHelper.js';
import {checkNullField} from '../../app/components/SectionItemAudienceFilter/audienceFilterHelpers.js';
import RefreshDataIcon from '../../app/components/RefreshDataIcon/index.js';
import LinkActionIcon from "../../app/components/LinkActionIcon/index.js";
import {beautifyPrice} from "../../utils/math-util.js";
import {isRTL} from "../userModel.js";

export function createCampaignsSectionModel() {
  return new SectionModel({
    filterOptions: createCampaignsSectionFilter(),
    columns: [
      {
        title: 'settings.campaigns.CAMPAIGN_NAME_LABEL',
        accessor: 'name',
        width: '300px',
      },
      {
        title: 'settings.campaigns.TYPE_LABEL',
        accessor: 'campaign_type',
        component: campaign => getCampaignType(campaign.campaign_type),
      },
      {
        title: 'settings.campaigns.BONUS_LABEL',
        accessor: 'bonus_info',
        component: campaign => <div>{campaign?.bonus_info?.bonus || '-'}</div>,
      },
      {
        title: 'settings.campaigns.START_DATE',
        accessor: 'start_date',
        component: campaign => getFormattedDate(campaign.start_date, DATE_FORMAT_DMY2_HM),
      },
      {
        title: 'settings.campaigns.SEE_EXECUTIONS',
        accessor: 'execution',
        url: item => MARKETPLACE_SETTINGS_MODEL.campaignsExecutions + '?id=' + item.id,
        type: CONTROL_TYPE.linkControl,
      },
      {
        title: 'basic.ENABLED_STATUS',
        accessor: 'enabled',
        isEditable: true,
        type: CONTROL_TYPE.switch,
      },
      {
        title: "settings.campaigns.EXECUTE",
        width: "100px",
        component: function(data) {
          return (
              <LinkActionIcon
                  iconName={"start"}
                  tooltip={i18n.t("settings.campaigns.EXECUTE_TOOLTIP")}
                  withConfirm
                  actionName={EXECUTE_COMPAIGN}
                  data={data}
              />
          );
        },
        link: EXECUTE_COMPAIGN,
        type: CONTROL_TYPE.linkAction
      },
      {
        title: 'settings.users.USER_TOOLS_EDIT',
        iconName: 'editItem',
        width: "65px",
        type: CONTROL_TYPE.link,
        link: MARKETPLACE_SETTINGS_MODEL.campaignsEdit,
        tooltipText: i18n.t('settings.users.USER_TOOLS_EDIT_TOOLTIP'),
      },
    ],
    breadcrumbs: [
      createBreadcrumbModel({ label: 'header.menu.MARKETPLACE_SETTINGS' }),
      createBreadcrumbModel({ label: 'settings.marketplaceMessage.LOYALTY' }),
    ],
    label: 'settings.campaigns.MENU_LABEL',
    tableMinWidth: 1400,
    actionLoad: LOYALTY_CAMPAIGNS_GET,
    actionName: LOYALTY_CAMPAIGNS,
    editAction: LOYALTY_CAMPAIGNS_TOGGLE_ENABLED,
    isForceUpdate: true,
    typeModel: MARKETPLACE_SETTINGS_MODEL.campaigns,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: MARKETPLACE_SETTINGS_MODEL.campaignsNew,
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
      // createControlModel({
      //   type: MODEL_CONTROL_TYPE.filter,
      // }),
    ],
    mappingLambda: state => {
      return state.restaurant?.data?.campaigns || [];
    },
  });
}

export function createCampaignsEditSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        id: 'groupCampaignsEditNameType',
        columns: [
          {
            title: 'settings.campaigns.CAMPAIGN_EDIT_NAME_LABEL',
            accessor: 'name',
            isEditable: true,
            withoutBorder: true,
            isItemValid: item => {
              return !!item?.name?.length;
            },
            type: CONTROL_TYPE.text,
          },
          {
            title: 'settings.campaigns.CAMPAIGN_EDIT_TYPE_LABEL',
            accessor: 'campaign_type',
            connect: () => ({
              values: getCampaignTypes(),
            }),
            isEditable: true,
            withoutBorder: true,
            type: CONTROL_TYPE.select,
          },
          {
            title: 'settings.campaigns.CAMPAIGN_ACTIVE_LABEL',
            accessor: 'enabled',
            isInline: true,
            isEditable: true,
            type: CONTROL_TYPE.switch,
          },
        ],
        isEditable: true,
        border: 'none',
        type: CONTROL_TYPE.group,
      },
      {
        id: 'groupCampaignsEditMassageSettings',
        columns: [
          {
            title: 'settings.campaigns.CAMPAIGN_EDIT_PROMO_TITLE_LABEL',
            accessor: 'message_title',
            isEditable: true,
            withoutBorder: true,
            type: CONTROL_TYPE.text,
          },
          {
            title: 'settings.campaigns.CAMPAIGN_EDIT_PROMO_MESSAGE_LABEL',
            accessor: 'message',
            isEditable: true,
            height: '154px',
            type: CONTROL_TYPE.textArea,
          },
        ],
        isEditable: true,
        border: 'none',
        justifyContent: 'flex-start',
        type: CONTROL_TYPE.group,
      },
      {
        accessor: 'audience_filter',
        isEditable: true,
        isFillContent: true,
        isItemValid: item => {
          const isFilterExist = !checkNullField(item?.audience_filter || []) && item?.audience_filter;
          const isChannelExist = item?.audience_channel !== null
          return isFilterExist || isChannelExist
        },
        type: CONTROL_TYPE.audienceFilter,
      },
      {
        accessor: 'bonus_info',
        isEditable: true,
        isFillContent: true,
        isItemValid: item => {
          if(item?.audience_channel !== null || item?.bonus_info === null) return true

          const percent = item?.bonus_info?.checkout_limit_percent;
          const isNameExist = item?.bonus_info?.bonus_name;
          const isValidPercent = percent >= 0.01 && percent <= 1.0;
          const isValidValidityPeriod = item?.bonus_info?.bonus_expiration !== '00:00:00';
          return isValidPercent && isValidValidityPeriod && isNameExist;
        },
        disable: !getRestaurantLoyaltyEnabled(),
        type: CONTROL_TYPE.bonusVoucher,
      },
      {
        title: 'settings.campaigns.CAMPAIGN_TEST_USERS_LABEL',
        isEditable: true,
        isFillContent: true,
        key: 'consumerId',
        buttonsConfig: {
          saveTitle: 'settings.campaigns.CAMPAIGN_TEST_USERS_SAVE_LABEL',
          openPopupTitle: 'settings.campaigns.CAMPAIGN_TEST_USERS_OPEN_MODAL_WINDOW_LABEL',
          defaultTitle: 'settings.campaigns.CAMPAIGN_TEST_USERS_OPEN_MODAL_WINDOW_DEFAULT_LABEL',
          saveAction: LOYALTY_CAMPAIGNS_EXECUTIONS_ADD,
        },
        isShow: item => !!item?.id,
        modalType: MODAL_COMPONENT_TYPE.customersList,
        type: CONTROL_TYPE.modalSelect,
      },
      {
        id: 'groupCampaignsEditDate',
        columns: [
          {
            title: 'settings.campaigns.CAMPAIGN_REPEAT_LABEL',
            accessor: 'recurring_type',
            inlineWidth: '120px',
            alignItems: 'flex-end',
            isNumberValue: true,
            isEditable: true,
            type: CONTROL_TYPE.switch,
          },
          {
            accessor: 'recurring_type',
            width: '150px',
            fixedWidth: '150px',
            connect: () => ({
              values: getCampaignRecurringTypes(),
            }),
            isEditable: true,
            type: CONTROL_TYPE.select,
          },
          {
            title: 'settings.campaigns.CAMPAIGN_START_DATE_LABEL',
            accessor: 'start_date',
            width: '300px',
            fixedWidth: '300px',
            isEditable: true,
            timeType: DATECONTROL_TYPE.datetime,
            type: CONTROL_TYPE.date,
          },
        ],
        isEditable: true,
        isInline: true,
        isFillContent: true,
        alignItems: 'flex-end',
        type: CONTROL_TYPE.group,
        gap: '7px',
      },
    ],
    actionName: LOYALTY_CAMPAIGNS,
    actionLoad: LOYALTY_CAMPAIGNS_GET,
    stayAfterSave: args.typeAction === TYPE_ACTION.edit,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.campaigns,
    typeAction: args.typeAction,
    typeModel:
      args.typeAction === TYPE_ACTION.edit
        ? MARKETPLACE_SETTINGS_MODEL.campaignsEdit
        : MARKETPLACE_SETTINGS_MODEL.campaignsNew,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
    ],
    initNewItemModel: item => getInitModel(MODEL_TYPE.marketplaceCampaignModel, item),
    breadcrumbs: [
      createBreadcrumbModel({ label: 'header.menu.MARKETPLACE_SETTINGS' }),
      createBreadcrumbModel({ label: 'settings.marketplaceMessage.LOYALTY' }),
      createBreadcrumbModel({ label: 'settings.campaigns.MENU_LABEL' }),
    ],
    getLabel: () => {
      const { id } = getHistoryLocationParams(window.location.search);
      return id ? getCampaignNameById(id) : i18n.t('settings.campaigns.MENU_LABEL_NEW_CAMPAIGN');
    },
    mappingLambda: state => {
      return state.restaurant?.data?.campaigns || [];
    },
  });
}

export function createCampaignsExecutionsSectionModel() {
  return new SectionModel({
    filterOptions: createCampaignsExecutionsSectionFilter(),
    columns: getCampaignsExecutionsColumns(),
    breadcrumbs: [
      createBreadcrumbModel({ label: 'header.menu.MARKETPLACE_SETTINGS' }),
      createBreadcrumbModel({ label: 'settings.marketplaceMessage.LOYALTY' }),
      createBreadcrumbModel({ label: 'settings.campaigns.MENU_LABEL' }),
      createBreadcrumbModel({
        getLabel: () => {
          const { id } = getHistoryLocationParams(window.location.search);
          return id ? getCampaignNameById(id) : i18n.t('settings.campaigns.MENU_LABEL_NEW_CAMPAIGN');
        },
      }),
    ],
    label: 'settings.campaigns.EXECUTIONS',
    tableMinWidth: 1400,
    isForceUpdate: true,
    actionLoad: LOYALTY_CAMPAIGNS_EXECUTIONS_GET,
    hideHeaderItemControls: true,
    parentUrl: MARKETPLACE_SETTINGS_MODEL.campaigns,
    typeModel: MARKETPLACE_SETTINGS_MODEL.campaignsExecutions,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
      }),
    ],
    mappingLambda: state => {
      const { id } = getHistoryLocationParams(window.location.search);
      const campaign = state.restaurant?.data?.campaigns?.find(item => item.id === id);
      return campaign?.executions || [];
    },
  });
}

export const getCampaignsExecutionsColumns = () => [
  {
    title: 'settings.campaigns.EXECUTION_START_LABEL',
    accessor: 'date',
    width: '160px',
    fixedWidth: '160px',
    component: campaign => (
        <div className={'dateRtl'}>{getFormattedDate(campaign.date, DATE_FORMAT_DMY2_HM)}</div>
    ),
  },
  {
    title: 'settings.campaigns.EXECUTION_END_LABEL',
    accessor: 'end_date',
    width: '160px',
    fixedWidth: '160px',
    component: campaign => (
        <div className={'dateRtl'}>{getFormattedDate(campaign?.end_date, DATE_FORMAT_DMY2_HM)}</div>
    ),
  },
  {
    title: 'settings.campaigns.STATUS_LABEL',
    accessor: 'start_date',
    width: '100px',
    fixedWidth: '100px',
    component: campaign => <div>{getCampaignsExecutionStatusLabel(campaign.state)}</div>,
  },
  {
    title: 'settings.campaigns.ELIGIBLE_AUDIENCE',
    accessor: 'eligible_audience',
    width: '100px',
    fixedWidth: '100px',
    component: item => (item?.eligible_audience ? <div>{item.eligible_audience}</div> : '-'),
  },
  {
    title: 'settings.campaigns.RECEIVED_MESSAGE_LABEL',
    accessor: 'sent_push',
    width: '100px',
    fixedWidth: '100px',
  },
  {
    title: 'settings.campaigns.TOTAL_APP_OPENS_LABEL',
    accessor: 'total_app_opens',
    width: '100px',
    fixedWidth: '100px',
    component: item => {
      const percents =
        item?.total_app_opens && item?.sent_push ? ((item?.total_app_opens / item?.sent_push) * 100).toFixed(0) : null;
      const totalOpens = item?.total_app_opens ? ` (${item.total_app_opens}) ` : null;
      const withPercent = percents && totalOpens ? <div>{percents + '%' + totalOpens}</div> : '';
      return withPercent ? withPercent : totalOpens ? <div>{item?.total_app_opens}</div> : '-';
    },
  },
  {
    title: 'settings.campaigns.TOTAL_ORDERS_LABEL',
    accessor: 'start_date',
    width: '100px',
    fixedWidth: '100px',
    component: item => {
      const percents =
        item?.total_orders && item?.sent_push
          ? ((item?.total_orders / item?.sent_push) * 100).toFixed(0)
          : null;
      const totalOrders = item?.total_orders ? ` (${item?.total_orders}) ` : null;
      const withPercent = percents && totalOrders ? <div>{percents + '%' + totalOrders}</div> : '';
      return withPercent ? withPercent : totalOrders ? <div>{item?.total_orders}</div> : '-';
    },
  },
  {
    title: 'settings.campaigns.TOTAL_CAMPAIGN_REVENUE',
    accessor: 'total_price',
    width: '100px',
    fixedWidth: '100px',
    component: item => (item?.total_price ? <div>{!isRTL() && getCurrencyValue()} {beautifyPrice(item.total_price)} {isRTL() && getCurrencyValue()}</div> : '-'),
  },
  {
    title: 'settings.campaigns.TOTAL_CAMPAIGN_COST',
    accessor: 'total_discount',
    width: '100px',
    fixedWidth: '100px',
    component: item => (item?.total_discount ? <div>{!isRTL() && getCurrencyValue()} {beautifyPrice(item.total_discount)} {isRTL() && getCurrencyValue()}</div> : '-'),
  },
  {
    title: 'settings.campaigns.TOTAL_CAMPAIGN_PROFIT',
    accessor: 'total_price',
    width: '100px',
    fixedWidth: '100px',
    component: item => (<div>{!isRTL() && getCurrencyValue()} {beautifyPrice((item.total_price || 0) - (item.total_discount || 0))} {isRTL() && getCurrencyValue()}</div>),
  },
  {
    title: 'settings.campaigns.AVERAGE_REVENUE_PER_USER',
    accessor: 'start_date',
    width: '100px',
    fixedWidth: '100px',
    component: item => {
      const result = (item?.total_price / item?.total_orders).toFixed(0);
      return item?.total_price && item?.total_orders ? <div>{!isRTL() && getCurrencyValue()} {beautifyPrice(result)} {isRTL() && getCurrencyValue()}</div> : '-';
    },
  },
  {
    title: '',
    width: '40px',
    component: item => {
      return <RefreshDataIcon item={item} refreshAction={LOYALTY_CAMPAIGNS_EXECUTIONS_UPDATE_BY_ID} />;
    },
  },
];

function createCampaignsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(campaign) {
    return getCampaignFilter(this.selected, campaign);
  });

  return filterOptionsModel;
}

function createCampaignsExecutionsSectionFilter() {
  return new FilterOptionsModel();
}

import {
  SECTION_MODEL_UPDATE,
  SECTION_MODEL_INIT,
  SECTION_MODEL_UPDATE_MODEL, SECTION_MODEL_SCROLL_UPDATE
} from "./routing-actions";
import {
  CUSTOMER_SERVICE_MODEL,
  MARKETPLACE_SETTINGS_MODEL,
  ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
  ORDERING_MENU_TYPE_SECTION_MODEL,
  ROLES_MANAGER_TYPE, SHIFTS_MODEL,
  TYPE_SECTION_DEPOT_MODEL,
  TYPE_SECTION_MENUTEMPLATE_MODEL, TYPE_SECTION_MESSAGE_MODEL,
  TYPE_SECTION_MODEL
} from "../../../utils/enums";
import { getSeparatedValue } from "../../../utils/objects-util";
import { REMOVE_ALL_DATA } from "../common-actions";
import RoleManager from "../../../services/roleManage";

const settingsRouteLocations = ["/orders", "/menu"]
  .concat(Object.keys(TYPE_SECTION_MODEL).map(key => TYPE_SECTION_MODEL[key]))
  .concat(
    Object.keys(ORDERING_MENU_TYPE_SECTION_MODEL).map(
      key => ORDERING_MENU_TYPE_SECTION_MODEL[key]
    )
  )
  .concat(
    Object.keys(TYPE_SECTION_DEPOT_MODEL).map(
      key => TYPE_SECTION_DEPOT_MODEL[key]
    )
  )
  .concat(
    Object.keys(MARKETPLACE_SETTINGS_MODEL).map(
      key => MARKETPLACE_SETTINGS_MODEL[key]
    )
  )
  .concat(
    Object.keys(ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL).map(
      key => ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL[key]
    )
  )
  .concat(
    Object.keys(TYPE_SECTION_MENUTEMPLATE_MODEL).map(
      key => TYPE_SECTION_MENUTEMPLATE_MODEL[key]
    )
  ).concat(
      Object.keys(TYPE_SECTION_MESSAGE_MODEL).map(
          key => TYPE_SECTION_MESSAGE_MODEL[key]
      )
    ).concat(
      Object.keys(CUSTOMER_SERVICE_MODEL).map(
          key => CUSTOMER_SERVICE_MODEL[key]
      )
    ).concat(
      Object.keys(SHIFTS_MODEL).map(
          key => SHIFTS_MODEL[key]
      )
    );

const getInitModelType = () => {
  if (
    !RoleManager.instance.isAuthorized(
      ROLES_MANAGER_TYPE.menu,
      TYPE_SECTION_MODEL.users
    )
  ) {
    const firstAuthorizedSettingsLink = Object.keys(TYPE_SECTION_MODEL)
      .filter(
        key =>
          key !== "users" && TYPE_SECTION_MODEL[key].split("/").length === 3
      )
      .find(key =>
        RoleManager.instance.isAuthorized(
          ROLES_MANAGER_TYPE.menu,
          TYPE_SECTION_MODEL[key]
        )
      );
    const firstAuthorizedMarketplaceSettingsLink = Object.keys(
      MARKETPLACE_SETTINGS_MODEL
    )
      .filter(
        key =>
          key !== "businessCategories" &&
          MARKETPLACE_SETTINGS_MODEL[key].split("/").length === 3
      )
      .find(key =>
        RoleManager.instance.isAuthorized(
          ROLES_MANAGER_TYPE.menu,
          MARKETPLACE_SETTINGS_MODEL[key]
        )
      );
    return (
      (firstAuthorizedSettingsLink &&
        TYPE_SECTION_MODEL[firstAuthorizedSettingsLink]) ||
      (firstAuthorizedMarketplaceSettingsLink &&
        MARKETPLACE_SETTINGS_MODEL[firstAuthorizedMarketplaceSettingsLink]) ||
      "/"
    );
  } else {
    return Object.keys(ORDERING_MENU_TYPE_SECTION_MODEL).includes(
      window.location.pathname
    )
      ? ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit
      : Object.keys(TYPE_SECTION_MODEL).includes(window.location.pathname)
        ? TYPE_SECTION_MODEL.users
        : Object.keys(ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL).includes(
            window.location.pathname
          )
          ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit
          : Object.keys(MARKETPLACE_SETTINGS_MODEL).includes(
              window.location.pathname
            )
            ? MARKETPLACE_SETTINGS_MODEL.menuTemplate
            : Object.keys(TYPE_SECTION_MESSAGE_MODEL).includes(
                window.location.pathname
              )
              ? TYPE_SECTION_MESSAGE_MODEL.messageInfo
              : Object.keys(CUSTOMER_SERVICE_MODEL).includes(window.location.pathname)
                  ? CUSTOMER_SERVICE_MODEL.order
                  : CUSTOMER_SERVICE_MODEL.order
  }
};

const getInitPrevModelType = () => {
  if (
    !RoleManager.instance.isAuthorized(
      ROLES_MANAGER_TYPE.menu,
      TYPE_SECTION_MODEL.users,
      MARKETPLACE_SETTINGS_MODEL.menuTemplate
    )
  ) {
    const firstAuthorizedSettingsLink = Object.keys(TYPE_SECTION_MODEL)
      .filter(
        key =>
          key !== "users" && TYPE_SECTION_MODEL[key].split("/").length === 3
      )
      .find(key =>
        RoleManager.instance.isAuthorized(
          ROLES_MANAGER_TYPE.menu,
          TYPE_SECTION_MODEL[key]
        )
      );

    const firstAuthorizedMarketplaceSettingsLink = Object.keys(
      MARKETPLACE_SETTINGS_MODEL
    )
      .filter(
        key =>
          key !== "businessCategories" &&
          MARKETPLACE_SETTINGS_MODEL[key].split("/").length === 3
      )
      .find(key =>
        RoleManager.instance.isAuthorized(
          ROLES_MANAGER_TYPE.menu,
          MARKETPLACE_SETTINGS_MODEL[key]
        )
      );
    return {
      TYPE_SECTION_MODEL:
        (firstAuthorizedSettingsLink &&
          TYPE_SECTION_MODEL[firstAuthorizedSettingsLink]) ||
        "/",
      MARKETPLACE_SETTINGS_MODEL:
        (firstAuthorizedMarketplaceSettingsLink &&
          MARKETPLACE_SETTINGS_MODEL[firstAuthorizedMarketplaceSettingsLink]) ||
        "/",
      ORDERING_MENU_TYPE_SECTION_MODEL:
        ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
      ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL:
        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
      TYPE_SECTION_DEPOT_MODEL: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
      TYPE_SECTION_MENUTEMPLATE_MODEL:
        TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
      TYPE_SECTION_MESSAGE_MODEL: TYPE_SECTION_MESSAGE_MODEL.messageInfo,
      CUSTOMER_SERVICE_MODEL: CUSTOMER_SERVICE_MODEL.order,
    };
  } else {
    return {
      TYPE_SECTION_MODEL: TYPE_SECTION_MODEL.users,
      MARKETPLACE_SETTINGS_MODEL: MARKETPLACE_SETTINGS_MODEL.marketplaceSettings,
      ORDERING_MENU_TYPE_SECTION_MODEL:
        ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
      ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL:
        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
      TYPE_SECTION_DEPOT_MODEL: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
      TYPE_SECTION_MENUTEMPLATE_MODEL:
        TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
      TYPE_SECTION_MESSAGE_MODEL: TYPE_SECTION_MESSAGE_MODEL.messageInfo,
      CUSTOMER_SERVICE_MODEL: CUSTOMER_SERVICE_MODEL.order,
    };
  }
};

const initState = {
  prevModelType: getInitPrevModelType(),
  currentModelType: getInitModelType(),
  layoutModelType: null,
  sectionModels: settingsRouteLocations.reduce((obj, path) => {
    return {
      ...obj,
      [path]: {
        id: "",
        model: null
      }
    };
  }, {}),
  groupSections: settingsRouteLocations.reduce((obj, path) => {
    const groupKey = getSeparatedValue(path, "/", 3);
    return {
      ...obj,
      [groupKey]: ""
    };
  }, {})
};

export default function routing(state = initState, action) {
  switch (action.type) {
    case SECTION_MODEL_INIT:
    case REMOVE_ALL_DATA:
      return {
        prevModelType: getInitPrevModelType(),
        currentModelType: getInitModelType(),
        layoutModelType: null,
        sectionModels: settingsRouteLocations.reduce((obj, path) => {
          return {
            ...obj,
            [path]: {
              id: "",
              model: null
            }
          };
        }, {}),
        groupSections: settingsRouteLocations.reduce((obj, path) => {
          const groupKey = getSeparatedValue(path, "/", 3);
          return {
            ...obj,
            [groupKey]: ""
          };
        }, {})
      };
    case SECTION_MODEL_UPDATE:
      return {
        ...state,
        ...action.data
      };
    case SECTION_MODEL_UPDATE_MODEL:
      return {
        ...state,
        sectionModels: {
          ...state.sectionModels,
          [action.data.typeModel]: {
            ...state.sectionModels[action.data.typeModel],
            model: {
              ...state.sectionModels[action.data.typeModel].model,
              ...action.data.data
            }
          }
        }
      };
    case SECTION_MODEL_SCROLL_UPDATE:
      return {
        ...state,
        scrolls: {
          ...state.scrolls,
          [action.data.typeModel]: action.data.scroll,
        }
      }
    default:
      return state;
  }
}

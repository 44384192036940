import {
  ADD_DEPOT_USER,
  CLOUD_PRINTERS_SETTING_DELETE,
  CLOUD_PRINTERS_SETTING_LOAD,
  CLOUD_PRINTERS_SETTING_LOAD_DETAILS,
  CLOUD_PRINTERS_SETTING_UPDATE,
  CURRENT_CONTROL_CENTER_UPDATE,
  DEPOT_DELETE,
  DEPOT_EDIT,
  DEPOT_NEW,
  FLEET_SETTINGS_FLEET_VEHICLE_LOAD,
  FLEET_SETTINGS_LOAD,
  FLEET_SETTINGS_TELEMETRY_LOAD,
  LOAD_RESTAURANT_FAIL,
  LOAD_RESTAURANT_SUCCESS,
  PROVIDERS_SETTING_DETAILS_UNMOUNT,
  PROVIDERS_SETTING_LOAD,
  PROVIDERS_SETTING_LOAD_DETAILS,
  PROVIDERS_SETTING_UPDATE,
  PUSHER_RESTAURANT_STATE_UPDATE,
  REMOVE_BUSINESS_MENU,
  RESTAURANT_DELIVERY_ZONE_UPDATE,
  RESTAURANT_UPDATE,
  RESTAURANT_UPDATE_AVAILABLE_CONTROL_CENTERS,
  RESTAURANT_UPDATE_CONTROL_CENTERS,
  SAVE_DEPOT_AVATAR,
  SAVE_DEPOT_USERS,
  SAVE_DEPOTS_AVATARS,
  SET_BUSINESS_MENU,
  SET_BUSINESS_PARENT_MENU,
  SET_DEPOT_PROVIDERS,
  SET_ORDERS_PROVIDER,
  SET_SHIFT_PROVIDERS,
  SHIFTS_SAVE,
  UPDATE_DEPOT_USER, UPDATE_SHIFTS, SHIFTS_TRIP_HISTORY_ADD, SHIFTS_HISTORY_SAVE,
} from './restaurant-actions';

import {STATUSES} from '../../../utils/enums';
import {arrayToMap, mergeArrays, objectKeysToUpperLowerCase} from '../../../utils/objects-util';

import {REMOVE_ALL_DATA} from '../common-actions';

import * as Sentry from '@sentry/react';

const initState = {
  data: {},
  status: STATUSES.notInitialized
};

export default function restaurant(state = initState, action) {
  let newData;
  switch (action.type) {
    case REMOVE_ALL_DATA:
      newData = initState;
      break;
    case LOAD_RESTAURANT_SUCCESS:
      newData = {
        ...state,
        data: {
          ...action.data,
          depotsMap: arrayToMap(action.data.depots)
        },
        status: STATUSES.success
      };
      break;
    case LOAD_RESTAURANT_FAIL:
      newData = {
        ...state,
        status: STATUSES.failed
      }
      break;
    case SAVE_DEPOT_AVATAR:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.map(depot => depot.id === data.depotId
            ? { ...depot, avatar: data.avatar }
            : depot
          )
        }
      };
      break;
    case SAVE_DEPOTS_AVATARS:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.map(depot => {
            const item = action.data.find(f => f.id === depot.id);
            return {
              ...depot,
              avatar: item && item.avatar ? item.avatar : depot.avatar
            };
          })
        }
      };
      break;
    case PUSHER_RESTAURANT_STATE_UPDATE:
      const data = objectKeysToUpperLowerCase(action.data);

      newData = {
        ...state,
        data: { ...state.data, ...data }
      };
      break;
    case RESTAURANT_UPDATE_CONTROL_CENTERS:
      newData = {
        ...state,
        data: {
          ...state.data,
          controlCenters: action.data
        }
      };

      break;
      case RESTAURANT_UPDATE_AVAILABLE_CONTROL_CENTERS:
      newData = {
        ...state,
        data: {
          ...state.data,
          availableControlCenters: action.data
        }
      };

      break;
    case RESTAURANT_UPDATE:
      newData = {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          configuration: (action.data || {}).configuration || (state.data || {}).configuration
        }
      };

      break;
    case RESTAURANT_DELIVERY_ZONE_UPDATE:
      newData = {
        ...state,
        data: {
          ...state.data,
          deliveryZones: mergeArrays(state.data.deliveryZones || [], [action.data])
        }
      };

      break;
    case DEPOT_NEW:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.concat([action.data])
        }
      };

      break;
    case DEPOT_EDIT:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.map(depot => depot.id === action.data.id ? { ...depot, ...action.data } : depot)
        }
      };

      break;
    case DEPOT_DELETE:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.filter(f => f.id !== action.data.id)
        }
      };

      break;
    case SAVE_DEPOT_USERS:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.map(
              depot =>
                  depot.id === action.data.id
                      ? { ...depot, depotUsers: action.data.data }
                      : depot
          )
        }
      };
    break;
    case PROVIDERS_SETTING_LOAD:
      newData = {
        ...state,
        data: {
          ...state.data,
          providers: action.data ? action.data : [],
        }
      };

      break;
    case PROVIDERS_SETTING_LOAD_DETAILS:
      newData = {
        ...state,
        data: {
          ...state.data,
          providersDetails: action.data ? {...action.data} : null,
        }
      };
      break;
    case CLOUD_PRINTERS_SETTING_LOAD:
      newData = {
        ...state,
        data: {
          ...state.data,
          cloudPrinters: action.data ? action.data : [],
        }
      };

      break;
    case CLOUD_PRINTERS_SETTING_LOAD_DETAILS:
      newData = {
        ...state,
        data: {
          ...state.data,
          cloudPrintersDetails: action.data ? {...action.data} : null,
        }
      };

      break;
    case CLOUD_PRINTERS_SETTING_UPDATE:
      const printersUpdate = state?.data?.cloudPrinters.map(printer => printer.id === action?.data?.id
        ? action.data
        : printer);

      newData = {
        ...state,
        data: {
          ...state.data,
          cloudPrinters: printersUpdate

        }
      }; break;
    case PROVIDERS_SETTING_UPDATE:
      let result = state?.data?.providers.map(provider => provider.id === action?.data?.id
          ? action.data
          : provider);

      newData = {
        ...state,
        data: {
          ...state.data,
          providers: result

        }
      }; break;
    case CLOUD_PRINTERS_SETTING_DELETE:
      const printersNew = state?.data?.cloudPrinters.filter(printer => printer.id !== action?.data?.id);

      newData = {
        ...state,
        data: {
          ...state.data,
          cloudPrinters: printersNew

        }
      }; break;
    case PROVIDERS_SETTING_DETAILS_UNMOUNT:
      newData = {
        ...state,
        data: {
          ...state.data,
          providersDetails: null

        }
      };

      break;
    case SHIFTS_SAVE:
      newData = {
        ...state,
        data: {
          ...state.data,
          shifts: action.data
        }
      };

      break;
    case UPDATE_SHIFTS:
      newData = {
        ...state,
        data: {
          ...state.data,
          shifts: (state.data.shifts || []).map(shift => shift.shiftId === action.data.shiftId ? {...shift, ...action.data} : shift)
        }
      };

      break;
    case SHIFTS_HISTORY_SAVE:
      newData = {
        ...state,
        data: {
          ...state.data,
          shiftsHistory: action.data
        }
      };

      break;
    case SHIFTS_TRIP_HISTORY_ADD:
      newData = {
        ...state,
        data: {
          ...state.data,
          shiftsTrip: action?.data?.orders ? {
            ...state.data.shiftsTrip,
            ...action.data,
            summary: action.data.summary,
            orders: state?.data?.shiftsTrip?.orders ? mergeArrays(state?.data?.shiftsTrip?.orders || [], action.data.orders) : action.data.orders,
          } : null,
        },
      };

      break;
    case UPDATE_DEPOT_USER:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.map(depot => depot.id === action.data.depotId
            ? {
                ...depot,
                depotUsers: depot.depotUsers.map(depotUser => depotUser.id === action.data.userId
                  ? { ...depotUser, ...action.data.data }
                  : depotUser
                )
              }
            : depot
          )
        }
      };

      break;
    case ADD_DEPOT_USER:
      newData = {
        ...state,
        data: {
          ...state.data,
          depots: state.data.depots.map(depot => depot.id === action.data.depotId
              ? { ...depot, depotUsers: mergeArrays(depot.depotUsers, action.data.data) }
              : depot
          )
        }
      };

      break;
    case CURRENT_CONTROL_CENTER_UPDATE:
      newData = {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

      break;
    case SET_BUSINESS_MENU:
      newData = {
        ...state,
        data: {
          ...state.data,
          businessMenu: action.data
        }
    }

    break;
    case REMOVE_BUSINESS_MENU:
      newData = {
        ...state,
        data: {
          ...state.data,
          businessMenu: null
        }
      }

    break;
    case SET_BUSINESS_PARENT_MENU:
      newData = {
        ...state,
        data: {
          ...state.data,
          parentMenu: action.data
        }
      }

    break;
    case SET_DEPOT_PROVIDERS:
      newData = {
        ...state,
        data: {
          ...state.data,
          providers: (action.data || []).map(el => ({...el, order: el.priority}))
        }
      };

    break;
    case SET_ORDERS_PROVIDER:
      newData = {
        ...state,
        data: {
          ...state.data,
          ordersProvider: action.data
        }
      }

    break;
    case FLEET_SETTINGS_LOAD:

      newData = {
        ...state,
        data: {
          ...state.data,
          fleet: action.data || []
        }
      };
      break;
    case FLEET_SETTINGS_TELEMETRY_LOAD:

      newData = {
        ...state,
        data: {
          ...state.data,
          fleetTelemetry: action.data || {}
        }
      };
      break;
    case FLEET_SETTINGS_FLEET_VEHICLE_LOAD:
      let fleet = [];

      if(state.data.fleet && action.data?.vehicleId) {
        const exist = state.data.fleet.find((e) => e.vehicleId === action.data.vehicleId);
        if(exist) {
          fleet = state.data.fleet
        } else {
          fleet = [...state.data.fleet, action.data]
        }
      } else if(action.data) {
        fleet = [action.data]
      }

      newData = {
        ...state,
        data: {
          ...state.data,
          fleet: fleet
        }
      };
      break;
    case SET_SHIFT_PROVIDERS:
      newData = {
        ...state,
        data: {
          ...state.data,
          shiftProviders: action.data
        }
      }

    break
    default:
      newData = state;
  }

  if(state && state.data &&
    state.status === STATUSES.success &&
    (!newData || !newData.data || !newData.data.configuration) &&
    action.type !== REMOVE_ALL_DATA
  ) {
    Sentry.captureException("Error. Restaurant config removed by action:" + action.type);
  }

  return newData;
}

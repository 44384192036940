export function  extraSuboptionServerModel(data) {
    return {
        Name: data.name,
        Enabled: data.enabled,
        Description: data.description,
        Order: data.order,
        Price: data.price,
		SKU: data.sku,
        Max: data.max,
        Tag: data.tag,
        Image_settings: data.image_settings,
    }
}
import {all, call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import Store from '../store';
import { saveAs } from "file-saver";
import {createSaga, ErrorData} from "../../utils/sagaHelper";
import {getErrorMessage, requestResultIsOk} from "../../utils/request-util";
import {
    CHECK_USER_BY_PHONE,
    CONFIRM_EDIT_CUSTOMER_ORDER_BY_STRIPE,
    CONFIRM_EDIT_CUSTOMER_ORDER_FINAL,
    confirmEditCustomerOrderByStripe,
    confirmEditCustomerOrderFinal,
    CREATE_CONSUMER_USER,
    EXPORT_BATCH_TEMPLATE,
    GET_BUSINESS_BY_FILTERS,
    GET_BUSINESS_MENU,
    GET_EDIT_CUSTOMER_ORDER_SUMMARY,
    GET_ORDER_INFO_AND_MENU,
    GET_PREVIOUS_ORDERS,
    GET_USER_CARDS_BY_ID,
    getEditCustomerOrderSummary,
    IMPORT_BATCH_TEMPLATE,
    resetCustomerOrderEditInfo,
    SET_EDIT_CUSTOMER_ORDER_CONFIRM,
    SET_EDIT_CUSTOMER_ORDER_NEW_CONFIRM,
    setBatchCustomersTable,
    setBusinessOrdersByFilters,
    setConfirmEditCustomerOrderLoading,
    setEditCustomerOrderCourierTip,
    setEditCustomerOrderData,
    setEditCustomerOrderEnabledPayments,
    setEditCustomerOrderLoading,
    setEditCustomerOrderPayments,
    setEditCustomerOrderSummary,
    setPreviousAddressByOrders,
    setPreviousOrder,
    VERIFY_SINGLE_TABLE_ROW_BATCH_CUSTOMERS,
    DELETE_SINGLE_TABLE_ROW_BATCH_CUSTOMERS,
    setTemplateBatchDrafts,
    GET_TEMPLATE_BATCH_DRAFTS,
    deleteBatchTemplateDraft,
    DELETE_BATCH_DRAW_TEMPLATE,
    deleteBatchCustomersTableRow,
    GET_TEMPLATE_BATCH_BY_ID,
    addTemplateBatchDrafts,
    UPDATE_BATCH_TEMPLATE_COMMENT,
    updateTemplateBatchDraft,
    GET_TEMPLATE_BATCH_ORDER_PRICE,
    UPDATE_SELECTED_BUSINESS_CONSUMER,
    CONFIRM_BATCH_ORDER,
    PROCESS_BATCH_ORDER,
    updateBatchCustomersTableRow,
    changeTemporaryConsumer,
    setEditCustomerOrderGiftOptions,
    setEditCustomerOrderProducts,
    REPEAT_ORDER,
    CONFIRM_ORDER_WITH_EDITED_WEIGHT,
    ORDER_PRICE_QUICK_EDIT,
    GET_ORDER_TIME_SLOTS, setOrderTimeSlots,
} from "../reducers/editCustomerOrder/editCustomerOrder-action";
import {
    createBatchOrderRow,
    deleteBatchOrderRow,
    deleteTemplateBatchDraft,
    exportTemplateBatchOrder,
    fetchAdminOrderById,
    fetchAdminOrders,
    fetchConsumerCardsById, fetchConsumerInfo,
    getCallCenterMenu,
    getOrderPrice, getOrderTimeSlots,
    getPaymentIntent,
    getTemplateBatchDraftById,
    getTemplateBatchDrafts, getTemplateBatchOrderPrice,
    importTemplateBatchOrder, processBatchOrder,
    setCreatedOrders, submitBatchOrder,
    updateBatchOrderComment,
    updateBatchOrderRow, updateDeliveryBusinessBatchOrder
} from "../../api/order-requests";
import {objectKeysToUpperLowerCase} from "../../utils/objects-util";
import {
    CUSTOMER_SERVICE_MODEL,
    DELIVERY_STRATEGY,
    DELIVERY_TYPE,
    MODEL_TYPE,
    PAY_METHOD,
    PAYMENT_PROVIDER
} from "../../utils/enums";
import {getDriverTipData, setBusinessMenu} from "../reducers/restaurant/restaurant-actions";
import {toast} from "react-toastify";
import i18n from "i18next";
import {loadStripe} from "@stripe/stripe-js";
import {
    actionAdminOrderUpdate,
    setAdminOrderSelectedOrderId
} from "../reducers/order/order-actions";
import {createCustomer, getCustomers} from "../../api/customer-requests.js";
import {getBusinessWithQuery} from "../../api/restaurant-requests.js";
import {getInitModel} from "../../services/initModels.js";
import {distributeAmount} from "../../utils/order.js";
import {editCustomerOrderModel} from "../../models/editCustomerOrderModel.js";

function* getOrderInfoAndMenuSaga(action) {
    yield* createSaga(function*() {
        const changeInPlace = yield select(state => state.editCustomerOrder.changeInPlace);
        const resultRequestOrderInfo = yield call(fetchAdminOrderById, action.data);
        const infoData = objectKeysToUpperLowerCase(resultRequestOrderInfo.data);
        if(requestResultIsOk(resultRequestOrderInfo)) {
            const body = getInitModel(MODEL_TYPE.editCustomerOrder, infoData);

            const resultRequestMenu = yield call(getCallCenterMenu, infoData.business_id);
            if(requestResultIsOk(resultRequestMenu)) {
                const menu = objectKeysToUpperLowerCase(resultRequestMenu.data);
                body.menu = menu;
                yield put(setBusinessMenu({...menu, depotId: menu.depot_id}));
            }
            yield put(getDriverTipData());
            const paymentCards = yield call(fetchConsumerCardsById, infoData.id);
            if(requestResultIsOk(paymentCards)) {
                body.enabledPayments = paymentCards.data;
            }
            if(changeInPlace) {
                body.changeInPlace = true;
            }

            yield put(resetCustomerOrderEditInfo(body));
            yield put(getEditCustomerOrderSummary({isEdit: true}));
        } else {
            return new ErrorData(getErrorMessage(resultRequestOrderInfo));
        }
    }, action.type);
}

function* getOrderSummarySaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const {orderInfo, coupon, recalculateDiscounts, canceledOffer, cancelPaymentIds, territory, isSingleOrder, cartId, summary: oldSummary, products, deliveryTime, deliveryType, courierTip, address, payments, deliveryStrategy} = yield select(state => state.editCustomerOrder);
        const bonus = payments.find(p => p.paymethod_id === PAY_METHOD.bonus);
        const discount = payments.find(p => p.paymethod_id === PAY_METHOD.discount);

        const summaryData = {
            recalculate_discounts: recalculateDiscounts,
            cancel_offer_ids: canceledOffer.map(o => o.offer_id),
            territory: territory,
            cart_id: (orderInfo || {}).id || cartId,
            business_id: (orderInfo || {}).business_id || action.payload?.businessId,
            customer_id: action?.payload?.userId,
            products: products,
            delivery_type: isSingleOrder ? deliveryType : DELIVERY_TYPE.takeAway,
            delivery_datetime: deliveryStrategy === DELIVERY_STRATEGY.asap ? null : deliveryTime,
            delivery_strategy: deliveryStrategy,
            location: address && address.location ? {
                lat: address.location.latitude || address.location.lat,
                lng: address.location.longitude || address.location.lng
            } : null,
            paymethod_ids: payments ? payments.map(p => p.paymethod_id === PAY_METHOD.terminal ? PAY_METHOD.creditCard : p.paymethod_id) : null,
            cancel_payment_ids: cancelPaymentIds,
            coupon: (coupon || '').trim(),
            requested_bonus: bonus ? bonus.price || bonus.saleAmount : null,
            requested_discount: discount ? discount.price || discount.saleAmount : null,
            driver_tip: courierTip,
        };
        const resultRequest = yield call(getOrderPrice, summaryData);
        if(requestResultIsOk(resultRequest)) {
            const summary = objectKeysToUpperLowerCase(resultRequest.data);
            yield put(setEditCustomerOrderProducts(summary.products));
            yield put(setEditCustomerOrderCourierTip(summary.driver_tip));
            const bonus = payments.find(p => p.paymethod_id === PAY_METHOD.bonus);
            if(bonus && summary.approved_bonus !== bonus.saleAmount) {
                yield put(setEditCustomerOrderSummary(summary));
                const newPayments = payments.map(p => p.paymethod_id === PAY_METHOD.bonus ? {...p, saleAmount: summary.approved_bonus} : p);
                yield put(setEditCustomerOrderPayments(distributeAmount(
                    action.payload?.isEdit
                        ? summary.payment_balance
                        : summary.final_price,
                    newPayments, summary.max_allowed_bonus, summary.min_credit_card_amount)));
            } else if(action.payload?.isEdit && oldSummary && oldSummary.payment_balance !== summary.payment_balance) {
                yield put(setEditCustomerOrderSummary(summary));
                yield put(setEditCustomerOrderPayments(distributeAmount(summary.payment_balance, payments, summary.max_allowed_bonus, summary.min_credit_card_amount)));
                yield put(getEditCustomerOrderSummary({isEdit: action.payload?.isEdit, businessId: action.payload?.businessId, userId: action?.payload?.userId}));
                return
            } else if(oldSummary && oldSummary.final_price !== summary.final_price) {
                yield put(setEditCustomerOrderSummary(summary));
                yield put(setEditCustomerOrderPayments(distributeAmount(summary.final_price, payments, summary.max_allowed_bonus, summary.min_credit_card_amount)));
                yield put(getEditCustomerOrderSummary({isEdit: action.payload?.isEdit, businessId: action.payload?.businessId, userId: action?.payload?.userId}));
                return
            }
            yield put(setEditCustomerOrderSummary(summary));
            yield put(setEditCustomerOrderLoading(false));
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(resultRequest));
        }
    }, action.type);
}

function* confirmOrderUpdateSaga(action) {
    yield* createSaga(function*() {
        yield put(setConfirmEditCustomerOrderLoading(true));
        const {orderInfo, recalculateDiscounts, coupon, canceledOffer, territory, deliveryType, comment, products, courierTip, cancelPaymentIds, giftOption, summary, payments, cutlery, deliveryTime, deliveryStrategy, address} = yield select(state => state.editCustomerOrder);
        const paymentRequest = payments.map(p => ({
            sale_amount: Math.round(p.saleAmount * 100),
            paymethod_id: p.paymethod_id === PAY_METHOD.terminal ? PAY_METHOD.creditCard : p.paymethod_id,
            payment_data: p.payme_sale_id || p.token,
            provider: p.provider,
            card_type: p.card_type || p.type,
            card_expiry: p.expiry,
        }));
        const paymentsNeed = summary.payment_balance > 0;
        const hasCoupon = summary?.discounts?.find(d => d.offer_type === 2 && d.coupon);

        const body = {
            recalculate_discounts: recalculateDiscounts,
            cancel_offer_ids: (canceledOffer || []).map(o => o.offer_id),
            territory,
            cart_id: orderInfo.id,
            business_id: orderInfo.business_id,
            delivery_type: deliveryType,
            delivery_datetime: deliveryStrategy === DELIVERY_STRATEGY.asap ? null : deliveryTime,
            delivery_strategy: deliveryStrategy,
            comment: comment,
            cutlery: cutlery,
            final_price: summary.final_price,
            products: products,
            payment_requests: paymentRequest && paymentsNeed ? paymentRequest : [],
            driver_tip: courierTip,
            gift_option: giftOption && giftOption.isGift ? {
                full_name: giftOption.name,
                phone: giftOption.phone,
                greeting: giftOption.greeting,
            } : null,
            cancel_payment_ids: cancelPaymentIds,
            location: address && address.location ? {
                lat: address.location.latitude || address.location.lat,
                lng: address.location.longitude || address.location.lng
            } : null,
            city: address && address.city,
            address: address && address.address,
            address_notes: address && address.address_notes,
            flat: address && address.flat,
            storey: address && address.storey,
            coupon: hasCoupon ? (coupon || '').trim() : '',
        }
        try {
            const hasStripe = payments.some(p => p.paymethod_id === PAY_METHOD.creditCard && p.provider === PAYMENT_PROVIDER.Stripe);
            if(hasStripe) {
                yield put(confirmEditCustomerOrderByStripe({body, changeInPlace: action.payload.changeInPlace}));
            } else {
                yield put(confirmEditCustomerOrderFinal({body, changeInPlace: action.payload.changeInPlace}));
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
            yield put(setConfirmEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* confirmOrdeNewSaga(action) {
    yield* createSaga(function*() {
        yield put(setConfirmEditCustomerOrderLoading(true));
        const {deliveryType, changeInPlace, coupon, cartId, territory, menu, products, comment, courierTip, giftOption, summary, payments, cutlery, deliveryTime, deliveryStrategy, address} = yield select(state => state.editCustomerOrder);
        const paymentRequest = payments.map(p => ({
            sale_amount: Math.round(p.saleAmount * 100),
            paymethod_id: p.paymethod_id === PAY_METHOD.terminal ? PAY_METHOD.creditCard : p.paymethod_id,
            payment_data: p.payme_sale_id || p.token,
            provider: p.provider,
            card_type: p.card_type || p.type,
            card_expiry: p.expiry,
        }));

        const hasCoupon = summary?.discounts?.find(d => d.offer_type === 2 && d.coupon);

        const body = {
            territory,
            cart_id: cartId,
            customer_id: action.data.user?.id,
            phone: action.data.user?.phone,
            email: action.data.user?.email,
            business_id: menu.id,
            delivery_type: deliveryType,
            delivery_datetime: deliveryStrategy === DELIVERY_STRATEGY.asap ? null : deliveryTime,
            delivery_strategy: deliveryStrategy,
            comment: comment,
            cutlery: cutlery,
            final_price: summary.final_price,
            products: products,
            payment_requests: paymentRequest ? paymentRequest : [],
            driver_tip: courierTip,
            gift_option: giftOption && giftOption.isGift ? {
                full_name: giftOption.name,
                phone: giftOption.phone,
                greeting: giftOption.greeting,
            } : null,

            location: address && address.location ? {
                lat: address.location.latitude || address.location.lat,
                lng: address.location.longitude || address.location.lng
            } : null,
            city: address && address.city,
            address: address && address.address,
            address_notes: address && address.address_notes,
            flat: address && address.flat,
            storey: address && address.storey,
            coupon: hasCoupon ? (coupon || '').trim() : '',
        }
        try {
            const hasStripe = payments.some(p => p.paymethod_id === PAY_METHOD.creditCard && p.provider === PAYMENT_PROVIDER.Stripe);
            if(hasStripe) {
                yield put(confirmEditCustomerOrderByStripe({body, changeInPlace}));
            } else {
                yield put(confirmEditCustomerOrderFinal({body, changeInPlace}));
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
    }, action.type);
}

const prepearePaymentsData = (stripePaymentIntents, cartPayments, provider) => {
    const paymentsStripe = stripePaymentIntents.map(p => ({
        paymethod_id: PAY_METHOD.creditCard,
        sale_amount: p.saleAmount,
        payment_data: p.id || null,
        card_type: p.cardType || p.card_type || p.type || null,
        provider: provider,
        card_expiry: p.expiry,
    }));
    const paymentsWithoutStripe = cartPayments.filter(p => p.paymethod_id !== PAY_METHOD.creditCard).map(p => ({
        paymethod_id: p.paymethod_id,
        sale_amount: p.saleAmount || p.sale_amount,
        payment_data: p.payment_data || p.token || null,
        card_type: p.cardType || p.card_type || p.type || null,
        provider: provider,
       expiry: p.expiry || p.card_expiry,
    }));
    return [].concat(paymentsStripe).concat(paymentsWithoutStripe);
}

function* confirmOrderByStripeSaga(action) {
    yield* createSaga(function*() {
        try {
            const {provider, publicStripeKey} = yield select(state => state.restaurant.data.paymentsConfiguration);
            const {payment_requests, territory, products, driver_tip, delivery_type, delivery_datetime, location, coupon, requested_bonus, business_id} = action.data.body;
            const stripeCards = payment_requests.filter(p => p.paymethod_id === PAY_METHOD.creditCard);
            const paymentIntentRequests = yield all(stripeCards.map(p =>
                call(getPaymentIntent, {
                    products, driver_tip, delivery_type, delivery_datetime, business_id,
                    location, coupon,
                    //email
                    territory,
                    requested_bonus: requested_bonus || 0,
                    payment_data: p.payment_data,
                    sale_amount: p.sale_amount,
                    paymethod_id: PAY_METHOD.creditCard,
                }))
            )
            const stripePaymentIntents = paymentIntentRequests.map((p, i) => ({
                ...p.data,
                cardId: stripeCards[i].id,
                saleAmount: stripeCards[i].sale_amount,
                cardType: stripeCards[i].type,
                expiry: stripeCards[i].expiry || stripeCards[i].card_expiry
            }));

            if(stripePaymentIntents.every(p => p.confirmed)) {
                const payments = prepearePaymentsData(stripePaymentIntents, payment_requests, provider);
                yield put(confirmEditCustomerOrderFinal({body: {
                    ...action.data.body,
                    payment_requests: payments
                }, changeInPlace: action.data.changeInPlace}));
                return;
            }

            const stripe = yield call(loadStripe, publicStripeKey, {location: i18n.language})
            for(let i = 0; i < stripePaymentIntents.length; i++) {
                const payment = stripePaymentIntents[i];
                if(!payment.confirmed) {
                    const {paymentIntent, error} = yield call(stripe.confirmCardPayment, payment.client_secret, {
                        payment_method: payment.payment_method_id,
                    });

                    if(paymentIntent) {
                        payment.id = paymentIntent.id;
                    }
                    if(error) {
                        throw new Error(error.message);
                    }
                }
            }
            const payments = prepearePaymentsData(stripePaymentIntents, payment_requests, provider);
            yield put(confirmEditCustomerOrderFinal({ body: {
                    ...action.data.body,
                    payment_requests: payments,
                }, changeInPlace: action.data.changeInPlace
            }));

        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            yield put(setConfirmEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* confirmOrderFinalSaga(action) {
    yield* createSaga(function*() {
        try {
            const request = yield call(setCreatedOrders, action.data.body);
            if(requestResultIsOk(request)) {
                yield put(actionAdminOrderUpdate(request.data));
                if(action.data.changeInPlace) {
                    Store.history.push('/?orderId=' + request.data.id);
                } else {
                    yield put(setAdminOrderSelectedOrderId(request.data.id));
                    Store.history.push(`${CUSTOMER_SERVICE_MODEL.order}?searchStr=${request.data.id}`);
                }
                yield put(resetCustomerOrderEditInfo());
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            yield put(setConfirmEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* checkUserBuPhone(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        try {
            const response = yield call(getCustomers, {search: action.data});
            if(requestResultIsOk(response)) {
                return objectKeysToUpperLowerCase(response.data);
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
            return e;
        } finally {
            yield put(setEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* createConsumerUserSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const user = {...action.data};
        try {
            user.birthdate = user.birth ? new Date(user.birth) : null;
            delete user.birth;
            delete user.id;
            const response = yield call(createCustomer, user);
            if(requestResultIsOk(response)) {
                const data = objectKeysToUpperLowerCase(response.data);
                data.phone = data.phone.nationalFormatedNumber;
                return data;
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
            return e;
        } finally {
            yield put(setEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* getPreviousOrderSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        try {
            const response = yield call(fetchAdminOrders, {Search: action.data});
            if(requestResultIsOk(response)) {
                const orders = response.data;
                yield put(setPreviousOrder(orders));
                const map = new Map();
                orders && orders.forEach(order => {
                    const id = `${order.city || ''} ${order.address || ''}`.trim();
                    if(!(id && id.length) || (map.has(id) && order.location)) {
                        return;
                    }
                    map.set(id, {
                        id: id,
                        city: order.city,
                        address: order.address,
                        address_notes: order.address_notes,
                        flat: order.flat,
                        location: {latitude: order.location.lat, longitude: order.location.lng},
                        storey: order.storey,
                    });
                });

                if(map.size) {
                    yield put(setPreviousAddressByOrders(Array.from(map.values())));
                }
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            yield put(setEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* getBusinessByFiltersSaga(action) {
    yield* createSaga(function*() {
        const territory = yield select(state => state.editCustomerOrder.territory);
        yield put(setEditCustomerOrderLoading(true));
        try {
            const response = yield call(getBusinessWithQuery, {
                minimal_data: true,
                location: action.data.location,
                query: action.data.query,
                search_type: action.data.searchType,
                business_category_id: action.data.category,
                customer_id: action.data.customerId,
                territory,
            });
            if(requestResultIsOk(response)) {
                yield put(setBusinessOrdersByFilters(response.data));
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
            return e;
        } finally {
            yield put(setEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* getBusinessMenuSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        try {
            const resultRequestMenu = yield call(getCallCenterMenu, action.data);
            if(requestResultIsOk(resultRequestMenu)) {
                const menu = objectKeysToUpperLowerCase(resultRequestMenu.data);
                yield put(setEditCustomerOrderData(menu));
                yield put(setBusinessMenu(menu));
            }
            return;
        } catch (e) {
            toast.error(getErrorMessage(e));
            return e;
        } finally {
            yield put(setEditCustomerOrderLoading(false));
            return;
        }
    }, action.type);
}

function* repeatOrderSaga(action) {
    yield* createSaga(function*() {
        const user = yield select(state => state.editCustomerOrder.user);
        yield put(setEditCustomerOrderLoading(true));
        let body = null;
        try {
            const resultRequestMenu = yield call(getCallCenterMenu, action.data.business_id);
            if(requestResultIsOk(resultRequestMenu)) {
                const menu = objectKeysToUpperLowerCase(resultRequestMenu.data);
                delete action.data.id;
                body = editCustomerOrderModel({ ...action.data, menu, delivery_strategy: DELIVERY_STRATEGY.asap });
                const productIds = menu.categories.flatMap(c => c.products).filter(p => p.enabled).map(p => p.id);

                body.products = body.products.filter(p => productIds.includes(p.id)).map(p => ({...p, actual_weight: 0}));
                if(action.data.saveUserData) {
                    body.user = {...action.data.consumerInfo, id: action.data.consumerInfo.consumerId};
                    body.toChekout = true;
                } else if(user) {
                    body.user = user;
                }

                if(action.data.fetchUserData) {
                    const request = yield call(fetchConsumerInfo, action.data.customer_id);
                    const user = objectKeysToUpperLowerCase(request.data);
                    body.user = {...user, id: user.consumerId};
                    body.toChekout = true;
                }
                if(action.data.changeInPlace) {
                    body.changeInPlace = true;
                }
                yield put(setEditCustomerOrderData(menu));
                yield put(setBusinessMenu(menu));
                yield put(resetCustomerOrderEditInfo(body));
            }
            return body;
        } catch (e) {
            toast.error(getErrorMessage(e));
            return e;
        } finally {
            yield put(setEditCustomerOrderLoading(false));
            return body;
        }
    }, action.type);
}

function* getUserCardsByIdSaga(action) {
    yield put(setEditCustomerOrderLoading(true));
    yield* createSaga(function*() {
        try {
            const provider = yield select(state => state.restaurant.data.paymentsConfiguration.provider);
            const paymentCards = yield call(fetchConsumerCardsById, action.data.userId);
            if(requestResultIsOk(paymentCards)) {
                yield put(setEditCustomerOrderEnabledPayments(
                    paymentCards.data.filter(p => p.provider === provider)
                ));
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
            return e;
        } finally {
            yield put(setEditCustomerOrderLoading(false));
        }
    }, action.type);
}

function* confirmOrderWithEditedWeightSaga(action) {
    yield* createSaga(function*() {
        try {
            const order = {...action.data};
            const body = {
                cancel_payment_ids: null,
                recalculate_discounts: false,
                cancel_offer_ids: [],
                cart_id: order.id,
                business_id: order.business_id,
                delivery_type: order.delivery_type,
                delivery_datetime: order.delivery_strategy === DELIVERY_STRATEGY.asap ? null : order.delivery_datetime,
                delivery_strategy: order.delivery_strategy,
                comment: order.comment,
                cutlery: order.cutlery,
                final_price: order.final_price,
                products: order.products,
                payment_requests: [],
                driver_tip: order.driver_tip,
                gift_option: order.giftOption && order.giftOption.isGift ? {
                    full_name: order.giftOption.name,
                    phone: order.giftOption.phone,
                    greeting: order.giftOption.greeting,
                } : null,
                location: order.location ? {
                    lat: order.location.latitude || order.location.lat,
                    lng: order.location.longitude || order.location.lng
                } : null,
                city: order.city,
                address: order.address,
                address_notes: order.address_notes,
                flat: order.flat,
                storey: order.storey,
                coupon: order.coupon,
                territory: order.entity_id,
                reuse_payment_method: true,
            };
            const request = yield call(setCreatedOrders, body);
            return request.data;
        } catch (e) {
            toast.error(getErrorMessage(e));
            return null;
        } finally {
        }
    }, action.type);
}

function* orderPriceQuickEditSaga(action) {
    yield* createSaga(function*() {
        try {
            const summaryData = {
                recalculate_discounts: null,
                cancel_offer_ids: null,
                territory: action.data.entity_id,
                cart_id: action.data.id,
                business_id: action.data.business_id,
                // customer_id: action.data.customer_id,
                products: action.data.products,
                delivery_type: action.data.delivery_type,
                delivery_datetime: action.data.delivery_datetime,
                delivery_strategy: action.data.delivery_strategy,
                location: action.data.location,
                cancel_payment_ids: null,
                coupon: (action.data.coupon || "").trim(),
                requested_bonus: action.data.requested_bonus,
                requested_discount: action.data.requested_discount,
                driver_tip: action.data.driver_tip,
            };
            const resultRequest = yield call(getOrderPrice, summaryData);
            return resultRequest.data;
        } catch (e) {
            toast.error(getErrorMessage(e));
            return null;
        } finally {
        }
    }, action.type);
}

function* importBatchTemplateSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        let request = null;
        if(action.data.empty) {
            request = yield call(importTemplateBatchOrder, null);
        } else {
            const file = action.data?.file
            if(!file) {
                yield put(setEditCustomerOrderLoading(false));
                return;
            };
            request = yield call(importTemplateBatchOrder, file);
        }
        if(!requestResultIsOk(request)) {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(request));
        }
        const table = objectKeysToUpperLowerCase(request.data);
        yield put(setBatchCustomersTable(table));
        yield put(addTemplateBatchDrafts(table));
        yield put(setEditCustomerOrderLoading(false));
        return table;
    }, action.type);
}

function* verifySingleTableRowBatchSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const {temporaryId, ...row} = action.data.row;
        const response = yield call(
            temporaryId ? createBatchOrderRow : updateBatchOrderRow,
            action.data.id,
            getInitModel(MODEL_TYPE.consumerServerModel, row),
        );

        if(requestResultIsOk(response)) {
            const data = objectKeysToUpperLowerCase(response.data);
            if(temporaryId) {
                yield put(changeTemporaryConsumer({row: data, temporaryId }));
            } else {
                yield put(updateBatchCustomersTableRow({row: data}));
            }
            yield put(setEditCustomerOrderLoading(false));
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(response));
        }
    }, action.type);
}

function* deleteSingleTableRowBatchSaga(action) {
    yield* createSaga(function*() {
        if(action.data.row.temporaryId) {
            yield put(deleteBatchCustomersTableRow(action.data.row.temporaryId));
            return;
        }
        const response = yield call(
            deleteBatchOrderRow,
            action.data.id,
            action.data.row.id
        );
        if(requestResultIsOk(response)) {
            yield put(deleteBatchCustomersTableRow(action.data.row.id));
        }
    }, action.type);
}


function* exportBatchTemplateSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const requestResult = yield call(exportTemplateBatchOrder);
        if (requestResultIsOk(requestResult)) {
            const c = requestResult.headers["content-disposition"];
            let fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), ""));
            const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const contentType = requestResult.headers["content-type"] || octetStreamMime;
            const blob = new Blob([requestResult.data], { type: contentType });

            yield put(setEditCustomerOrderLoading(false));
            saveAs(blob, fileName);
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getBatchTemplatesSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getTemplateBatchDrafts);
        if (requestResultIsOk(requestResult)) {
            yield put(setTemplateBatchDrafts(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getTimeSlotsSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getOrderTimeSlots, {
            delivery_type: action.data.deliveryType,
            territory: action.data.territory,
        });
        if (requestResultIsOk(requestResult)) {
            yield put(setOrderTimeSlots(objectKeysToUpperLowerCase(requestResult.data)));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* deleteBatchTemplatesSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(deleteTemplateBatchDraft, action.data);
        if (requestResultIsOk(requestResult)) {
            yield put(deleteBatchTemplateDraft(action.data));
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getTemplateBatchByIdSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(getTemplateBatchDraftById, action.data);
        if (requestResultIsOk(requestResult)) {
            const template = objectKeysToUpperLowerCase(requestResult.data);
            yield put(setBatchCustomersTable(template));
            if(template.gift_option) {
                yield put(setEditCustomerOrderGiftOptions({
                    greeting: template.gift_option.greeting,
                    phone: template.gift_option.phone,
                    name: template.gift_option.full_name,
                    isGift: true
                }))
            }
            return template;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateBatchOrderCommentSaga(action) {
    yield* createSaga(function*() {
        const requestResult = yield call(updateBatchOrderComment, action.data.id, action.data.comment);
        if (requestResultIsOk(requestResult)) {
            const batchTemplate = yield select(state => state.editCustomerOrder.batchTable);
            const template = { ...batchTemplate, comment: (objectKeysToUpperLowerCase(requestResult.data) || {}).comment || '' };
            yield put(setBatchCustomersTable(template));
            yield put(updateTemplateBatchDraft(template));
            return template;
        } else {
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* getTemplateBatchOrderPriceSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const { discount, discountFromBatch } = action.data || {};
        const { products, menu, batchTable, territory } = yield select(state => state.editCustomerOrder);
        const orderProducts = (products.length ? products : batchTable.products) || [];
        const discountValue = discountFromBatch ? batchTable.discount : discount;
        const requestResult = yield call(getTemplateBatchOrderPrice, batchTable.id, {
            business_id: menu?.id || batchTable.business_id, products: orderProducts, territory_id: territory,discount: discountValue || 0
        });
        if (requestResultIsOk(requestResult)) {
            const template = objectKeysToUpperLowerCase(requestResult.data);
            yield put(setBatchCustomersTable(template));
            yield put(setEditCustomerOrderSummary(template));
            yield put(setEditCustomerOrderProducts(template.products));
            yield put(setEditCustomerOrderLoading(false));
            return template;
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* updateSelectedBusinessConsumerSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const {row, businessId, deliveryType} = action.data;
        const { batchTable } = yield select(state => state.editCustomerOrder);
        const requestResult = yield call(updateDeliveryBusinessBatchOrder, batchTable.id, row.id, {
            selected_business_id: businessId || row.selected_business_id,
            delivery_type: deliveryType || row.delivery_type,
        });
        if (requestResultIsOk(requestResult)) {
            const template = objectKeysToUpperLowerCase(requestResult.data);
            yield put(setBatchCustomersTable(template));
            yield put(setEditCustomerOrderLoading(false));
            return template;
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* processBatchTableSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const {templateId} = action.data;
        const requestResult = yield call(processBatchOrder, templateId);

        if (requestResultIsOk(requestResult)) {
            const template = objectKeysToUpperLowerCase(requestResult.data);
            yield put(updateTemplateBatchDraft(template));
            yield put(setEditCustomerOrderLoading(false));
            return template;
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

function* confirmBatchOrderSaga(action) {
    yield* createSaga(function*() {
        yield put(setEditCustomerOrderLoading(true));
        const { discount } = action.data ?? 0;
        const { batchTable, bulkEmail, payments, giftOption, batchDraft, isSingleOrder,invoice_recipient } = yield select(state => state.editCustomerOrder);
        const payment = payments[0] || null;
        const batchPaymentModel = {
            email: bulkEmail,
            invoiceRecipient: invoice_recipient,
            giftOption: giftOption.isGift ? {
                greeting: giftOption.greeting,
                full_name: giftOption.name,
                phone: giftOption.phone,
            } : null ,
            paymentRequests: payment ? [{
                sale_amount: batchTable.price * 100,
                paymethod_id: payment.paymethod_id,
                payment_data: payment.payme_sale_id || payment.token,
                provider: payment.provider,
                card_type: payment.card_type || payment.type,
                card_expiry: payment.expiry,
            }] : []
        }
        if(discount) {
            batchPaymentModel.paymentRequests = batchPaymentModel.paymentRequests
                .map(p => ({ ...p, sale_amount: batchTable.price * 100 }))
                .concat({
                    paymethod_id: PAY_METHOD.discount,
                    sale_amount: discount * 100,
                });
        }
        const requestResult = yield call(submitBatchOrder, batchTable.id, batchPaymentModel);
        if (requestResultIsOk(requestResult)) {
            const template = objectKeysToUpperLowerCase(requestResult.data);
            yield put(resetCustomerOrderEditInfo({ batchDraft, batchTable: template, isSingleOrder }))
            yield put(updateTemplateBatchDraft(template));
            yield put(setEditCustomerOrderLoading(false));
            return template;
        } else {
            yield put(setEditCustomerOrderLoading(false));
            return new ErrorData(getErrorMessage(requestResult));
        }
    }, action.type);
}

export default function* () {
    yield takeEvery(GET_ORDER_INFO_AND_MENU, getOrderInfoAndMenuSaga);
    yield takeLatest(GET_EDIT_CUSTOMER_ORDER_SUMMARY, getOrderSummarySaga);
    yield takeEvery(ORDER_PRICE_QUICK_EDIT, orderPriceQuickEditSaga);
    yield takeEvery(SET_EDIT_CUSTOMER_ORDER_CONFIRM, confirmOrderUpdateSaga);
    yield takeEvery(SET_EDIT_CUSTOMER_ORDER_NEW_CONFIRM, confirmOrdeNewSaga);
    yield takeEvery(CONFIRM_EDIT_CUSTOMER_ORDER_BY_STRIPE, confirmOrderByStripeSaga);
    yield takeEvery(CONFIRM_EDIT_CUSTOMER_ORDER_FINAL, confirmOrderFinalSaga);
    yield takeEvery(CHECK_USER_BY_PHONE, checkUserBuPhone);
    yield takeEvery(CREATE_CONSUMER_USER, createConsumerUserSaga);
    yield takeEvery(GET_PREVIOUS_ORDERS, getPreviousOrderSaga);
    yield takeEvery(GET_BUSINESS_BY_FILTERS, getBusinessByFiltersSaga);
    yield takeEvery(GET_BUSINESS_MENU, getBusinessMenuSaga);
    yield takeEvery(REPEAT_ORDER, repeatOrderSaga);
    yield takeEvery(CONFIRM_ORDER_WITH_EDITED_WEIGHT, confirmOrderWithEditedWeightSaga);
    yield takeEvery(GET_USER_CARDS_BY_ID, getUserCardsByIdSaga);
    yield takeEvery(IMPORT_BATCH_TEMPLATE, importBatchTemplateSaga);
    yield takeEvery(EXPORT_BATCH_TEMPLATE, exportBatchTemplateSaga);
    yield takeEvery(VERIFY_SINGLE_TABLE_ROW_BATCH_CUSTOMERS, verifySingleTableRowBatchSaga);
    yield takeEvery(DELETE_SINGLE_TABLE_ROW_BATCH_CUSTOMERS, deleteSingleTableRowBatchSaga);
    yield takeEvery(GET_TEMPLATE_BATCH_DRAFTS, getBatchTemplatesSaga);
    yield takeEvery(GET_ORDER_TIME_SLOTS, getTimeSlotsSaga);
    yield takeEvery(DELETE_BATCH_DRAW_TEMPLATE, deleteBatchTemplatesSaga);
    yield takeEvery(GET_TEMPLATE_BATCH_BY_ID, getTemplateBatchByIdSaga);
    yield takeEvery(UPDATE_BATCH_TEMPLATE_COMMENT, updateBatchOrderCommentSaga);
    yield takeEvery(GET_TEMPLATE_BATCH_ORDER_PRICE, getTemplateBatchOrderPriceSaga);
    yield takeEvery(UPDATE_SELECTED_BUSINESS_CONSUMER, updateSelectedBusinessConsumerSaga);
    yield takeEvery(CONFIRM_BATCH_ORDER, confirmBatchOrderSaga);
    yield takeEvery(PROCESS_BATCH_ORDER, processBatchTableSaga);
}

import i18n from "../../i18n";

import FilterOptionsModel from "../../models/filterOptionsModel";
import SectionModel from "../../models/sectionModel";

import {
    BUSINESS_PARENT_MENU,
    BUSINESS_PARENT_MENU_GET,
    BUSINESS_PARENT_MENU_UPDATE,
    PARENT_MENU_OVERRIDE_CATEGORY,
    PARENT_MENU_OVERRIDE_DELETE,
    PARENT_MENU_OVERRIDE_EXTRA,
    PARENT_MENU_OVERRIDE_OPTION,
    PARENT_MENU_OVERRIDE_PRODUCT,
    PARENT_MENU_OVERRIDE_SUBOPTION,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    EXTRA_TYPES,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    ORDERING_MENU_TYPE_SECTION_MODEL,
    PARENT_MENU_UNIT_TYPES,
    spicyTypes,
    TYPE_SECTION_DEPOT_MODEL,
} from '../../utils/enums';
import {getDeliveryZonesFilter} from "../filter";
import {getInitModel} from "../initModels";
import {createBreadcrumbModel} from "../createBreadcrumbModel";
import {getHistoryLocationParams} from "../../utils/routesHelper";
import {getAllExtra, getAllOption, getAllSuboption, getDepotNameById, getOrderingMenuOptions,} from '../restaurant';
import {createControlModel, filtrateControlByDepotRole} from "../controlModelFactory";
import {getRestaurantBusinessMenu} from "../../store/selectors";

export function createParentMenuSection({ ...args }) {
    const { parentId, subId, subToId, id } = getHistoryLocationParams(window.location.search);
    const extraTypesModels = {
        [EXTRA_TYPES.extra]:
        ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuExtraOverride +
        `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}&extra=`,
        [EXTRA_TYPES.option]:
        ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuOptionsOverride +
        `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}&option=`,
        [EXTRA_TYPES.suboption]:
        ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuSuboptionsOverride +
        `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}suboption=`,
        [PARENT_MENU_UNIT_TYPES.category]:
        ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuCategoryOverride +
        `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}suboption=`,
        [PARENT_MENU_UNIT_TYPES.product]:
        ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuProductOverride +
        `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}suboption=`,
    };
    return new SectionModel({
        filterOptions: createDeliveryZonesSectionFilter(),
        isForceUpdate: true,
        fixScroll: true,
        columns: [
            {
                title: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU_NAME",
                accessor: "name",
                isEditable: true,
                nestedTab: 15,
                type: CONTROL_TYPE.nestedItem,
            },
            {
                accessor: "enabled",
                isEditable: true,
                component: function(item) {
                    return item && item.enabled
                        ? i18n.t("basic.ENABLED_STATUS")
                        : i18n.t("basic.DISABLED_STATUS")
                },
                type: CONTROL_TYPE.switch,
                width: "100px"
            },
            {
                title: "",
                controlTitle: "",
                accessor: "enabled",
                width: "120px",
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
                withLoader: true
            },
            {
                title: '',
                width: '55px',
                centered: true,
                isLayout: true,
                quantity: item => {
                    const extraOverrides = item && item.overrides && item.overrides.filter(x => x.id === item.id && x.name !== "enabled");
                    return (extraOverrides && extraOverrides.length) || 0;
                },
                type: CONTROL_TYPE.iconWithQuantity,
                url: item => {
                    return extraTypesModels[item.type] + item.id
                },
                iconName: 'editItem',
                isShow: (item) => item.type,
            },
            {
                title: '',
                width: '55px',
                isShow: (item) => !item.type,
            }
        ],
        showDeleteButton: false,
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: BUSINESS_PARENT_MENU,
        editAction: BUSINESS_PARENT_MENU_UPDATE,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        typeAction: args.typeAction,
        parentUrl: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
        controls: filtrateControlByDepotRole([
            createControlModel({
                type: MODEL_CONTROL_TYPE.back
            }),
        ]),
        label: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU",
        breadcrumbs: [
            createBreadcrumbModel({
                label: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU"
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getDepotNameById(id);
                }
            })
        ],
        mappingLambda: state => {
            const businessMenu = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu) || {};
            const parentMenu = (state.restaurant.data.parentMenu && state.restaurant.data.parentMenu) || {};
            const data = getInitModel(MODEL_TYPE.parentMenu, {businessMenu, parentMenu}).data || [];
            const getOverridedName = object => {
                const findOverride = (businessMenu.overrides || []).find(o => o.object_id === object.id && o.name === 'name');
                return findOverride ? `${findOverride.value} (${object.name})` : object.name;
            }
            return data.map(el => ({...el,
                name: getOverridedName(el),
                overrides: businessMenu.overrides}));
        }
    });
}

export function createParentMenuEditSection({ ...args }) {
    return new SectionModel({
        isForceUpdate: true,
        columns: [
            {
                title: "settings.orderingmenu.MENU_BUSINESS_PARENT_MENU_NAME",
                accessor: "name",
                type: CONTROL_TYPE.text,
            },
            {
                title: "settings.orderingmenu.DELIVERY_ZONE_ENABLED_LABEL",
                accessor: "enabled",
                isEditable: true,
                type: CONTROL_TYPE.switch
            },
        ],
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: BUSINESS_PARENT_MENU,
        initNewItemModel: item => item,
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu) || {};
            const parentMenu = (state.restaurant.data.parentMenu && state.restaurant.data.parentMenu) || {};
            return getInitModel(MODEL_TYPE.parentMenu, {businessMenu, parentMenu}).data;
        }
    });
}

function createDeliveryZonesSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(deliveryZone) {
        return getDeliveryZonesFilter(this.selected, deliveryZone);
    });

    return filterOptionsModel;
}


export function createParentMenuSuboptionsOverride({ ...args }) {
    return new SectionModel({
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_PRICE_LABEL',
                accessor: 'price',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('price');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_MAX_LABEL',
                accessor: 'max',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('max');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_SKU_LABEL',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('sku');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
        ],
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: PARENT_MENU_OVERRIDE_SUBOPTION,
        initNewItemModel: item => getInitModel(MODEL_TYPE.suboptionsModelWithOverrides, item),
        isLayoutForm: true,
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuSuboptionsOverride,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = getRestaurantBusinessMenu(state);
            const parentMenu = state.restaurant.data.parentMenu || {};
            return getAllSuboption(parentMenu).map(el => ({...el, overrides: businessMenu?.overrides}));
        },
    });
}

export function createParentMenuOptionsOverride({ ...args }) {
    const { id, parentId } = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MIN_LABEL',
                accessor: 'min',
                isEditable: true,
                type: CONTROL_TYPE.number,
                max: item => item.max,
                useColorRed: item => item.min > item.max && item.max !== 0,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('min');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MAX_LABEL',
                accessor: 'max',
                isEditable: true,
                useColorRed: item => item.min > item.max && item.min !== 0,
                type: CONTROL_TYPE.number,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('max');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_SKU',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('sku');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_HALF_OPTION_AVAILLABLE_LABEL',
                accessor: 'with_half_option',
                accessorsToOff: ['pizza_option'],
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('with_half_option');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ALLOW_SUBOPTION_QUANTITY_LABEL',
                accessor: 'allow_suboption_quantity',
                isInline: true,
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('allow_suboption_quantity');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_LIMIT_SUBOPTIONS_BY_MAX_LABEL',
                accessor: 'limit_suboptions_by_max',
                isInline: true,
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('limit_suboptions_by_max');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_CONDITIONED_LABEL',
                accessor: 'conditioned',
                isShow: () => {
                    const options = getOrderingMenuOptions(parentId, id);
                    return options.length > 1;
                },
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('conditioned');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_PIZZA_OPTION_LABEL',
                accessor: 'pizza_option',
                alwaysOnWithAccessor: 'with_half_option',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('pizza_option');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
        ],
        isLayoutForm: true,
        isEditable: true,
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: PARENT_MENU_OVERRIDE_OPTION,
        initNewItemModel: item => getInitModel(MODEL_TYPE.optionModelWithOverrides, item),
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuOptionsOverride,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = getRestaurantBusinessMenu(state);
            const parentMenu = state.restaurant.data.parentMenu || {};
            return getAllOption(parentMenu).map(el => ({...el, overrides: businessMenu.overrides}));
        },
    });
}

export function createParentMenuExtraOverride({ ...args }) {
    return new SectionModel({
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
        ],
        isEditable: true,
        isLayoutForm: true,
        isHideControls: true,
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: PARENT_MENU_OVERRIDE_EXTRA,
        initNewItemModel: item => getInitModel(MODEL_TYPE.extraModelWithOverrides, item),
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuExtraOverride,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = getRestaurantBusinessMenu(state);
            const parentMenu = state.restaurant.data.parentMenu || {};
            return getAllExtra(parentMenu).map(el => ({...el, overrides: businessMenu.overrides}));
        },
    });
}

export function createParentMenuCategoriesOverride({ ...args }) {
    return new SectionModel({
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
        ],
        isEditable: true,
        isLayoutForm: true,
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: PARENT_MENU_OVERRIDE_CATEGORY,
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.categoryModelOverride, item)
        },
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuCategoryOverride,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = getRestaurantBusinessMenu(state);
            const parentMenu = state.restaurant.data.parentMenu || {};
            const categories = parentMenu.categories || [];
            return categories.map(el => ({...el, overrides: businessMenu.overrides}));
        },
    });
}

export function createParentMenuProductOverride({ ...args }) {
    return new SectionModel({
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_UNIT_WEIGHT',
                accessor: 'unit_weight',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isInline: true,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('unit_weight');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                isEditable: true,
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_WEIGHTED',
                accessor: 'weighed',
                type: CONTROL_TYPE.switch,
                isInline: true,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('weighed');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                isEditable: true,
                isInline: true,
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_WEIGHT_RANGE',
                accessor: 'weight_range',
                type: CONTROL_TYPE.number,
                measure: '%',
                min: () => 0,
                max: () => 100,
                isShow: item => item.weighed,
                useColorRed: item => item?.weight_range > 100 || item?.weight_range < 0,
                isItemValid: item => item?.weight_range <= 100 && item?.weight_range >= 0 ,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('weight_range');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_LABEL',
                accessor: 'price',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('price');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_BEFORE_DISCOUNT',
                accessor: 'price_before_discount',
                isEditable: true,
                type: CONTROL_TYPE.number,
                useColorRed: item => item.price_before_discount < item.price && item.price_before_discount,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('price_before_discount');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_UPSELLING_LABEL',
                accessor: 'upselling',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('upselling');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_VEGAN_LABEL',
                accessor: 'vegan',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('vegan');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_GLUTEN_FREE_LABEL',
                accessor: 'gluten_free',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('gluten_free');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_VEGITERIAN_LABEL',
                accessor: 'vegetarian',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('vegetarian');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_SPICY_LABEL',
                accessor: 'spicy',
                connect: () => ({
                    values: spicyTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('spicy');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_SKU',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('sku');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_MAX',
                accessor: 'max',
                isEditable: true,
                type: CONTROL_TYPE.number,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('max');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_ALLOW_COMMENTS_LABEL',
                accessor: 'allow_comments',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('allow_comments');
                },
                labelIconName: 'delete',
                clickAction: PARENT_MENU_OVERRIDE_DELETE,
            },
        ],
        isEditable: true,
        isLayoutForm: true,
        actionLoad: BUSINESS_PARENT_MENU_GET,
        actionName: PARENT_MENU_OVERRIDE_PRODUCT,
        initNewItemModel: item => getInitModel(MODEL_TYPE.categoryProductModelOverride, item),
        typeAction: args.typeAction,
        typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.parentMenuProductOverride,
        parentUrl: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessParentMenu,
        mappingLambda: state => {
            const businessMenu = getRestaurantBusinessMenu(state);
            const parentMenu = state.restaurant.data.parentMenu || {};
            const products = parentMenu.categories.flatMap(c => c.products);
            return products.map(el => ({...el, overrides: businessMenu.overrides}));
        },
    });
}

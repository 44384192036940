import React from 'react';
import i18n from '../../i18n';

import FilterOptionsModel from '../../models/filterOptionsModel';
import SectionModel from '../../models/sectionModel';

import {
    GET_ORDERING_MENU_BUSINESS_EXTRA,
    ORDERING_MENU,
    ORDERING_MENU_BUSINESS_CATEGORIES,
    ORDERING_MENU_BUSINESS_CATEGORIES_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORIES_LIST_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORIES_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DELETE,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION,
    ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DRAG_UPDATE,
    ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_UPDATE,
    ORDERING_MENU_BUSINESS_EXTRA,
    ORDERING_MENU_BUSINESS_EXTRA_DELETE,
    ORDERING_MENU_BUSINESS_EXTRA_OPTIONS,
    ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION,
    ORDERING_MENU_BUSINESS_EXTRA_UPDATE,
    ORDERING_MENU_BUSINESS_GALLERY,
    ORDERING_MENU_BUSINESS_GALLERY_REORDER,
    ORDERING_MENU_GET,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_DELETE,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION,
    TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
} from '../../store/reducers/restaurant/restaurant-actions';

import {
    CONTROL_TYPE,
    CROP_MODE,
    EXTRA_TYPES,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
    spicyTypes,
    TYPE_ACTION, TYPE_SECTION_MENUTEMPLATE_MODEL,
} from '../../utils/enums';
import { getHistoryLocationParams } from '../../utils/routesHelper';
import { createControlModel } from '../controlModelFactory';
import { getBusinesMenuCategoriesFilter, getCategoryProductFilter, getGalleryFilter, sortByProp } from '../filter';
import { getInitModel } from '../initModels';
import {
    getBusinessMenuCategories, getBusinessMenuGalleryNameById,
    getCurrentCurrency,
    getCurrentOrderingMenuDepotId,
    getDepotCategoryExtras,
    getExtraNameFromBusinessCategory,
    getExtraOptionNameFromBusinessCategory,
    getMenuTemplateNameById,
    getNameByIdsBusinessMenu,
    getNameForExtraById,
    getNameForOptionExtraById,
    getNameForProductsByIds,
    getNewCategoryOrder,
    getOrderingMenuExtraOptions,
    getOrderingMenuOptions, getTemplateMenuById,
} from '../restaurant';

import ItemLogo from '../../app/components/ItemLogo';
import { mergeArrays } from '../../utils/objects-util';
import { createBreadcrumbModel } from '../createBreadcrumbModel';

export function createOrderingMenuEditSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU,
        initNewItemModel: item => item && item.businessMenu,
        typeAction: args.typeAction,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
        parentUrl: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
        mappingLambda: state => state.restaurant.data.depots || [],
    });
}

export function createBusinesMenuSectionMenuTemplate({ ...args }) {
    const { id } = getHistoryLocationParams(window.location.search);

    return new SectionModel({
        filterOptions: createBusinesMenuCategoriesSectionFilter(),
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_NAME_LABEL',
                accessor: 'name',
                url: item =>
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts +
                    '?parentId=' +
                    id +
                    '&id=' +
                    item.id,
                type: CONTROL_TYPE.linkControl,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_STATUS_LABEL',
                component: function(item) {
                    return item && item.enabled ? i18n.t('basic.ENABLED_STATUS') : i18n.t('basic.DISABLED_STATUS');
                },
            },
            {
                title: '',
                controlTitle: '',
                accessor: 'enabled',
                tooltipText: i18n.t('settings.orderingmenu.MENU_BUSINESS_CATEGORY_STATUS_CHANGE_TOOLTIP'),
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
                withLoader: true,
            },
            {
                title: '',
                component: function(item) {
                    return `${i18n.t('settings.orderingmenu.MENU_BUSSINES_CATEGORY_ITEMS_LABEL')}: ${(item.products &&
                        item.products.length) ||
                        0}`;
                },
                width: '90px',
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_CATEGORIES,
        tableMinWidth: 600,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
        typeAction: args.typeAction,
        parentUrl: TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
        breadcrumbs: [
            createBreadcrumbModel({
                label:'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(id);
                },
            }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                isLayout: true,
                type: MODEL_CONTROL_TYPE.add,
                url: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew + `?parentId=${id}`,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
        ],
        isCustomEditIcon: true,
        editControlsConfig: [
            {
                title: 'basic.EDIT_BUTTON',
                link: item => {
                    return (
                        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit +
                        `?parentId=${id}&id=${item.id}`
                    );
                },
                isLayout: true,
            },
            {
                title: 'basic.DELETE_BUTTON',
                action: ORDERING_MENU_BUSINESS_CATEGORIES_DELETE,
                isConfirm: true,
            },
        ],
        tabs: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
                search: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return `?id=${id}`
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras,
                search: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return `?id=${id}`
                },
            },
        ],
        isDraggable: false,
        dragActionName: ORDERING_MENU_BUSINESS_CATEGORIES_UPDATE,
        editAction: ORDERING_MENU_BUSINESS_CATEGORIES_LIST_UPDATE,
        label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB',
        sortByFunc: function(items) {
            return sortByProp(items);
        },
        mappingLambda: state => state.restaurant.data?.businessMenu?.categories || []
    });
}

export function createBusinesMenuEditSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_NAME_LABEL',
                accessor: 'name',
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_STATUS_LABEL',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_CATEGORIES,
        initNewItemModel: item => {
            const { id } = getHistoryLocationParams(window.location.search);
            const order = item && item.id ? item.order : getNewCategoryOrder(id);
            return {
                ...getInitModel(MODEL_TYPE.categoryModel, item),
                order,
            };
        },
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
        isHideControls: true,
        isLayoutForm: true,
        mappingLambda: state => {
            return (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
        },
    });
}

export function createBusinesMenuExtraSectionMenuTemplate({ ...args }) {
    const { id } = getHistoryLocationParams(args.params);

    return new SectionModel({
        filterOptions: createCategoryProductsSectionFilter(),
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                url: item =>
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit +
                    `?id=${id}&subId=${item.id}`,
                // width: '200px',
                type: CONTROL_TYPE.linkControl,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_STATUS_LABEL',
                component: function(item) {
                    return item && item.enabled ? i18n.t('basic.ENABLED_STATUS') : i18n.t('basic.DISABLED_STATUS');
                },
                // width: '70px',
            },
            {
                title: '',
                controlTitle: '',
                accessor: 'enabled',
                // width: '200px',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
                withLoader: true,
            },
            {
                title: '',
                component: function(item) {
                    return `${i18n.t('settings.orderingmenu.MENU_BUSSINES_CATEGORY_OPTIONS_LABEL')} ${(item.options &&
                        item.options.length) ||
                        0}`;
                },
                // width: '70px',
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS,
        tableMinWidth: 600,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras,
        typeAction: args.typeAction,
        parentUrl: TYPE_SECTION_MENUTEMPLATE_MODEL.menuTemplateEdit,
        isForceUpdate: true,
        label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(id);
                },
            }),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew + `?id=${id}`,
            }),
        ],
        showDeleteButton: false,
        isCustomEditIcon: true,
        editControlsConfig: [
            {
                title: 'basic.EDIT_BUTTON',
                link: item => {
                    return (
                        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit +
                        `?id=${id}&subId=${item.id}`
                    );
                },
            },
            {
                title: 'basic.DELETE_BUTTON',
                action: ORDERING_MENU_BUSINESS_EXTRA_DELETE,
                isConfirm: true,
            },
        ],
        editAction: ORDERING_MENU_BUSINESS_EXTRA_UPDATE,
        tabs: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
                search: `?id=${id}`,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras,
                search: `?id=${id}`,
            },
        ],
        sortByFunc: function(items) {
            return sortByProp(items);
        },
        mappingLambda: state => {
            const businessMenu = state.restaurant.data.businessMenu;
            const extras = (businessMenu && businessMenu.extras) || [];
            return extras || [];
        },
    });
}

export function createBusinesMenuExtraEditSectionMenuTemplate({ ...args }) {
    const { id, subId } = getHistoryLocationParams(args.params);
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_STATUS_LABEL',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTIONS_LABEL',
                accessor: 'options',
                model: new SectionModel({
                    isDraggable: true,
                    typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOption,
                    actionName: ORDERING_MENU_BUSINESS_EXTRA_OPTIONS,
                    columns: [
                        {
                            title: '',
                            accessor: 'name',
                            url: item =>
                                ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit +
                                `?id=${getCurrentOrderingMenuDepotId()}&subId=${subId}&subToId=${item.id}`,
                            type: CONTROL_TYPE.linkControl,
                            width: 'calc(100% - 215px)',
                        },
                        {
                            title: '',
                            accessor: 'enabled',
                            label: {
                                onLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_ENABLED_LABEL',
                                offLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_DISABLED_LABEL',
                            },
                            type: CONTROL_TYPE.switch,
                            isEditable: true,
                            width: '100px',
                        },
                    ],
                }),
                isEditable: true,
                isFillContent: true,
                newItemUrl:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew +
                    `?parentId=${id}&subId=${subId}`,
                isShowNewItemControl: true,
                isDisabledNewItemControl: item => !item || !item.id,
                type: CONTROL_TYPE.editTable,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_EXTRA,
        editCheckExcludedProps: ['options'],
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.extraModel, item);
        },
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras,
        businessId: id,
        getLabel: () => {
            const { id, subId } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit
                ? getNameForExtraById(id, subId)
                : i18n.t('settings.orderingmenu.NEW_MENU_BUSINESS_CATEGORY_TAB_EXTRAS');
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
            }),
        ],
        mappingLambda: state => {
            const businessMenu = state.restaurant.data.businessMenu;
            const extras = (businessMenu && businessMenu.extras) || [];
            return extras || [];
        },
    });
}

export function createBusinesMenuExtraOptionEditSectionMenuTemplate({ ...args }) {
    const { id, subId, subToId } = getHistoryLocationParams(args.params);
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_NAME_LABEL',
                accessor: 'name',
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ENABLED_LABEL',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
            },
            {
                id: 'group1',
                columns: [
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MIN_LABEL',
                        accessor: 'min',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MAX_LABEL',
                        accessor: 'max',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_FREE_BUDGET',
                        accessor: 'free_budget',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        isItemValid: item => item && item.max >= item.free_budget,
                        max: item => item.max,
                        useColorRed: item => item.free_budget > item.max && item.free_budget !== 0,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_SKU',
                        accessor: 'sku',
                        isEditable: true,
                        type: CONTROL_TYPE.text,
                    },
                ],
                isEditable: true,
                type: CONTROL_TYPE.group,
            },
            {
                items: [
                    [
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_HALF_OPTION_AVAILLABLE_LABEL',
                            accessor: 'with_half_option',
                            accessorsToOff: ['reduce_price_for_half_option', 'pizza_option'],
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_REDUCE_PRICE_FOR_HALF_OPTION',
                            accessor: 'reduce_price_for_half_option',
                            alwaysOnWithAccessor: 'with_half_option',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_PIZZA_OPTION_LABEL',
                            alwaysOnWithAccessor: 'with_half_option',
                            accessor: 'pizza_option',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ALLOW_SUBOPTION_QUANTITY_LABEL',
                            accessor: 'allow_suboption_quantity',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_LIMIT_SUBOPTIONS_BY_MAX_LABEL',
                            accessor: 'limit_suboptions_by_max',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_CONDITIONED_LABEL',
                            accessor: 'conditioned',
                            isShow: () => {
                                const options = getOrderingMenuExtraOptions(id);
                                return options.length > 1;
                            },
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                    ],
                ],
                isEditable: true,
                type: CONTROL_TYPE.switchGroups,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_RESPECT_TO_LABEL',
                accessor: 'respect_to',
                isShow: item => item.conditioned,
                connect: state => {
                    const options = getOrderingMenuExtraOptions(subId, state);
                    return {
                        values: options
                            .filter(option => option.id !== subToId)
                            .reduce((arr, option) => {
                                return arr.concat(
                                    option.suboptions.map(suboption => ({
                                        key: suboption.id,
                                        value: option.name + '/' + suboption.name,
                                    }))
                                );
                            }, []),
                    };
                },
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_SUBOPTIONS_LABEL',
                accessor: 'suboptions',
                model: new SectionModel({
                    typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSuboption,
                    isLayoutForm: true,
                    showDeleteButton: true,
                    isDraggable: true,
                    actionName: ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION,
                    columns: [
                        {
                            title: '',
                            accessor: 'name',
                            url: () =>
                                ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionEdit +
                                `?id=${getCurrentOrderingMenuDepotId()}&subId=${subId}&subToId=${subToId}`,
                            type: CONTROL_TYPE.linkControl,
                            isLayout: true,
                            width: 'calc(100% - 280px)',
                        },
                        {
                            title: '',
                            component: function(item) {
                                return item.price + ' ' + getCurrentCurrency();
                            },
                            width: '100px',
                        },
                        {
                            title: '',
                            accessor: 'enabled',
                            label: {
                                onLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_ENABLED_LABEL',
                                offLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_DISABLED_LABEL',
                            },
                            type: CONTROL_TYPE.switch,
                            isEditable: true,
                            width: '100px',
                        },
                    ],
                }),
                isShowNewItemControl: true,
                isDisabledNewItemControl: item => !item || !item.id,
                isEditable: true,
                isFillContent: true,
                newItemUrl:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasSubsuboptionNew +
                    `?parentId=${id}&id=${subId}&subId=null`,
                type: CONTROL_TYPE.editTable,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_EXTRA_OPTIONS,
        initNewItemModel: item => getInitModel(MODEL_TYPE.productOptionModel, item),
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasOptionNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtras,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        saveControlsConfig: {
            cancel: {
                action: (item, history) =>
                    history.push(
                        `${
                            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit
                        }?id=${getCurrentOrderingMenuDepotId()}&subId=${subId}`
                    ),
            },
            save: {
                action: (item, history) =>
                    history.push(
                        `${
                            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuExtrasEdit
                        }?id=${getCurrentOrderingMenuDepotId()}&subId=${subId}`
                    ),
            },
        },
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, parentId } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(id) || getNameByIdsBusinessMenu(parentId);
                },
            }),
            createBreadcrumbModel({
                label:'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, subId } = getHistoryLocationParams(window.location.search);
                    return getNameForExtraById(id, subId);
                },
            }),
        ],
        getLabel: () => {
            const { id, subId, subToId   } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit
              ? getNameForOptionExtraById(id, subId, subToId)
              : i18n.t('settings.orderingmenu.NEW_MENU_BUSINESS_CATEGORY_TAB_EXTRAS');
        },
        mappingLambda: state => {
            const { subId } = getHistoryLocationParams(window.location.search);
            const businessMenu = state.restaurant.data.businessMenu;
            const extras = (businessMenu && businessMenu.extras) || [];
            const extra = extras.find(f => f.id === subId);
            return (extra && extra.options) || [];
        },
    });
}

export function createBusinesMenuExtraSuboptionEditSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'image',
                settingsAaccessor: 'image_settings',
                isEditable: true,
                type: CONTROL_TYPE.imageEdit,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_NAME_LABEL',
                accessor: 'name',
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_ENABLED_LABEL',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_PRICE_LABEL',
                accessor: 'price',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_MAX_LABEL',
                accessor: 'max',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_SKU_LABEL',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_CUSTOM_TAG_LABEL',
                accessor: 'tag',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
        ],
        actionName: ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION,
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.extraSuboptionModel, item);
        },
        isLayoutForm: true,
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
        mappingLambda: state => {
            const { subId, subToId } = getHistoryLocationParams(window.location.search);
            const businessMenu = state.restaurant.data.businessMenu;
            const extras = (businessMenu && businessMenu.extras) || [];
            const extra = extras.find(f => f.id === subId);
            const options = (extra && extra.options) || [];
            const optionId = subToId;
            const option = options.find(f => f.id === optionId);
            return (option && option.suboptions) || [];
        },
    });
}

export function createCategoryExtrasSectionMenuTemplate({ ...args }) {
    const { parentId, id } = getHistoryLocationParams(args.params);

    return new SectionModel({
        filterOptions: createCategoryProductsSectionFilter(),
        isForceUpdate: true,
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                url: item =>
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit +
                    '?parentId=' +
                    parentId +
                    '&id=' +
                    id +
                    '&subId=' +
                    item.id,
                width: '200px',
                type: CONTROL_TYPE.linkControl,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_STATUS_LABEL',
                component: function(item) {
                    return item && item.enabled ? i18n.t('basic.ENABLED_STATUS') : i18n.t('basic.DISABLED_STATUS');
                },
                width: '70px',
            },
            {
                title: '',
                controlTitle: '',
                accessor: 'enabled',
                width: '200px',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
            {
                title: '',
                component: function(item) {
                    return `${i18n.t('settings.orderingmenu.MENU_BUSSINES_CATEGORY_OPTIONS_LABEL')} ${(item.options &&
                        item.options.length) ||
                        0}`;
                },
                width: '70px',
            },
        ],
        actionLoad: GET_ORDERING_MENU_BUSINESS_EXTRA,
        actionName: ORDERING_MENU_BUSINESS_EXTRA,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras,
        typeAction: args.typeAction,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
        controls: [
            createControlModel({ type: MODEL_CONTROL_TYPE.back }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew +
                    `?parentId=${parentId}&id=${id}&subId=0`,
            }),
        ],
        showDeleteButton: false,
        isCustomEditIcon: true,
        editControlsConfig: [
            {
                title: 'basic.EDIT_BUTTON',
                link: item => {
                    return (
                        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit +
                        `?parentId=${parentId}&id=${id}&subId=${item.id}`
                    );
                },
            },
            {
                title: 'basic.DELETE_BUTTON',
                action: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DELETE,
                isConfirm: true,
            },
        ],
        editAction: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_UPDATE,
        tabs: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_ITEMS',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
                search: `?parentId=${parentId}&id=${id}`,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras,
                search: `?parentId=${parentId}&id=${id}`,
            },
        ],
        tabsControls: [
            createControlModel({
                items: getBusinessMenuCategories().map(category => ({
                    key:
                        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras +
                        `?parentId=${parentId}&id=${category.id}`,
                    value: category.name,
                })),
                value: (getBusinessMenuCategories().find(f => f.id === id) || {})['name'],
                type: MODEL_CONTROL_TYPE.comboUrl,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(parentId, id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
            }),
        ],
        getLabel: () => {
            const { parentId, id } = getHistoryLocationParams(window.location.search);
            return getNameForProductsByIds(parentId, id);
        },
        sortByFunc: function(items) {
            return sortByProp(items);
        },
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(category => category.id === id) || {};
            return category.extras || [];
        },
    });
}

export function createCategoryExtrasEditSectionMenuTemplate({ ...args }) {
    const { id, subId } = getHistoryLocationParams(args.params);
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                isRequired: true,
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_STATUS_LABEL',
                accessor: 'enabled',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                isInline: true,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTIONS_LABEL',
                accessor: 'options',
                model: new SectionModel({
                    isDraggable: true,
                    typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOption,
                    actionName: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION,
                    columns: [
                        {
                            title: '',
                            accessor: 'name',
                            url: item =>
                                ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit +
                                `?parentId=${getCurrentOrderingMenuDepotId()}&id=${id}&subId=${subId}&subToId=${
                                    item.id
                                }`,
                            type: CONTROL_TYPE.linkControl,
                            width: 'calc(100% - 180px)',
                        },
                        {
                            title: '',
                            accessor: 'enabled',
                            label: {
                                onLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_ENABLED_LABEL',
                                offLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_DISABLED_LABEL',
                            },
                            type: CONTROL_TYPE.switch,
                            isEditable: true,
                            width: '100px',
                        },
                    ],
                }),
                isEditable: true,
                isFillContent: true,
                newItemUrl:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew +
                    `?parentId=${getCurrentOrderingMenuDepotId()}&id=${id}&subId=${subId}`,
                isShowNewItemControl: true,
                isDisabledNewItemControl: item => !item || !item.id,
                type: CONTROL_TYPE.editTable,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS,
        editCheckExcludedProps: ['options'],
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.extraModel, item);
        },
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(parentId, id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameForProductsByIds(parentId, id);
                }
            })
        ],
        getLabel: () => {
            const { id, subId } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit
              ? getExtraNameFromBusinessCategory(id, subId)
              : i18n.t('settings.orderingmenu.NEW_MENU_BUSINESS_CATEGORY_TAB_EXTRAS');
        },
        controls: [
            createControlModel({ type: MODEL_CONTROL_TYPE.back }),
        ],
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            return (category && category.extras) || [];
        },
    });
}

export function createCategoryExtrasOptionEditSectionMenuTemplate({ ...args }) {
    const { parentId, id, subId, subToId } = getHistoryLocationParams(args.params);

    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ENABLED_LABEL',
                accessor: 'enabled',
                isEditable: true,
                padding: '0 0 10px',
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
            },
            {
                id: 'group1',
                columns: [
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MIN_LABEL',
                        accessor: 'min',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        max: item => item.max,
                        useColorRed: item => item.min > item.max && item.max !== 0,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MAX_LABEL',
                        accessor: 'max',
                        useColorRed: item => item.min > item.max && item.min !== 0,
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_FREE_BUDGET',
                        accessor: 'free_budget',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        isItemValid: item => item && item.max >= item.free_budget,
                        max: item => item.max,
                        useColorRed: item => item.free_budget > item.max && item.free_budget !== 0,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_SKU',
                        accessor: 'sku',
                        isEditable: true,
                        type: CONTROL_TYPE.text,
                    },
                ],
                isEditable: true,
                type: CONTROL_TYPE.group,
            },
            {
                items: [
                    [
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_HALF_OPTION_AVAILLABLE_LABEL',
                            accessor: 'with_half_option',
                            accessorsToOff: ['reduce_price_for_half_option', 'pizza_option'],
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_REDUCE_PRICE_FOR_HALF_OPTION',
                            alwaysOnWithAccessor: 'with_half_option',
                            accessor: 'reduce_price_for_half_option',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_PIZZA_OPTION_LABEL',
                            alwaysOnWithAccessor: 'with_half_option',
                            accessor: 'pizza_option',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ALLOW_SUBOPTION_QUANTITY_LABEL',
                            accessor: 'allow_suboption_quantity',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_LIMIT_SUBOPTIONS_BY_MAX_LABEL',
                            accessor: 'limit_suboptions_by_max',
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                        {
                            title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_CONDITIONED_LABEL',
                            accessor: 'conditioned',
                            isShow: () => {
                                const options = getOrderingMenuOptions(parentId, id);
                                return options.length > 1;
                            },
                            isEditable: true,
                            type: CONTROL_TYPE.switch,
                        },
                    ],
                ],
                isEditable: true,
                type: CONTROL_TYPE.switchGroups,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_RESPECT_TO_LABEL',
                accessor: 'respect_to',
                isShow: item => item.conditioned,
                connect: state => {
                    const options = getOrderingMenuOptions(id, subId, state);
                    return {
                        values: options
                            .filter(option => option.id !== subToId)
                            .reduce((arr, option) => {
                                return arr.concat(
                                    option.suboptions.map(suboption => ({
                                        key: suboption.id,
                                        value: option.name + '/' + suboption.name,
                                    }))
                                );
                            }, []),
                    };
                },
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_SUBOPTIONS_LABEL',
                accessor: 'suboptions',
                model: new SectionModel({
                    typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboption,
                    isLayoutForm: true,
                    showDeleteButton: true,
                    isDraggable: true,
                    actionName: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION,
                    columns: [
                        {
                            title: '',
                            accessor: 'name',
                            url: () =>
                                ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionEdit +
                                `?parentId=${getCurrentOrderingMenuDepotId()}&id=${id}&subId=${subId}&subToId=${subToId}`,
                            type: CONTROL_TYPE.linkControl,
                            isLayout: true,
                            width: 'calc(100% - 280px)',
                        },
                        {
                            title: '',
                            component: function(item) {
                                return item.price + ' ' + getCurrentCurrency();
                            },
                            width: '100px',
                        },
                        {
                            title: '',
                            accessor: 'enabled',
                            label: {
                                onLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_ENABLED_LABEL',
                                offLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_OPTION_DISABLED_LABEL',
                            },
                            type: CONTROL_TYPE.switch,
                            isEditable: true,
                            width: '100px',
                        },
                    ],
                }),
                isShowNewItemControl: true,
                isDisabledNewItemControl: item => !item || !item.id,
                isEditable: true,
                isFillContent: true,
                newItemUrl:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasSuboptionNew +
                    `?parentId=${id}&id=${subId}&subId=null`,
                type: CONTROL_TYPE.editTable,
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION,
        initNewItemModel: item => getInitModel(MODEL_TYPE.productOptionModel, item),
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        saveControlsConfig: {
            cancel: {
                action: (item, history) =>
                    history.push(
                        `${
                            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit
                        }?parentId=${getCurrentOrderingMenuDepotId()}&id=${id}&subId=${subId}`
                    ),
            },
            save: {
                action: (item, history) =>
                    history.push(
                        `${
                            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasEdit
                        }?parentId=${getCurrentOrderingMenuDepotId()}&id=${id}&subId=${subId}`
                    ),
            },
        },
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(parentId, id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameForProductsByIds(parentId, id);
                }
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id, subId } = getHistoryLocationParams(window.location.search);
                    return getExtraNameFromBusinessCategory(id, subId);
                }
            })
        ],
        getLabel: () => {
            const { id, subId, subToId } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit
              ? getExtraOptionNameFromBusinessCategory(id, subId, subToId)
              : i18n.t('settings.orderingmenu.NEW_MENU_BUSSINES_CATEGORY_OPTIONS_LABEL');
        },
        mappingLambda: state => {
            const { id, subId } = getHistoryLocationParams(window.location.search);
            const businessMenu = state.restaurant.data.businessMenu;
            const categories = (businessMenu && businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            const extras = (category && category.extras) || [];
            const extra = extras.find(f => f.id === subId);
            return (extra && extra.options) || [];
        },
    });
}

export function createCategoryProductsSectionMenuTemplate({ ...args }) {
    const { parentId, id } = getHistoryLocationParams(args.params);

    return new SectionModel({
        filterOptions: createCategoryProductsSectionFilter(),
        isForceUpdate: true,
        columns: [
            {
                title: '',
                component: function(item) {
                    return (
                        <ItemLogo value={item.images} settings={{ width: 70, height: 45, crop_mode: CROP_MODE.pad }} />
                    );
                },
                width: '100px',
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_NAME_LABEL',
                accessor: 'name',
                // width: '200px',
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRAS_LABEL',
                accessor: 'extrasJoin',
                // width: '300px',
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_LABEL',
                accessor: 'price',
                // width: '100px',
                centered: true,
            },
            {
                title: '',
                controlTitle: '',
                accessor: 'enabled',
                // width: '200px',
                isEditable: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                isSubId: true,
                type: CONTROL_TYPE.link,
                link:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit +
                    `?parentId=${parentId}&id=${id}`,
                iconName: 'editItem',
            },
        ],
        actionLoad: ORDERING_MENU_GET,
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS,
        editAction: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_UPDATE,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
        typeAction: args.typeAction,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(parentId, id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB',
            }),
        ],
        getLabel: () => {
            const { parentId, id } = getHistoryLocationParams(window.location.search);
            return getNameForProductsByIds(parentId, id);
        },
        controls: [
            createControlModel({ type: MODEL_CONTROL_TYPE.back }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url:
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew +
                    `?parentId=${parentId}&id=${id}&subId=0`,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
        ],
        tabsControls: [
            createControlModel({
                items: getBusinessMenuCategories().map(category => ({
                    key:
                        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts +
                        `?parentId=${parentId}&id=${category.id}`,
                    value: category.name,
                })),
                value: (getBusinessMenuCategories().find(f => f.id === id) || {})['name'],
                type: MODEL_CONTROL_TYPE.comboUrl,
            }),
        ],
        isDraggable: false,
        dragActionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DRAG_UPDATE,
        tabs: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_ITEMS',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
                search: `?parentId=${parentId}&id=${id}`,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB_EXTRAS',
                typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras,
                search: `?parentId=${parentId}&id=${id}`,
            },
        ],
        sortByFunc: function(items) {
            return sortByProp(items);
        },
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(category => category.id === id) || {};
            return (category.products || []).map(product => ({
                ...product,
                extrasJoin: (product.extras || []).map(m => m.name).join(', '),
            }));
        },
    });
}

export function createCategoryProductIngredientSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_INGRIDIENTS_LABEL',
                accessor: 'ingredients',
                model: new SectionModel({
                    isDraggable: true,
                    columns: [
                        {
                            title: '',
                            accessor: 'name',
                            type: CONTROL_TYPE.text,
                            width: '200px',
                            isEditable: true,
                        },
                    ],
                    typeModel:
                        ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredient,
                    actionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT,
                }),
                isEditable: true,
                isFillContent: true,
                isShowArrayNewItemControl: true,
                arrayNewItemAccessor: 'name',
                actionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_UPDATE,
                type: CONTROL_TYPE.editTable,
            },
        ],
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT,
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientNew,
        initNewItemModel: item => item,
        isHideControls: true,
        isLayoutForm: true,
        isHideSaveButton: true,
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            return (category && category.products) || [];
        },
    });
}

export function createCategoryProductsEditSectionMenuTemplate({ ...args }) {
    const { parentId, id, subId } = getHistoryLocationParams(args.params);

    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'images',
                settingsAaccessor: 'image_settings',
                isEditable: true,
                type: CONTROL_TYPE.imageEdit,
            },
            {
                columns: [
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_ENABLED_LABEL',
                        accessor: 'enabled',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_UNIT_WEIGHT',
                        accessor: 'unit_weight',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_WEIGHTED',
                        accessor: 'weighed',
                        isEditable: true,
                        type: CONTROL_TYPE.switch,
                        isInline: true,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_WEIGHT_RANGE',
                        accessor: 'weight_range',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        measure: '%',
                        min: () => 0,
                        max: () => 100,
                        isShow: item => item.weighed,
                        useColorRed: item => item.weight_range > 100 || item.weight_range < 0,
                        isItemValid: item => item.weight_range <= 100 && item.weight_range >= 0 ,
                    },
                ],
                isEditable: true,
                type: CONTROL_TYPE.group,
                justifyContent: 'flex-start'
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_LABEL',
                accessor: 'price',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_SKU',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_PRICE_BEFORE_DISCOUNT',
                accessor: 'price_before_discount',
                isEditable: true,
                type: CONTROL_TYPE.number,
                useColorRed: item => item.price_before_discount < item.price && item.price_before_discount,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_MAX',
                accessor: 'max',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_UPSELLING_LABEL',
                accessor: 'upselling',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_ALLOW_COMMENTS_LABEL',
                accessor: 'allow_comments',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_VEGAN_LABEL',
                accessor: 'vegan',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_GLUTEN_FREE_LABEL',
                accessor: 'gluten_free',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_VEGITERIAN_LABEL',
                accessor: 'vegetarian',
                isEditable: true,
                isInline: true,
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_CUSTOM_TAG_LABEL',
                accessor: 'tag',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_INGRIDIENTS_LABEL',
                accessor: 'categoryIngredients',
                url: item =>
                    ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductIngredientEdit +
                    `?parentId=${parentId}&id=${id}&subId=${item.id}`,
                isEditable: true,
                isLayout: true,
                type: CONTROL_TYPE.linkControl,
                isDisabled: item => !item.id,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_SPICY_LABEL',
                accessor: 'spicy',
                connect: () => ({
                    values: spicyTypes(),
                }),
                isEditable: true,
                type: CONTROL_TYPE.select,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_LABEL',
                accessor: 'categoryExtras',
                model: new SectionModel({
                    isDraggable: true,
                    showDeleteButton: false,
                    columns: [
                        {
                            title: '',
                            accessor: 'name',
                            type: CONTROL_TYPE.text,
                            width: 'calc(100% - 195px)',
                        },
                        {
                            title: 'basic.EDIT_BUTTON',
                            width: "40px",
                            centered: true,
                            type: CONTROL_TYPE.iconWithQuantity,
                            quantity: item => {
                                if (item && item.overrides) {
                                    return item.overrides.length;
                                }
                                return 0;
                            },
                            url: item =>
                                ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra +
                                `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${item.id}`,
                            iconName: 'editItem',
                            isShow: item => item.enabled,
                        },
                        {
                            title: '',
                            accessor: 'enabled',
                            label: {
                                onLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_ADDED_LABEL',
                                offLabel: 'settings.orderingmenu.MENU_BUSINESS_PRODUCT_EXTRA_ADD_LABEL',
                            },
                            type: CONTROL_TYPE.switch,
                            isEditable: true,
                            width: '100px',
                        },
                    ],
                }),
                isEditable: true,
                isFillContent: true,
                actionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_UPDATE,
                type: CONTROL_TYPE.editTable,
            },
            {
                columns: [
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_ENABLED_LABEL',
                        accessor: 'enable_schedule',
                        interchangeableAccessor: 'preorder_schedule',
                        isEditable: true,
                        isFillContent: true,
                        padding: '10px 0',
                        type: CONTROL_TYPE.switch,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_PREORDER_SHEDULE_ENABLED_LABEL',
                        accessor: 'preorder_schedule',
                        interchangeableAccessor: 'enable_schedule',
                        isEditable: true,
                        isFillContent: true,
                        padding: '10px 0',
                        type: CONTROL_TYPE.switch,
                    },
                ],
                isEditable: true,
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                type: CONTROL_TYPE.group,
                isFillContent: true,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_SHEDULE_LABEL',
                accessor: 'schedule',
                isEditable: true,
                isFillContent: true,
                type: CONTROL_TYPE.schedule,
            },
        ],
        // actionLoad: ORDERING_MENU_GET,
        shouldCallActionFromSideMenu: (state) => {
            if(state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.id) {
                return state.restaurant?.data.businessMenu?.id === state.menuTemplate.templates ? getTemplateMenuById(parentId)?.business_id : parentId;
            }
            return false;
        },
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS,
        initNewItemModel: item => {
            return {
                ...getInitModel(MODEL_TYPE.categoryProductModel, item),
                categoryExtras: mergeArrays(getDepotCategoryExtras(parentId, id), (item && item.extras) || []).map(
                    extra => {
                        const hasExtra = item && item.extras && item.extras.some(e => e.id === extra.id);
                        return { ...extra, enabled: hasExtra, order: extra.order || 0 };
                    }
                ),
            };
        },
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
        checkActiveButtonFromModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameByIdsBusinessMenu(parentId, id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_TAB',
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getNameForProductsByIds(parentId, id);
                },
            }),
        ],
        getLabel: () => {
            const { parentId, id, subId } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit
              ? getNameForProductsByIds(parentId, id, subId)
              : i18n.t('settings.orderingmenu.NEW_MENU_BUSINESS_PRODUCT_ID_LABEL');
        },
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            return (category && category.products) || [];
        },
    });
}

export function createCategoryExtrasSuboptionEditSectionMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: '',
                accessor: 'image',
                settingsAaccessor: 'image_settings',
                isEditable: true,
                type: CONTROL_TYPE.imageEdit,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isRequired: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_ENABLED_LABEL',
                accessor: 'enabled',
                isEditable: true,
                padding: '0 0 10px',
                type: CONTROL_TYPE.switch,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.richText,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_PRICE_LABEL',
                accessor: 'price',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_MAX_LABEL',
                accessor: 'max',
                isEditable: true,
                type: CONTROL_TYPE.number,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_SKU_LABEL',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_CUSTOM_TAG_LABEL',
                accessor: 'tag',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
        ],
        actionName: ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION,
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.extraSuboptionModel, item);
        },
        isLayoutForm: true,
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtrasOptionNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
        mappingLambda: state => {
            const { id, subId, subToId } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            const extras = (category && category.extras) || [];
            const extra = extras.find(f => f.id === subId);
            const options = (extra && extra.options) || [];
            const optionId = state.sectionModel.sectionModels[state.sectionModel.currentModelType].id || subToId;
            const option = options.find(f => f.id === optionId);

            return (option && option.suboptions) || [];
        },
    });
}

function createBusinesMenuCategoriesSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(category) {
        return getBusinesMenuCategoriesFilter(this.selected, category);
    });

    return filterOptionsModel;
}

function createCategoryProductsSectionFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(product) {
        return getCategoryProductFilter(this.selected, product);
    });

    return filterOptionsModel;
}

export function createReviewProductExtraMenuTemplate({ ...args }) {
    const { parentId, subId, subToId, id } = getHistoryLocationParams(window.location.search);
    const extraTypesModels = {
        [EXTRA_TYPES.extra]:
            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideExtra +
            `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}&extra=`,
        [EXTRA_TYPES.option]:
            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideOption +
            `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}&option=`,
        [EXTRA_TYPES.suboption]:
            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideSuboption +
            `?parentId=${parentId}&id=${id}&subId=${subId}&subToId=${subToId}suboption=`,
    };
    return new SectionModel({
        filterOptions: createReviewProductExtraFilter(),
        isForceUpdate: true,
        showNeedSave: ({models, sectionModel}) => {
            const model = models[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra]?.model;
            return model?.isMarkedModelAsChanged;
        },
        columns: [
            {
                title: 'settings.orderingmenu.OVERRIDE_LABEL_NAME',
                accessor: 'name',
                type: CONTROL_TYPE.nestedItem,
                nestedTab: 15,
                isEditable: true,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                isLayout: true,
                accessor: 'name',
                quantity: item => {
                    const extraOverrides = item && item.overrides && item.overrides.filter(x => x.id === item.id);
                    return (extraOverrides && extraOverrides.length) || 0;
                },
                type: CONTROL_TYPE.iconWithQuantity,
                url: item => extraTypesModels[item.extraType] + item.id,
                iconName: 'editItem',
                isShow: () => true,
            },
        ],
        actionName: ORDERING_MENU_GET,
        shouldCallActionFromSideMenu: (state) => {
            if(state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.id) {
                return state.restaurant?.data.businessMenu?.id === state.menuTemplate.templates ? getTemplateMenuById(parentId)?.business_id : parentId;
            }
            return false;
        },
        typeAction: args.typeAction,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProductsEdit,
        initNewItemModel: item => getInitModel(MODEL_TYPE.reviewExtra, item),
        isLayoutForm: true,
        showDeleteButton: false,
        controls: [createControlModel({ type: MODEL_CONTROL_TYPE.back })],
        mappingLambda: state => {
            const { id, subId, subToId } = getHistoryLocationParams(window.location.search);
            const globalExtras =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.extras) || [];
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories && categories.find(el => el.id === id);
            const product = category && category.products.find(p => p.id === subId);
            const extraOverride = product && product.extras.find(p => p.id === subToId);
            const extras = globalExtras.concat((category && category.extras) || []);
            const extra = extras.find(el => el.id === subToId);
            return getInitModel(MODEL_TYPE.reviewExtra, extra).data.map(el => ({
                ...el,
                overrides: extraOverride && extraOverride.overrides,
            }));
        },
    });
}

function createReviewProductExtraFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(category) {
        return getBusinesMenuCategoriesFilter(this.selected, category);
    });

    return filterOptionsModel;
}
export function createOverrideExtraMenuTemplate({ ...args }) {
    const {parentId} = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
            },
        ],
        isEditable: true,
        isLayoutForm: true,
        actionName: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA,
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.extraModelWithOverrides, item);
        },
        typeAction: args.typeAction,
        shouldCallActionFromSideMenu: (state) => {
            if(state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.id) {
                return state.restaurant?.data.businessMenu?.id === state.menuTemplate.templates ? getTemplateMenuById(parentId)?.business_id : parentId;
            }
            return false;
        },
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideExtra,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra,
        markModelAsChanged: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra,
        mappingLambda: state => {
            const { id, subId, parentId } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            const product = category.products.find(p => p.id === subId);
            const allExtra = getDepotCategoryExtras(parentId, id, state);

            return mergeArrays(allExtra || [], (product && product.extras) || []);
        },
    });
}

export function createOverrideOptionMenuTemplate({ ...args }) {
    const { id, parentId } = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                labelIconName: 'delete',
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
            },
            {
                id: 'group1',
                columns: [
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MIN_LABEL',
                        accessor: 'min',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        min: () => 0,
                        max: item => item.max,
                        useColorRed: item => item.min > item.max,
                        clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                        labelIconName: 'delete',
                        isShowIconLabel: item => {
                            return item.overridesProperty && item.overridesProperty.includes('min');
                        },
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_MAX_LABEL',
                        accessor: 'max',
                        isEditable: true,
                        type: CONTROL_TYPE.number,
                        clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                        labelIconName: 'delete',
                        isShowIconLabel: item => {
                            return item.overridesProperty && item.overridesProperty.includes('max');
                        },
                    },
                    {
                        title: i18n.t('settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_FREE_BUDGET'),
                        accessor: 'free_budget',
                        isEditable: true,
                        min: () => 0,
                        type: CONTROL_TYPE.number,
                        clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                        labelIconName: 'delete',
                        isShowIconLabel: item => {
                            return item.overridesProperty && item.overridesProperty.includes('free_budget');
                        },
                        isItemValid: item => item && item.max >= item.free_budget,
                        max: item => item.max,
                        useColorRed: item => item.free_budget > item.max && item.free_budget !== 0,
                    },
                    {
                        title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_SKU',
                        accessor: 'sku',
                        isEditable: true,
                        type: CONTROL_TYPE.text,
                        clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                        labelIconName: 'delete',
                        isShowIconLabel: item => {
                            return item.overridesProperty && item.overridesProperty.includes('sku');
                        },
                    },
                ],
                isEditable: true,
                type: CONTROL_TYPE.group,
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_HALF_OPTION_AVAILLABLE_LABEL',
                accessor: 'with_half_option',
                accessorsToOff: ['reduce_price_for_half_option', 'pizza_option'],
                isEditable: true,
                type: CONTROL_TYPE.switch,
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                labelIconName: 'delete',
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('with_half_option');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ALLOW_SUBOPTION_QUANTITY_LABEL',
                accessor: 'allow_suboption_quantity',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                labelIconName: 'delete',
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('allow_suboption_quantity');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_LIMIT_SUBOPTIONS_BY_MAX_LABEL',
                accessor: 'limit_suboptions_by_max',
                isEditable: true,
                type: CONTROL_TYPE.switch,
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                labelIconName: 'delete',
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('limit_suboptions_by_max');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_CONDITIONED_LABEL',
                accessor: 'conditioned',
                isShow: () => {
                    const options = getOrderingMenuOptions(parentId, id);
                    return options.length > 1;
                },
                isEditable: true,
                type: CONTROL_TYPE.switch,
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE,
                labelIconName: 'delete',
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('conditioned');
                },
            },
        ],
        isLayoutForm: true,
        isEditable: true,
        actionName: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION,
        initNewItemModel: item => getInitModel(MODEL_TYPE.optionModelWithOverrides, item),
        typeAction: args.typeAction,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideOption,
        shouldCallActionFromSideMenu: (state) => {
            if(state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.id) {
                return state.restaurant?.data.businessMenu?.id === state.menuTemplate.templates ? getTemplateMenuById(parentId)?.business_id : parentId;
            }
            return false;
        },
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryExtras,
        markModelAsChanged: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra,
        mappingLambda: state => {
            const { id, subId, subToId } = getHistoryLocationParams(window.location.search);
            const businessMenu = state.restaurant.data.businessMenu;
            const categories = (businessMenu && businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            const product = category && category.products.find(p => p.id === subId);
            const extras = mergeArrays(
                (category && category.extras) || [],
                getDepotCategoryExtras(parentId, id, state)
            );
            const extra = extras.find(f => f.id === subToId);
            const overridesExtra = product && product.extras.find(e => e.id === extra.id);
            const extraWithOverride =
                extra &&
                extra.options &&
                extra.options.map(e => ({ ...e, overrides: overridesExtra && overridesExtra.overrides }));

            return extraWithOverride || [];
        },
    });
}

export function createOverrideSuboptionMenuTemplate({ ...args }) {
    const {parentId} = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        columns: [
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_NAME_LABEL',
                accessor: 'name',
                isEditable: true,
                isFuncRequired: (item) => item !== null,
                type: CONTROL_TYPE.text,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('name');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DESCRIPTION_LABEL',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('description');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_PRICE_LABEL',
                accessor: 'price',
                min: () => 0,
                isEditable: true,
                type: CONTROL_TYPE.number,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('price');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_MAX_LABEL',
                accessor: 'max',
                isEditable: true,
                min: () => 0,
                type: CONTROL_TYPE.number,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('max');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_SKU_LABEL',
                accessor: 'sku',
                isEditable: true,
                type: CONTROL_TYPE.text,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('sku');
                },
            },
            {
                title: 'settings.orderingmenu.MENU_BUSINESS_CATEGORY_EXTRAS_CUSTOM_TAG_LABEL',
                accessor: 'tag',
                isEditable: true,
                type: CONTROL_TYPE.text,
                labelIconName: 'delete',
                clickAction: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE,
                isShowIconLabel: item => {
                    return item.overridesProperty && item.overridesProperty.includes('tag');
                },
            },
        ],
        actionName: TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION,
        initNewItemModel: item => {
            return getInitModel(MODEL_TYPE.suboptionsModelWithOverrides, item);
        },
        isLayoutForm: true,
        typeAction: args.typeAction,
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.overrideSuboption,
        shouldCallActionFromSideMenu: (state) => {
            if(state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.id) {
                return state.restaurant?.data.businessMenu?.id === state.menuTemplate.templates ? getTemplateMenuById(parentId)?.business_id : parentId;
            }
            return false;
        },
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingBusinessMenuCategoryProducts,
        markModelAsChanged: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.reviewProductExtra,
        mappingLambda: state => {
            const { id, subToId, subId, parentId } = getHistoryLocationParams(window.location.search);
            const categories =
                (state.restaurant.data.businessMenu && state.restaurant.data.businessMenu.categories) || [];
            const category = categories.find(f => f.id === id);
            const extras = mergeArrays(
                getDepotCategoryExtras(parentId, id, state),
                (category && category.extras) || []
            );
            const extra = extras.find(f => f.id === subToId);
            const options = (extra && extra.options) || [];
            const product = category && category.products.find(p => p.id === subId);
            const overridesExtra = product && product.extras.find(e => e.id === extra.id);
            return options
                .flatMap(el => el.suboptions)
                .map(el => ({
                    ...el,
                    overrides: overridesExtra && overridesExtra.overrides,
                }));
        },
    });
}

export function createBusinessGalleryMenuTemplate() {
    const { id } = getHistoryLocationParams(window.location.search);
    return new SectionModel({
        parentUrl: MARKETPLACE_SETTINGS_MODEL.menuTemplate,
        actionName: ORDERING_MENU_BUSINESS_GALLERY,
        filterOptions: createGalleryFilter(),
        typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery,
        columns: [
            {
                title: '',
                component: function(item) {
                    return (
                        <ItemLogo value={item.file} settings={{ width: 70, height: 45, crop_mode: CROP_MODE.pad }} />
                    );
                },
                width: '100px',
            },
            {
                accessor: 'title',
                title: 'settings.business-gallery.TITLE',
                type: CONTROL_TYPE.text,
            },
            {
                title: 'basic.EDIT_BUTTON',
                width: "90px",
                centered: true,
                type: CONTROL_TYPE.link,
                link: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryEdit,
                iconName: 'editItem',
            },
        ],
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { id } = getHistoryLocationParams(window.location.search);
                    return getMenuTemplateNameById(id);
                },
            }),
        ],
        label: 'settings.business-gallery.LABEL',
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryNew + `?id=${id}`,
            }),
            createControlModel({ type: MODEL_CONTROL_TYPE.sortMode }),
        ],
        dragActionName: ORDERING_MENU_BUSINESS_GALLERY_REORDER,
        actionLoad: ORDERING_MENU_GET,
        initNewItemModel: item => item,
        mappingLambda: state => {
            const businessMenu = state.restaurant.data && state.restaurant.data.businessMenu;
            return (businessMenu && businessMenu.gallery) || [];
        },
    });
}

export function createBusinessGalleryNewMenuTemplate({ ...args }) {
    return new SectionModel({
        columns: [
            {
                title: 'settings.business-gallery.IMAGE',
                accessor: 'file',
                settingsAaccessor: 'file_settings',
                isEditable: true,
                type: CONTROL_TYPE.imageEdit,
            },
            {
                title: 'settings.business-gallery.DESCRIPTION',
                accessor: 'description',
                isEditable: true,
                type: CONTROL_TYPE.textArea,
            },
            {
                title: 'settings.business-gallery.TITLE',
                accessor: 'title',
                isEditable: true,
                type: CONTROL_TYPE.text,
            },
        ],
        actionName: ORDERING_MENU_BUSINESS_GALLERY,
        initNewItemModel: item => getInitModel(MODEL_TYPE.gallery, item),
        actionLoad: ORDERING_MENU_GET,
        typeAction: args.typeAction,
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryEdit
                : ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.galleryNew,
        parentUrl: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.gallery,
        breadcrumbs: [
            createBreadcrumbModel({
                label: 'header.menu.MARKETPLACE_SETTINGS',
            }),
            createBreadcrumbModel({
                label: "settings.orderingmenu.APP_BUILDER",
            }),
            createBreadcrumbModel({
                getLabel: () => {
                    const { parentId, id } = getHistoryLocationParams(window.location.search);
                    return getMenuTemplateNameById(parentId) || getMenuTemplateNameById(id);
                },
            }),
            createBreadcrumbModel({
                label: 'settings.business-gallery.LABEL',
            }),
        ],
        getLabel: () => {
            const { id } = getHistoryLocationParams(window.location.search);
            return args.typeAction === TYPE_ACTION.edit ? getBusinessMenuGalleryNameById(id) : i18n.t('settings.business-gallery.NEW_LABEL');
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
            }),
        ],
        mappingLambda: state => {
            const businessMenu = state.restaurant.data && state.restaurant.data.businessMenu;
            return (businessMenu && businessMenu.gallery) || [];
        },
    });
}

function createGalleryFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions('search', CONTROL_TYPE.text, null, function(gallery) {
        return getGalleryFilter(this.selected, gallery);
    });

    return filterOptionsModel;
}

export const bulkConsumerModel = (data = {}) => {
    const model = {
        name: data?.name || '',
        id: data?.id,
        original_phone: data?.phone_valid?.original_value || '',
        delivery_type: data?.delivery_type || 0,
        original_city: data?.address_valid?.original_city || '',
        original_street: data?.address_valid?.original_street || '',
        original_notes: data?.address_valid?.original_notes || '',
        flat: data?.address_valid?.flat || '',
        storey: data?.address_valid?.storey || '',
        original_date: data?.datetime?.original_date || '',
        original_time: data?.datetime?.original_time || '',
        notes: data?.notes || ''
    }

    if(!data.id) {
        delete model.id;
    }

    return model;
}
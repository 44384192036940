import { SHIFTS_MODEL } from '../utils/enums';

import MenuModel from './menuModel';


export function createShiftsMenu() {
    return [
        new MenuModel({
            pathname: SHIFTS_MODEL.currentShiftUsers,
            title: "shifts.CURRENT_SHIFT",
            icon: "current_shift",
            active: location => getIsActive(location, SHIFTS_MODEL.currentShiftUsers)
        }),
        new MenuModel({
            pathname: SHIFTS_MODEL.history,
            title: "shifts.HISTORY",
            icon: "shifts_history",
            active: location => getIsActive(location, SHIFTS_MODEL.history)
        }),
        new MenuModel({
            pathname: SHIFTS_MODEL.providers,
            title: "shifts.PROVIDERS",
            icon: "externalProvider",
            active: location => getIsActive(location, SHIFTS_MODEL.providers)
                || getIsActive(location, SHIFTS_MODEL.currentShiftProviderHistory)
                || getIsActive(location, SHIFTS_MODEL.currentShiftProviderHistoryOrder)
        })
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split("/");
    const splitType = type.split("/");
    return (splitCurrentLocation[1] === splitType[1] && splitCurrentLocation[2] === splitType[2]);
}
